import { FC, useEffect, useState } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  CircularProgress,
  Stack,
  InputLabel,
  Divider,
  IconButton,
  Button,
  Tooltip,
  styled,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationEconomicActivityCompany } from "./validation";
import { EconomicActivityCompanyType } from "../../types/types";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";

const LabelForm = styled(InputLabel)(({ theme }) => ({
  left: "-14px",
}));

const EconomicActivity: FC = () => {
  const [loadingSendData, setLoadingSendData] = useState(false);
  const { dataForm, completeData, loadDataServer, data, address, dataJ } =
    useProfile();
  const {
    setActiveStep,
    step2,
    setStep2,
    handleBack,
    handleNext,
    step2Answered,
  } = useLegalForm();

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step2;
  }

  const defaultValues: EconomicActivityCompanyType = {
    nonfinancial_activities: findData.nonfinancial_activities
      ? datos.non_financial_activities.find(
          (f: any) => f.id === findData.nonfinancial_activities
        )
      : [],
    other_economic_activity: findData.other_economic_activity
      ? dataJ.al_ta_other_economic_activity.find(
          (f: any) => f.id === Number(findData.other_economic_activity)
        )
      : [],
    financialactivities: findData.financialactivities
      ? datos.financial_activities.find(
          (f: any) => f.id === findData.financialactivities
        )
      : [],
    economic_sector: findData.economic_sector ? findData.economic_sector : "",
    entity_have_links_with_altio: findData.entity_have_links_with_altio
      ? datos.selectBoolean.find(
          (f: any) => f.id === findData.entity_have_links_with_altio
        )
      : [],
    entity_have_links_with_altio_data:
      (findData.entity_have_links_with_altio_data &&
        JSON.parse(findData.entity_have_links_with_altio_data)) ?? [
        {
          name_or_social_denomination: "",
          relation_with_entity: "",
        },
      ],
    annual_income: findData.annual_income
      ? dataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.annual_income)
        )
      : [],
    annual_expenses: findData.annual_expenses
      ? dataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.annual_expenses)
        )
      : [],
    total_assets: findData.total_assets
      ? dataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.total_assets)
        )
      : [],
    total_liabilities: findData.total_liabilities
      ? dataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.total_liabilities)
        )
      : [],
    subscribed_paid_Capital: findData.subscribed_paid_Capital
      ? dataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.subscribed_paid_Capital)
        )
      : [],
    total_employees: findData.total_employees ? findData.total_employees : "",
    average_annual_amount: findData.average_annual_amount
      ? dataJ.al_ta_initial_investment_amount.find(
          (f: any) => f.id === Number(findData.average_annual_amount)
        )
      : [],
    total_heritage: findData.total_heritage
      ? dataJ.al_ta_total_heritage.find(
          (f: any) => f.id === Number(findData.total_heritage)
        )
      : [],
    investment_goals: findData.investment_goals
      ? data &&
        data.al_ta_investment_goals.find(
          (f: any) => f.id === Number(findData.investment_goals)
        )
      : [],
    main_sources_funds: findData.main_sources_funds
      ? findData.main_sources_funds
      : "",
    main_clients_entity: (findData.main_clients_entity &&
      JSON.parse(findData.main_clients_entity)) ?? [
      {
        social_name: "",
        identification_number: "",
        economic_sector: [],
        other_economic_sector: "",
        contact: "",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState,
    control,
    watch,
    getValues,
  } = useForm<EconomicActivityCompanyType>({
    defaultValues,
    resolver: yupResolver(validationEconomicActivityCompany),
  });
  const { errors, isDirty, dirtyFields } = formState;

  const {
    fields: entity_have_links_with_altio_dataFields,
    append: entity_have_links_with_altio_dataAppend,
    remove: entity_have_links_with_altio_dataRemove,
  } = useFieldArray({
    control,
    name: "entity_have_links_with_altio_data",
    rules: {
      minLength: 1,
    },
  });
  const {
    fields: main_clients_entityFields,
    append: main_clients_entityAppend,
    remove: main_clients_entityRemove,
  } = useFieldArray({
    control,
    name: "main_clients_entity",
    rules: {
      minLength: 3,
    },
  });

  const watch_non_financial_activities: any = watch("nonfinancial_activities");
  const watch_financial_activities: any = watch("financialactivities");

  const watch_entity_have_links_with_altio: any = watch(
    "entity_have_links_with_altio"
  );
  const watch_main_clients_entity: any = watch("main_clients_entity");

  const miarray: any = [];

  useEffect(() => {
    reset(defaultValues);
  }, [dataForm]);

  /* useEffect(() => {
    if (watch_non_financial_activities?.id !== "6") {
      resetField("other_economic_activity");
    }
  }, [watch_non_financial_activities]); */
  useEffect(() => {
    if (
      watch_non_financial_activities?.id !== "6" ||
      watch_financial_activities?.id !== "9"
    ) {
      resetField("other_economic_activity");
    }

    console.log(watch_financial_activities);
  }, [watch_financial_activities, watch_non_financial_activities]);

  useEffect(() => {
    if (watch_entity_have_links_with_altio?.id !== "1") {
      resetField("entity_have_links_with_altio_data");
    }
  }, [watch_entity_have_links_with_altio]);

  useEffect(() => {
    if (
      dataForm !== undefined &&
      dirtyFields.main_clients_entity &&
      findData?.step_two_complete === "true"
    ) {
      if (watch_main_clients_entity?.[0]?.economic_sector?.id !== "15") {
        resetField(`main_clients_entity.${0}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_clients_entity?.[1]?.economic_sector?.id !== "15") {
        resetField(`main_clients_entity.${1}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_clients_entity?.[2]?.economic_sector?.id !== "15") {
        resetField(`main_clients_entity.${2}.other_economic_sector`, {
          defaultValue: "",
        });
      }
    }
  }, [watch_main_clients_entity]);

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id ? value.id : JSON.stringify(value);
      }
      data.step_two_complete = "true";
    }

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });

        if (
          "nonfinancial_activities" in update &&
          //@ts-ignore
          update.nonfinancial_activities !== "6"
        ) {
          Object.assign(update, { other_economic_activity: "[]" });
        }

        if (
          "financialactivities" in update &&
          //@ts-ignore
          update.financialactivities !== "9"
        ) {
          Object.assign(update, { other_economic_activity: "[]" });
        }
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep2 && setStep2(data);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_two_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "J",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Actividad económica de la empresa",
      };
      localStorage.setItem("stepForm", "Actividad económica de la empresa");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...data,
          },
          formType: "J",
          userId: sessionStorage.getItem("user_id"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Actividad económica de la empresa</TitleForm>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="nonfinancial_activities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.non_financial_activities}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                //disabled={completeData}
                disabled={
                  completeData || watch_financial_activities?.id !== undefined
                }
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="a) Actividades no financieras"
                      variant="standard"
                      error={!!errors.nonfinancial_activities}
                      helperText={
                        errors.nonfinancial_activities &&
                        errors.nonfinancial_activities.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="financialactivities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.financial_activities}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={
                  completeData ||
                  watch_non_financial_activities?.id !== undefined
                }
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="b) Actividades financieras"
                      variant="standard"
                      error={!!errors.financialactivities}
                      helperText={
                        errors.financialactivities &&
                        errors.financialactivities.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {(watch_financial_activities?.id === "9" ||
          watch_non_financial_activities?.id === "6") && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="other_economic_activity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={dataJ && dataJ.al_ta_other_economic_activity}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Otra Actividad Económica"
                        variant="standard"
                        error={!!errors.other_economic_activity}
                        helperText={
                          errors.other_economic_activity &&
                          errors.other_economic_activity.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Typography variant="body2" sx={{ mt: 5, mb: 4 }}>
        * Si marcó una actividad mencionada en el punto anterior (b), la entidad
        deberá completar el formulario de sujeto obligado, el cual tiene que
        estar firmado por el oficial de cumplimiento designado.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="economic_sector"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.economic_sector}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Sector económico"
                      variant="standard"
                      error={!!errors.economic_sector}
                      helperText={
                        errors.economic_sector && errors.economic_sector.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Personas Vinculadas
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="entity_have_links_with_altio"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la entidad vínculos con ALTIO, sus accionistas, directores o empleados?"
                      variant="standard"
                      error={!!errors.entity_have_links_with_altio}
                      helperText={
                        errors.entity_have_links_with_altio &&
                        errors.entity_have_links_with_altio.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {watch_entity_have_links_with_altio?.id === "1" && (
        <>
          {entity_have_links_with_altio_dataFields.map((field, index) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 3,
                      borderColor: "#efe5df",
                      width: "50%",
                      ml: "auto",
                      mr: "auto",
                    }}
                  />
                )}
                <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name={`entity_have_links_with_altio_data.${index}.name_or_social_denomination`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombres / Denominación social"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `entity_have_links_with_altio_data.${index}.name_or_social_denomination` as const
                          )}
                          error={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.name_or_social_denomination && true
                          }
                          helperText={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.name_or_social_denomination?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                    <Controller
                      name={`entity_have_links_with_altio_data.${index}.relation_with_entity`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Relación"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `entity_have_links_with_altio_data.${index}.relation_with_entity` as const
                          )}
                          error={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.relation_with_entity && true
                          }
                          helperText={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.relation_with_entity?.message
                          }
                        />
                      )}
                    />
                    {index !== 0 && !completeData && (
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => {
                          entity_have_links_with_altio_dataRemove(index);
                        }}
                      >
                        <RemoveCircleIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
          {entity_have_links_with_altio_dataFields.length < 4 &&
            !completeData && (
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    entity_have_links_with_altio_dataAppend({
                      name_or_social_denomination: "",
                      relation_with_entity: "",
                    });
                  }}
                >
                  <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
                  persona
                </Button>
              </Grid>
            )}
        </>
      )}

      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información financiera
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ingresos anuales (RD$/US$)"
                      variant="standard"
                      error={!!errors.annual_income}
                      autoComplete="off"
                      helperText={
                        errors.annual_income && errors.annual_income.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Obligaciones Financieras (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.annual_expenses}
                      helperText={
                        errors.annual_expenses && errors.annual_expenses.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total activos (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.total_assets}
                      helperText={
                        errors.total_assets && errors.total_assets.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_liabilities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total pasivos (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.total_liabilities}
                      helperText={
                        errors.total_liabilities &&
                        errors.total_liabilities.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="subscribed_paid_Capital"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Capital suscrito y pagado (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.subscribed_paid_Capital}
                      helperText={
                        errors.subscribed_paid_Capital &&
                        errors.subscribed_paid_Capital.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_employees"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.total_employees}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total empleados"
                      variant="standard"
                      error={!!errors.total_employees}
                      helperText={
                        errors.total_employees && errors.total_employees.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="average_annual_amount"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_initial_investment_amount}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Monto de Inversión Inicial (RD$/US$)"
                      variant="standard"
                      error={!!errors.average_annual_amount}
                      helperText={
                        errors.average_annual_amount &&
                        errors.average_annual_amount.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={dataJ && dataJ.al_ta_total_heritage}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio total"
                      variant="standard"
                      error={!!errors.total_heritage}
                      helperText={
                        errors.total_heritage && errors.total_heritage.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_goals"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={data.al_ta_investment_goals}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "investment_goals";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Propósito de la inversión"
                      variant="standard"
                      error={!!errors.investment_goals}
                      helperText={
                        errors.investment_goals &&
                        errors.investment_goals.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="main_sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.al_ta_main_sources_funds}
                getOptionLabel={(option: any) => option || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option === value
                }
                //@ts-ignore
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "main_sources_funds3";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Indicar las principales fuentes de los fondos de los aportes a invertir"
                      variant="standard"
                      error={!!errors.main_sources_funds}
                      helperText={
                        errors.main_sources_funds &&
                        errors.main_sources_funds.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 5 }}>
        * Favor proveer información financiera al último cierre fiscal
        presentado ante la dgii u organismo equivalente
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales clientes de la entidad
      </TitleForm>
      {main_clients_entityFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_clients_entity.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_clients_entity.${index}.social_name` as const
                      )}
                      error={
                        errors?.main_clients_entity?.[index]?.social_name &&
                        true
                      }
                      helperText={
                        errors?.main_clients_entity?.[index]?.social_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_clients_entity.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de identificación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_clients_entity.${index}.identification_number` as const
                      )}
                      error={
                        errors?.main_clients_entity?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.main_clients_entity?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`main_clients_entity.${index}.economic_sector`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.economic_activity}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sector económico"
                          variant="standard"
                          error={
                            errors?.main_clients_entity?.[index]
                              ?.economic_sector && true
                          }
                          helperText={
                            errors?.main_clients_entity?.[index]
                              ?.economic_sector?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch_main_clients_entity?.[index].economic_sector?.id ===
                "15" && (
                <Grid item sm={6} xs={12}>
                  <Controller
                    name={`main_clients_entity.${index}.other_economic_sector`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Otro sector económico"
                        type="text"
                        variant="standard"
                        value={value}
                        disabled={completeData}
                        {...register(
                          `main_clients_entity.${index}.other_economic_sector` as const
                        )}
                        error={
                          errors?.main_clients_entity?.[index]
                            ?.other_economic_sector && true
                        }
                        helperText={
                          errors?.main_clients_entity?.[index]
                            ?.other_economic_sector?.message
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item sm={6} xs={11} sx={{ display: "flex" }}>
                <Controller
                  name={`main_clients_entity.${index}.contact`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contacto"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_clients_entity.${index}.contact` as const
                      )}
                      error={
                        errors?.main_clients_entity?.[index]?.contact && true
                      }
                      helperText={
                        errors?.main_clients_entity?.[index]?.contact?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      main_clients_entityRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {main_clients_entityFields.length < 3 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              main_clients_entityAppend({
                social_name: "",
                identification_number: "",
                economic_sector: [],
                other_economic_sector: "",
                contact: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar clientes
          </Button>
        </Grid>
      )}
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        </Stack>
      )}

      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step2Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="submit"
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
    </form>
  );
};

export default EconomicActivity;
