import { useState, createContext, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CalculateProfile, InvestmentProfileResults } from "../types/types";
import axios from "axios";
import { apis } from "../apis/apis";
import {
  getProfile,
  getCalculateProfile,
  getAllData,
  getAddressData,
  getAddressSector,
} from "../services/Services";
import { setAuthData, getItem } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";

const initialState = {
  investmentProfile: [],
  dataForm: undefined,
  getSectorAddress: () => true,
};

const ProfileContext = createContext<CalculateProfile>(initialState);

const ProfileProvider = ({ children, authData }: any) => {
  //const navigate = useNavigate();
  const [investmentProfile, setInvestmentProfile] = useState<
    InvestmentProfileResults[]
  >(
    localStorage.getItem("calculateProfile") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("calculateProfile"))
  );
  const [dataForm, setDataForm] = useState(initialState.dataForm);
  const [exists, setExists] = useState<boolean>(false);
  const [completeData, setCompleteData] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [steps_completed, setSteps_completed] = useState<any>([]);
  const [loaderAllData, setLoaderAllData] = useState<boolean>(false);
  const [data, setAllData] = useState<any>(
    localStorage.getItem("allDataTablesF") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("allDataTablesF"))
  );
  const [dataJ, setAllDataJ] = useState<any>(
    localStorage.getItem("allDataTablesJ") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("allDataTablesJ"))
  );
  const [address, setAddres] = useState(
    localStorage.getItem("addressData")
      ? //@ts-ignore
        JSON.parse(localStorage.getItem("addressData"))
      : {
          provinces: {},
          municipality: {},
          sector: {},
        }
  );

  const getAddress = async () => {
    const response = await getAddressData();
    if (response?.statusP === 200 && response?.statusM === 200) {
      setAddres({
        ...address,
        provinces: response.provinces.content,
        municipality: response.municipality.content,
        //sector: response.sector.content,
      });
      localStorage.setItem(
        "addressData",
        JSON.stringify({
          provinces: response.provinces.content,
          municipality: response.municipality.content,
          //sector: response.sector.content,
        })
      );
    }
  };

  const getSectorAddress = async (provinceID: string) => {
    const response: any = await getAddressSector(provinceID);
    if (response) {
      return response.content;
    }
  };

  const getAllDataTables = async () => {
    const responseF = await getAllData("F");
    if (responseF?.status === 200) {
      setAllData(responseF.body);
      localStorage.setItem("allDataTablesF", JSON.stringify(responseF.body));
    }

    const responseJ = await getAllData("J");
    if (responseJ?.status === 200) {
      setAllDataJ(responseJ.body);
      localStorage.setItem("allDataTablesJ", JSON.stringify(responseJ.body));
    }
  };

  const loadDataServer = async (value: string | null) => {
    const responseProfile = await getProfile(value);
    if (responseProfile?.status === 200) {
      sessionStorage.setItem("formType", responseProfile.body.formType);
      localStorage.setItem(
        "data_form",
        JSON.stringify(responseProfile.body.fields)
      );
      localStorage.setItem("stepForm", responseProfile.body.stepForm);
      setDataForm(responseProfile.body.fields);
      setSteps_completed([
        !!responseProfile.body.fields.step_one_complete,
        !!responseProfile.body.fields.step_two_complete,
        !!responseProfile.body.fields.step_three_complete,
        !!responseProfile.body.fields.step_four_complete,
        !!responseProfile.body.fields.all_steps_complete,
      ]);
      setExists(true);
      if (!!responseProfile.body.fields.step_four_complete) {
        setCompleteData(true);
      } else {
        setCompleteData(false);
      }

      return { status: responseProfile.status, content: responseProfile };
    } else {
      setSteps_completed([]);
      console.log(responseProfile?.messageError);
    }
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 100);
  }, [dataForm]);

  const getInvestmentProfile = async (value: string | null) => {
    const responseCalculate = await getCalculateProfile(value);
    if (responseCalculate?.status === 200) {
      setInvestmentProfile(responseCalculate.body);
      localStorage.setItem(
        "calculateProfile",
        JSON.stringify(responseCalculate.body)
      );
    } else {
      console.log(responseCalculate?.messageError);
    }
  };

  useLayoutEffect(() => {
    localStorage.getItem("user_id") &&
      loadDataServer(localStorage.getItem("user_id"));
    localStorage.getItem("user_id") &&
      getInvestmentProfile(localStorage.getItem("user_id"));

    const cargaGET = async () => {
      await getAllDataTables();
      await getAddress();
      setLoaderAllData(true);
    };
    cargaGET();
  }, []);

  const formContextValues: CalculateProfile = {
    investmentProfile,
    setInvestmentProfile,
    getInvestmentProfile,
    dataForm,
    setDataForm,
    loader,
    //loadDataFormStorage,
    loadDataServer,
    exists,
    setExists,
    steps_completed,
    completeData,
    setCompleteData,
    data,
    dataJ,
    address,
    loaderAllData,
    getSectorAddress,
  };

  return (
    <ProfileContext.Provider value={formContextValues}>
      {children}
    </ProfileContext.Provider>
  );
};

export { ProfileProvider };

export default ProfileContext;
