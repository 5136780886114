import { useState, FC, useEffect } from "react";
import usePhysycalForm from "../../hooks/usePhysycalForm";
import useProfile from "../../hooks/useProfile";
import {
  Stack,
  Grid,
  styled,
  TextField,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Checkbox,
  ListItemText,
  Box,
  Chip,
  Divider,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Dialog,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ButtonGradient from "../../components/ButtonGradient";
import FancyDialog from "../../components/FancyDialog";
import TitleForm from "../../components/TitleForm";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { InvestorProfileType } from "../../types/types";
import * as Yup from "yup";
import { data as datos } from "../../data/data";
import { formSchemaOptions } from "../../utils";
import { updateProfile } from "../../services/Services";
import { triggerAsyncId } from "async_hooks";

const LabelForm = styled(InputLabel)(({ theme }) => ({
  left: "-14px",
}));

const InvestorProfile: FC = () => {
  const {
    setActiveStep,
    step4Answered,
    setStep4Answered,
    step4Field,
    setStep4Field,
    handleBack,
    handleNext,
  } = usePhysycalForm();
  const {
    dataForm,
    completeData,
    loadDataServer,
    getInvestmentProfile,
    data,
    address,
  } = useProfile();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageConditional, setMessageConditional] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step4Field;
  }

  const defaultValues: InvestorProfileType = {
    initial_investment_amount: findData.initial_investment_amount
      ? data &&
        data.al_ta_initial_investment_amount.find(
          (f: any) => f.id === Number(findData.initial_investment_amount)
        )
      : [],
    ability_absorb_losses: findData.ability_absorb_losses
      ? data &&
        data.al_ta_ability_absorb_losses.find(
          (f: any) => f.id === Number(findData.ability_absorb_losses)
        )
      : [],
    risk_tolerance: findData.risk_tolerance
      ? data &&
        data.al_ta_risk_tolerance.find(
          (f: any) => f.id === Number(findData.risk_tolerance)
        )
      : [],
    investment_goals: findData.investment_goals
      ? data &&
        data.al_ta_investment_goals.find(
          (f: any) => f.id === Number(findData.investment_goals)
        )
      : [],
    desired_profitability: findData.desired_profitability
      ? data &&
        data.al_ta_desired_profitability.find(
          (f: any) => f.id === Number(findData.desired_profitability)
        )
      : [],
    investment_time_horizon: findData.investment_time_horizon
      ? data &&
        data.al_ta_investment_time_horizon.find(
          (f: any) => f.id === Number(findData.investment_time_horizon)
        )
      : [],
    channel_catchment_client: findData.channel_catchment_client
      ? data &&
        data.al_ta_channel_catchment_client.find(
          (f: any) => f.id === Number(findData.channel_catchment_client)
        )
      : [],
    fixedIncomeSecurities: findData.fixedIncomeSecurities
      ? JSON.parse(findData.fixedIncomeSecurities)
      : [],
    investmentFunds: findData.investmentFunds
      ? JSON.parse(findData.investmentFunds)
      : [],
    liquidityOperations: findData.liquidityOperations
      ? JSON.parse(findData.liquidityOperations)
      : [],
    variableIncomeSecurities: findData.variableIncomeSecurities
      ? JSON.parse(findData.variableIncomeSecurities)
      : [],
    otherServices: findData.otherServices
      ? JSON.parse(findData.otherServices)
      : [],
    knowledge_market_values: findData.knowledge_market_values
      ? data &&
        data.al_ta_knowledge_market_values.find(
          (f: any) => f.id === Number(findData.knowledge_market_values)
        )
      : [],
    information_media: findData.information_media
      ? data &&
        data.al_ta_information_media.find(
          (f: any) => f.id === Number(findData.information_media)
        )
      : [],
    professionalInvestor: findData.professionalInvestor
      ? data &&
        data.al_ta_professional_investor.find(
          (f: any) => f.id === Number(findData.professionalInvestor)
        )
      : [],
    expcStockMarketLeastThree: findData.expcStockMarketLeastThree
      ? findData.expcStockMarketLeastThree === "true" && true
      : false,
    equityCapacityLeastTwentyFive: findData.equityCapacityLeastTwentyFive
      ? findData.equityCapacityLeastTwentyFive === "true" && true
      : false,
    knowledgeVerified: findData.knowledgeVerified
      ? findData.knowledgeVerified === "true" && true
      : false,
    financialInstrumentsYear: (findData.financialInstrumentsYear &&
      JSON.parse(findData.financialInstrumentsYear)) ?? [
      {
        financialInstruments: [],
        experienceTime: [],
        numTransactionsYear: [],
        levelknowInstRisk: [],
      },
    ],
    notHaveExperience: findData.notHaveExperience
      ? findData.notHaveExperience
      : false,
  };

  const formSchemaFinanceInstruments = {
    financialInstruments: Yup.object()
      .nullable()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    experienceTime: Yup.object()
      .nullable()
      .when("financialInstruments", {
        is: (financialInstruments: any) => {
          if (!!financialInstruments?.id && financialInstruments?.id !== "1") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
      }),
    numTransactionsYear: Yup.object()
      .nullable()
      .when("financialInstruments", {
        is: (financialInstruments: any) => {
          if (!!financialInstruments?.id && financialInstruments?.id !== "1") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
      }),
    levelknowInstRisk: Yup.object()
      .nullable()
      .when("financialInstruments", {
        is: (financialInstruments: any) => {
          if (!!financialInstruments?.id && financialInstruments?.id !== "1") {
            //console.log(financialInstruments?.id);
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
      }),
  };

  const validation = Yup.object().shape(
    {
      initial_investment_amount: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      ability_absorb_losses: Yup.object()
        .shape({
          id: Yup.number().required("Required"),
          description: Yup.string().required("Required"),
        })
        .typeError("Campo es requerido"),
      risk_tolerance: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      investment_goals: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      desired_profitability: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      investment_time_horizon: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      channel_catchment_client: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      knowledge_market_values: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      information_media: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      professionalInvestor: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      expcStockMarketLeastThree: Yup.boolean(),
      equityCapacityLeastTwentyFive: Yup.boolean(),
      knowledgeVerified: Yup.boolean(),
      fixedIncomeSecurities: Yup.array(),
      investmentFunds: Yup.array(),
      liquidityOperations: Yup.array(),
      variableIncomeSecurities: Yup.array(),
      otherServices: Yup.array(),
      financialInstrumentsYear: Yup.array().of(
        Yup.object().shape(formSchemaFinanceInstruments)
      ),
      productsUsed: Yup.bool().when(
        [
          "investmentFunds",
          "fixedIncomeSecurities",
          "liquidityOperations",
          "variableIncomeSecurities",
          "otherServices",
          "notHaveExperience",
        ],
        {
          is: (
            fixedIncomeSecurities: any,
            investmentFunds: any,
            liquidityOperations: any,
            variableIncomeSecurities: any,
            otherServices: any,
            notHaveExperience: any
          ) =>
            fixedIncomeSecurities?.length === 0 &&
            investmentFunds?.length === 0 &&
            liquidityOperations?.length === 0 &&
            variableIncomeSecurities?.length === 0 &&
            otherServices?.length === 0 &&
            notHaveExperience === false,
          then: Yup.bool().required("Debe seleccionar al menos uno"),
          otherwise: Yup.bool(),
        }
      ),
    },
    [
      ["fixedIncomeSecurities", "investmentFunds"],
      ["fixedIncomeSecurities", "liquidityOperations"],
      ["fixedIncomeSecurities", "variableIncomeSecurities"],
      ["fixedIncomeSecurities", "otherServices"],
      ["investmentFunds", "liquidityOperations"],
      ["investmentFunds", "variableIncomeSecurities"],
      ["investmentFunds", "otherServices"],
      ["liquidityOperations", "variableIncomeSecurities"],
      ["liquidityOperations", "otherServices"],
      ["variableIncomeSecurities", "otherServices"],
    ]
  );

  const {
    register,
    handleSubmit,
    control,
    formState,
    watch,
    resetField,
    getValues,
    trigger,
  } = useForm<InvestorProfileType>({
    defaultValues,
    resolver: yupResolver(validation),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { errors, dirtyFields, isValid } = formState;
  const {
    fields: financialInstrumentsYearField,
    append: financialInstrumentsYearAppend,
    remove: financialInstrumentsYearRemove,
  } = useFieldArray({
    control,
    name: "financialInstrumentsYear",
    rules: {
      minLength: 0,
      maxLength: 3,
    },
  });
  const watchProfessionalInvestor: any = watch("professionalInvestor");
  const watchNotHaveExperience: any = watch("notHaveExperience");
  const watchproductFields = watch([
    "investmentFunds",
    "fixedIncomeSecurities",
    "liquidityOperations",
    "variableIncomeSecurities",
    "otherServices",
  ]);
  const watch_knowledge_market_values: any = watch("knowledge_market_values");
  const watch_financialInstrumentsYear: any = watch("financialInstrumentsYear");

  useEffect(() => {
    if (
      watchproductFields[0].length > 0 ||
      watchproductFields[1].length > 0 ||
      watchproductFields[2].length > 0 ||
      watchproductFields[3].length > 0 ||
      watchproductFields[4].length > 0
    ) {
      setMessageConditional(false);
    } else {
      setMessageConditional(true);
    }
  }, [watchproductFields, watchNotHaveExperience]);

  useEffect(() => {
    if (watchNotHaveExperience) {
      resetField("investmentFunds");
      resetField("fixedIncomeSecurities");
      resetField("liquidityOperations");
      resetField("variableIncomeSecurities");
      resetField("otherServices");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNotHaveExperience]);

  useEffect(() => {
    if (watchProfessionalInvestor?.id === "1") {
      resetField("expcStockMarketLeastThree");
      resetField("equityCapacityLeastTwentyFive");
      resetField("knowledgeVerified");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProfessionalInvestor]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validateForm = async () => {
    await trigger();
    if (isValid) {
      setOpenDialog(true);
    }
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    const asingnumber = {
      fixedIncomeSecurities: 1,
      investmentFunds: 2,
      liquidityOperations: 3,
      variableIncomeSecurities: 4,
      otherServices: 5,
    };

    const op_values_finance_array = [];

    const instruments: any[] = [];
    const time_experience_instrument: any[] = [];
    const number_operations_year: any[] = [];
    const knowledge_level: any[] = [];

    for ([key, value] of Object.entries(data)) {
      if (!value || value.length === 0) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id
            ? typeof value.id !== "string"
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value);
      }
      if (
        key === "fixedIncomeSecurities" ||
        key === "investmentFunds" ||
        key === "liquidityOperations" ||
        key === "variableIncomeSecurities" ||
        key === "otherServices"
      ) {
        op_values_finance_array.push({
          //@ts-ignore
          [asingnumber[key]]: value,
        });
      }
    }
    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      instruments.push(Number(e.financialInstruments?.id));
      time_experience_instrument.push(Number(e.experienceTime?.id));
      number_operations_year.push(Number(e.numTransactionsYear?.id));
      knowledge_level.push(Number(e.levelknowInstRisk?.id));
    });

    const bonos_BCRD_hacienda: any[] = [];
    const fondos_abiertos_y_cerrados: any[] = [];
    const operaciones_a_plazo_forwards: any[] = [];
    const prestamos_a_titulos_de_valores_mutuo: any[] = [];
    const accciones: any[] = [];
    const contratos_de_reportes_repos: any[] = [];
    const bonos_corporativos_papeles_comerciales: any[] = [];
    const derivados_de_cobertura_no_especulativos: any[] = [];
    const prestamos_de_margen: any[] = [];
    const notes: any[] = [];
    const letters: any[] = [];

    let financialInstrumentsName: any = [];
    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      if (Number(e.financialInstruments.id) === 2) {
        bonos_BCRD_hacienda.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 3) {
        fondos_abiertos_y_cerrados.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 4) {
        operaciones_a_plazo_forwards.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 5) {
        prestamos_a_titulos_de_valores_mutuo.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 6) {
        accciones.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 7) {
        contratos_de_reportes_repos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 15) {
        bonos_corporativos_papeles_comerciales.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 16) {
        derivados_de_cobertura_no_especulativos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 17) {
        prestamos_de_margen.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 21) {
        notes.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 22) {
        letters.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
    });

    data.bonos_BCRD_hacienda = JSON.stringify(bonos_BCRD_hacienda);
    data.fondos_abiertos_y_cerrados = JSON.stringify(
      fondos_abiertos_y_cerrados
    );
    data.operaciones_a_plazo_forwards = JSON.stringify(
      operaciones_a_plazo_forwards
    );
    data.prestamos_a_titulos_de_valores_mutuo = JSON.stringify(
      prestamos_a_titulos_de_valores_mutuo
    );
    data.accciones = JSON.stringify(accciones);
    data.contratos_de_reportes_repos = JSON.stringify(
      contratos_de_reportes_repos
    );
    data.bonos_corporativos_papeles_comerciales = JSON.stringify(
      bonos_corporativos_papeles_comerciales
    );
    data.derivados_de_cobertura_no_especulativos = JSON.stringify(
      derivados_de_cobertura_no_especulativos
    );
    data.prestamos_de_margen = JSON.stringify(prestamos_de_margen);

    //nuevos instrumentos
    data.notes = JSON.stringify(notes);
    data.letters = JSON.stringify(letters);
    //-----fin nuevos

    data.operations_values_finance = JSON.stringify(op_values_finance_array);
    data.instruments = JSON.stringify(instruments);
    data.time_experience_instrument = JSON.stringify(
      time_experience_instrument
    );
    data.number_operations_year = JSON.stringify(number_operations_year);
    data.knowledge_level = JSON.stringify(knowledge_level);
    data.step_four_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
      if (
        dirtyFields.fixedIncomeSecurities ||
        dirtyFields.investmentFunds ||
        dirtyFields.liquidityOperations ||
        dirtyFields.variableIncomeSecurities ||
        dirtyFields.otherServices
      ) {
        Object.assign(update, {
          operations_values_finance: JSON.stringify(op_values_finance_array),
        });
      }
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep4Answered && setStep4Answered(true);
      setStep4Field && setStep4Field(data);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_four_complete !== "true") {
      setOpenDialog(false);
      let fullData = {
        fields: {
          ...data,
        },
        formType: "F",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Perfil del inversionista",
      };
      localStorage.setItem("stepForm", "Perfil del inversionista");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        getInvestmentProfile &&
          getInvestmentProfile(sessionStorage.getItem("user_id"));
        setLoading(false);
        handleResponse();
      }
    } else {
      setLoading(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <>
      <form>
        <TitleForm sx={{ mb: 4 }}>Perfil del inversionista</TitleForm>
        <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="initial_investment_amount"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_initial_investment_amount}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Monto de Inversión Inicial"
                      variant="standard"
                      autoFocus
                      error={!!errors.initial_investment_amount}
                      helperText={
                        errors.initial_investment_amount &&
                        errors.initial_investment_amount.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="ability_absorb_losses"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data?.al_ta_ability_absorb_losses}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Capacidad de absorber pérdidas de capital"
                      variant="standard"
                      error={!!errors.ability_absorb_losses}
                      helperText={
                        errors.ability_absorb_losses &&
                        errors.ability_absorb_losses.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="risk_tolerance"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_risk_tolerance}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tolerancia al Riesgo"
                      variant="standard"
                      error={!!errors.risk_tolerance}
                      helperText={
                        errors.risk_tolerance && errors.risk_tolerance.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="investment_goals"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_investment_goals}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Objetivos de Inversión"
                      variant="standard"
                      error={!!errors.investment_goals}
                      helperText={
                        errors.investment_goals &&
                        errors.investment_goals.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="desired_profitability"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_desired_profitability}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Rentabilidad Deseada"
                      variant="standard"
                      error={!!errors.desired_profitability}
                      helperText={
                        errors.desired_profitability &&
                        errors.desired_profitability.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="investment_time_horizon"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_investment_time_horizon}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Horizonte de Tiempo de la Inversión"
                      variant="standard"
                      error={!!errors.investment_time_horizon}
                      helperText={
                        errors.investment_time_horizon &&
                        errors.investment_time_horizon.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              control={control}
              name="information_media"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_information_media}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Medios de información utilizados sobre los mercados financieros"
                      variant="standard"
                      error={!!errors.information_media}
                      helperText={
                        errors.information_media &&
                        errors.information_media.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              control={control}
              name="channel_catchment_client"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_channel_catchment_client}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Canal de Captación del Cliente"
                      variant="standard"
                      error={!!errors.channel_catchment_client}
                      helperText={
                        errors.channel_catchment_client &&
                        errors.channel_catchment_client.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
          Productos Utilizados
        </TitleForm>
        {/* <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item xs={12}>
            <Controller
              control={control}
              name="notHaveExperience"
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <FormControlLabel
                  control={
                    <Checkbox onChange={onChange} checked={value} {...field} />
                  }
                  label="No poseo experiencia en ninguno de estos productos"
                  disabled={completeData}
                />
              )}
            />
          </Grid>
        </Grid> */}
        {watchNotHaveExperience === false && (
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="fixedIncomeSecurities"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={completeData}>
                    <LabelForm>Producto de Renta Fija</LabelForm>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("fixedIncomeSecurities")}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                datos.fixedIncomeSecurities.find(
                                  (item: any) => item.id === value
                                ).description
                              }
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.fixedIncomeSecurities.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      ))}
                    </Select>
                    {messageConditional && (errors as any).productsUsed && (
                      <FormHelperText>
                        {(errors as any).productsUsed.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="investmentFunds"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={completeData}>
                    <LabelForm>Fondos de Inversión</LabelForm>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("investmentFunds")}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                datos.investmentFunds.find(
                                  (item: any) => item.id === value
                                ).description
                              }
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.investmentFunds.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      ))}
                    </Select>
                    {messageConditional && (errors as any).productsUsed && (
                      <FormHelperText>
                        {(errors as any).productsUsed.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="liquidityOperations"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={completeData}>
                    <LabelForm>Operaciones de Liquidez</LabelForm>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("liquidityOperations")}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                datos.liquidityOperations.find(
                                  (item: any) => item.id === value
                                ).description
                              }
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.liquidityOperations.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      ))}
                    </Select>
                    {messageConditional && (errors as any).productsUsed && (
                      <FormHelperText>
                        {(errors as any).productsUsed.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="variableIncomeSecurities"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={completeData}>
                    <LabelForm>Valores de Renta Variable</LabelForm>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("variableIncomeSecurities")}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                datos.variableIncomeSecurities.find(
                                  (item: any) => item.id === value
                                ).description
                              }
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.variableIncomeSecurities.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      ))}
                    </Select>
                    {messageConditional && (errors as any).productsUsed && (
                      <FormHelperText>
                        {(errors as any).productsUsed.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="otherServices"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={completeData}>
                    <LabelForm>Otros Servicios</LabelForm>
                    <Select
                      variant="standard"
                      value={value}
                      multiple
                      {...register("otherServices")}
                      renderValue={(selected: any) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected.map((value: any) => (
                            <Chip
                              key={value}
                              label={
                                datos.otherServices.find(
                                  (item: any) => item.id === value
                                ).description
                              }
                              color="primary"
                              size="small"
                              sx={{ mb: "2px" }}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {datos.otherServices.map((item: any) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Checkbox checked={value.indexOf(item.id) > -1} />
                          <ListItemText primary={item.description} />
                        </MenuItem>
                      ))}
                    </Select>
                    {messageConditional && (errors as any).productsUsed && (
                      <FormHelperText>
                        {(errors as any).productsUsed.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        )}
        <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
          Conocimiento General del Mercado
        </TitleForm>
        <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item md={6} sm={6} xs={12}>
            <Controller
              control={control}
              name="knowledge_market_values"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_knowledge_market_values}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nivel de Conocimiento"
                      variant="standard"
                      error={!!errors.knowledge_market_values}
                      helperText={
                        errors.knowledge_market_values &&
                        errors.knowledge_market_values.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item md={6} sm={6} xs={12}>
            <Controller
              control={control}
              name="professionalInvestor"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_professional_investor}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Se considera inversionista profesional?"
                      variant="standard"
                      error={!!errors.professionalInvestor}
                      helperText={
                        errors.professionalInvestor &&
                        errors.professionalInvestor.message
                      }
                    />
                  )}
                />
              )}
            />
          </Grid>

          {watchProfessionalInvestor?.description === "Sí" && (
            <Grid item xs={12}>
              <Controller
                control={control}
                name="expcStockMarketLeastThree"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    sx={{ alignItems: "flex-start", mb: 2 }}
                    disabled={completeData}
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Cuenta con experiencia, comprobable mediante la realización de operaciones en el mercado de valores nacional o extranjero, con un volumen de al menos tres millones de pesos dominicanos (DOP 3,000,000) o su equivalente en cualquier otra moneda por año, con una frecuencia media de más de tres (3) operaciones por trimestre en el año previo a su clasificación como cliente profesional"
                  />
                )}
              />
              <Controller
                control={control}
                name="equityCapacityLeastTwentyFive"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    sx={{ alignItems: "flex-start", mb: 2 }}
                    disabled={completeData}
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Cuenta con Capacidad patrimonial, comprobable mediante la determinación de que sus activos en efectivo o inversiones en instrumentos financieros sean de al menos veinticinco millones de pesos dominicanos (DOP 25,000,000) o su equivalente en cualquier otra moneda, depositados en una entidad de intermediación financiera o inversiones locales o extranjeras"
                  />
                )}
              />
              <Controller
                control={control}
                name="knowledgeVerified"
                rules={{ required: true }}
                render={({ field: { onChange, value, ...field } }) => (
                  <FormControlLabel
                    sx={{ alignItems: "flex-start", mb: 2 }}
                    disabled={completeData}
                    control={
                      <Checkbox
                        onChange={onChange}
                        checked={value}
                        {...field}
                      />
                    }
                    label="Cuenta con Conocimiento, verificado, al menos, uno de los siguientes aspectos: (i) que ha laborado en cargos de dirección en el área de negocios o áreas afines de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (ii) que es o ha sido miembro del consejo de administración de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (iii) que cuenta con la certificación de corredor de valores vigente o su equivalente de otro país, al momento de su calificación; o, (iv) Que tenga o haya tenido un cargo de dirección o gerencial en el área de tesorería, inversiones, riesgos, finanzas o contraloría por un periodo de al menos dos (2) años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (v) Que sea o haya sido miembro del consejo de administración por un periodo de al menos dos años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (vi) Que haya laborado en cargos de dirección o gerencial en entidades financieras extranjeras u organismos multilaterales de los cuales la República Dominicana sea miembro por un período de al menos dos (2) años, o; (vii) Que sea o haya sido funcionario las Superintendencias del Mercado de Valores, de Bancos, de Seguros o de Pensiones, del Banco Central de la República Dominicana o de la Junta Monetaria"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
          Instrumentos financieros negociados en ultimos 2 años
        </TitleForm>
        {financialInstrumentsYearField.map((field, index) => {
          return (
            <div key={index}>
              {index !== 0 && (
                <Divider
                  sx={{
                    pt: 3,
                    mb: 3,
                    borderColor: "#efe5df",
                    width: "50%",
                    ml: "auto",
                    mr: "auto",
                  }}
                />
              )}
              <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Controller
                    control={control}
                    name={`financialInstrumentsYear.${index}.financialInstruments`}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={data.al_ta_instrument}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        fullWidth
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Instrumentos"
                            variant="standard"
                            error={
                              errors?.financialInstrumentsYear?.[index]
                                ?.financialInstruments && true
                            }
                            helperText={
                              errors?.financialInstrumentsYear?.[index]
                                ?.financialInstruments?.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                  {index !== 0 && !completeData && (
                    <Button
                      type="button"
                      variant="outlined"
                      aria-label="delete"
                      onClick={() => {
                        financialInstrumentsYearRemove(index);
                      }}
                      sx={{
                        width: "100%",
                        maxWidth: "180px",
                        ml: 2,
                        fontSize: "0.75rem",
                      }}
                    >
                      <RemoveCircleIcon fontSize="small" sx={{ mr: 1 }} />
                      Eliminar Instrumento
                    </Button>
                  )}
                </Grid>
                {!!watch_financialInstrumentsYear[index]?.financialInstruments
                  ?.id &&
                  watch_financialInstrumentsYear[index]?.financialInstruments
                    ?.id !== 1 && (
                    <>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          control={control}
                          name={`financialInstrumentsYear.${index}.experienceTime`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              options={data.al_ta_time_experience_instrument}
                              getOptionLabel={(option: any) =>
                                option.description || ""
                              }
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              value={value}
                              disabled={completeData}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Tiempo de Experiencia"
                                  variant="standard"
                                  error={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.experienceTime && true
                                  }
                                  helperText={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.experienceTime?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          control={control}
                          name={`financialInstrumentsYear.${index}.numTransactionsYear`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              options={data.al_ta_number_operations_year}
                              getOptionLabel={(option: any) =>
                                option.description || ""
                              }
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              value={value}
                              disabled={completeData}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Cantidad de Operaciones al Año"
                                  variant="standard"
                                  error={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.numTransactionsYear && true
                                  }
                                  helperText={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.numTransactionsYear?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Controller
                          control={control}
                          name={`financialInstrumentsYear.${index}.levelknowInstRisk`}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              fullWidth
                              onChange={(event, newValue) => {
                                onChange(newValue);
                              }}
                              options={data.al_ta_knowledge_level}
                              getOptionLabel={(option: any) =>
                                option.description || ""
                              }
                              isOptionEqualToValue={(option: any, value: any) =>
                                option.id === value.id
                              }
                              value={value}
                              disabled={completeData}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Nivel de conocimiento instrumento y riesgo"
                                  variant="standard"
                                  error={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.levelknowInstRisk && true
                                  }
                                  helperText={
                                    errors?.financialInstrumentsYear?.[index]
                                      ?.levelknowInstRisk?.message
                                  }
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
              </Grid>
            </div>
          );
        })}
        {financialInstrumentsYearField.length < 3 && !completeData && (
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Button
              type="button"
              variant="outlined"
              onClick={() => {
                financialInstrumentsYearAppend({
                  financialInstruments: [],
                  experienceTime: [],
                  numTransactionsYear: [],
                  levelknowInstRisk: [],
                });
              }}
            >
              <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
              Instrumento
            </Button>
          </Grid>
        )}
        <Typography variant="body2">
          * Máximo 3 instrumentos financieros{" "}
        </Typography>
        <Stack
          justifyContent="center"
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
        >
          {loading && (
            <CircularProgress
              size={24}
              sx={{ margin: "5px auto", color: "#6fc555" }}
            />
          )}
        </Stack>
        <Stack
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
          spacing={2}
        >
          {completeData ? (
            <>
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
              <ButtonGradient
                type="button"
                onClick={handleNext}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Continuar
              </ButtonGradient>
            </>
          ) : (
            <>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title={
                  step4Answered === false
                    ? "Perderas la información no guardada"
                    : ""
                }
                placement="top"
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={handleBack}
                  sx={{ width: { xs: "100%", sm: "auto" } }}
                >
                  Regresar
                </Button>
              </Tooltip>
              <ButtonGradient
                type="button"
                //onClick={() => setOpenDialog(true)}
                onClick={validateForm}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Continuar
              </ButtonGradient>
            </>
          )}
        </Stack>
        <Dialog open={openDialog} onClose={handleClose}>
          <FancyDialog
            textTitle={`¿Estas seguro de guardar todos los datos?`}
            textChildren={`Si guardas este paso, no podrás regresar a editar la información anterior`}
            actionButton={
              <>
                {" "}
                <Button
                  variant="contained"
                  sx={{ mt: 2, mr: 2 }}
                  onClick={handleClose}
                  autoFocus
                >
                  Cancelar
                </Button>
                <ButtonGradient type="submit" onClick={handleSubmit(onSubmit)}>
                  Aceptar
                </ButtonGradient>{" "}
              </>
            }
          />
        </Dialog>
      </form>
    </>
  );
};

export default InvestorProfile;
