import { useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import {
  Stack,
  Grid,
  Typography,
  styled,
  TextField,
  InputAdornment,
  FormControl,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link as LinkHref,
  CircularProgress,
  FormHelperText,
  Autocomplete,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationRegister } from "../validation";
import { registerTypes } from "../../../types/types";
import axios from "axios";
import { apis } from "../../../apis/apis";
//@ts-ignore
import Pdf from "../../../assets/documents/Altio-términos-y-condiciones.pdf";
import { data as datos } from "../../../data/data";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const Register: FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const formOptions = { resolver: yupResolver(validationRegister) };

  const defaultValues: registerTypes = {
    idType: { id: "1", description: "Cédula Identidad" },
    identification: "",
    name: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    acceptPoliciesAndServices: false,
  };

  const { register, handleSubmit, formState, control, watch } =
    useForm<registerTypes>({
      defaultValues,
      resolver: yupResolver(validationRegister),
    });
  const watch_IDType = watch("idType");
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: any) => {
    const dataRegister = {
      idType: JSON.stringify(data?.idType),
      identification: data.identification,
      firstName: data.name,
      lastName: data.lastName,
      password: data.password,
      username: data.email,
      userRole: "Cliente",
    };

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    setLoading(true);
    try {
      const response = await axios.post(
        apis.user.create,
        JSON.stringify(dataRegister),
        { headers }
      );
      if (!response) {
        setLoading(false);
        setMessage("Ha ocurrido un error");
      } else {
        setLoading(false);
        navigate("/validate-register/1");
      }
    } catch (e: any) {
      setMessage(e.response.data.message);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Regístrate</TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name={"name"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nombre"
                type="text"
                variant="standard"
                autoComplete="name"
                {...register("name")}
                error={errors.name && Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"lastName"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Apellido"
                type="text"
                variant="standard"
                autoComplete="lastName"
                {...register("lastName")}
                error={errors.lastName && Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="idType"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue);
                }}
                options={datos.id_type}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tipo de Identificación"
                    variant="standard"
                    error={!!errors.idType}
                    helperText={
                      //@ts-ignore
                      errors.idType && errors.idType.description.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"identification"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nro. de Identificación"
                type="text"
                variant="standard"
                autoComplete="identification"
                {...register("identification")}
                error={errors.identification && Boolean(errors.identification)}
                helperText={
                  errors.identification && errors.identification.message
                }
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"email"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Email"
                type="email"
                variant="standard"
                autoComplete="email"
                {...register("email")}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"password"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                label="Contraseña"
                size="small"
                type={showPassword ? "text" : "password"}
                variant="standard"
                autoComplete="password"
                {...register("password")}
                error={!!errors.password}
                helperText={!!errors.password && errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"repeatPassword"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                label="Confirmar Contraseña"
                size="small"
                type={showPassword ? "text" : "password"}
                variant="standard"
                autoComplete="repeatPassword"
                {...register("repeatPassword")}
                error={errors.repeatPassword && Boolean(errors.repeatPassword)}
                helperText={
                  errors.repeatPassword && errors.repeatPassword.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"acceptPoliciesAndServices"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      {...register("acceptPoliciesAndServices")}
                      icon={
                        <CircleOutlinedIcon
                          color="secondary"
                          fontSize="small"
                        />
                      }
                      checkedIcon={<CheckCircleOutlineIcon fontSize="small" />}
                    />
                  }
                  label={
                    <Typography color="text">
                      Acepto las{" "}
                      <LinkHref
                        href={Pdf}
                        target="_blank"
                        sx={{ color: "#00000080" }}
                      >
                        Políticas y Servicios
                      </LinkHref>{" "}
                      de ALTIO SAFI.
                    </Typography>
                  }
                />
                {errors.acceptPoliciesAndServices && (
                  <FormHelperText>
                    {errors.acceptPoliciesAndServices.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Stack pt={3} pb={1}>
        <ButtonGradient type="submit">Únete ahora</ButtonGradient>
        {loading && (
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        )}
        {message && (
          <Typography sx={{ mt: 1 }} color="success.main" align="center">
            {message}
          </Typography>
        )}
      </Stack>
      <Typography align="center" sx={{ mt: 2 }} variant="body2">
        ¿Ya tienes una cuenta? <LinkTo to="/">Inicia sesión</LinkTo>
      </Typography>
    </form>
  );
};

export default Register;
