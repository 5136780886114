import { useState, ReactElement } from "react";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import ButtonGradient from "./ButtonGradient";

interface DialogProps {
  textTitle?: string;
  textChildren?: string;
  actionButton?: any;
}

const FancyDialog = ({
  textTitle,
  textChildren,
  actionButton,
}: DialogProps) => {
  return (
    <>
      <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
        {textTitle}
      </DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <DialogContentText id="alert-dialog-description">
          {textChildren}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: 4, px: 4 }}>{actionButton}</DialogActions>
    </>
  );
};

export default FancyDialog;
