import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme/theme";
import { PhysicalFormProvider } from "./context/PhysicalFormProvider";
import { ProfileProvider } from "./context/ProfileProvider";
import AppRoutes from "./AppRoutes";
import { RouterProvider } from "react-router-dom";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProfileProvider>
        <RouterProvider router={AppRoutes} />
      </ProfileProvider>
    </ThemeProvider>
  );
}
/* function App() {
  return (
    <ThemeProvider theme={theme}>
      <ProfileProvider>
        <AppRoutes />
      </ProfileProvider>
    </ThemeProvider>
  );
} */

export default App;
