import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

interface DialogProps {
  textChildren?: string;
  actionButton?: any;
}

const DeleteModal = ({ textChildren, actionButton }: DialogProps) => {
  return (
    <>
      <DialogTitle>
        <DeleteIcon sx={{ fontSize: "1rem", width: 16, color: "#3e627c" }} />
        Eliminar Registro
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ¿Está seguro de eliminar <strong>{textChildren}</strong>?
        </DialogContentText>
      </DialogContent>
      <DialogActions>{actionButton}</DialogActions>
    </>
  );
};

export default DeleteModal;
