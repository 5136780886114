import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import useProfile from "../../../hooks/useProfile";
import {
  Stack,
  Grid,
  Button,
  Typography,
  styled,
  TextField,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationLogin } from "../validation";
import { LoginTypes } from "../../../types/types";
import { useCookies } from "react-cookie";
import {
  getCode,
  getAutenticate,
  getProfile,
  getUserData,
} from "../../../services/Services";
import { getAuthData, setItem } from "../../../utils/LocalStorageManager";
import { useApp } from "../../../hooks/useApp";

const LinkTo = styled(Link)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TextLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
  width: "100%",
}));

const Login: FC = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["altio_auth"]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messageLogin, setMessageLogin] = useState("");
  const [hiddenCode, setHiddenCode] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const { loginProvider, existProfile } = useApp();
  const { loadDataServer, getInvestmentProfile, setExists } = useProfile();
  const formOptions = { resolver: yupResolver(validationLogin) };

  const { register, handleSubmit, formState, control } =
    useForm<LoginTypes>(formOptions);
  const { errors } = formState;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleGetCode = async (data: any) => {
    setLoading(true);
    const responseUser = await getCode(data);
    if (responseUser?.status === 200) {
      setLoading(false);
      setMessageLogin(responseUser?.message);
      setMessage("");
      setHiddenCode(false);
    } else {
      setLoading(false);
      setMessage(responseUser?.messageError);
    }
  };

  const handleSession = async (data: any) => {
    let responseAutenticate: any;
    try {
      setLoading(true);
      responseAutenticate = await getAutenticate(data);
      setCookie("altio_auth", responseAutenticate.body.tokenInfo.access_token, {
        path: "/",
      });

      await loginProvider(responseAutenticate.body);
      setExists && setExists(existProfile);

      loadDataServer && loadDataServer(responseAutenticate.body.id);
      getInvestmentProfile && getInvestmentProfile(responseAutenticate.body.id);
      sessionStorage.setItem("user_id", responseAutenticate.body.id);
      localStorage.setItem("user_id", responseAutenticate.body.id);
    } catch (error) {
      setLoading(false);
      setMessage(responseAutenticate?.messageError);
    }

    //@ts-ignore
    //loadDataServer && loadDataServer(responseValidate.id);

    /*
    /* if (responseAutenticate?.status === 200) {
      const responseProfile = await getProfile(responseAutenticate.body.id);
      loadDataServer && loadDataServer(responseAutenticate.body.id);
      const responseProfileCalculate =
        getInvestmentProfile &&
        getInvestmentProfile(responseAutenticate.body.id);
      sessionStorage.setItem("user_id", responseAutenticate.body.id);
      localStorage.setItem("user_id", responseAutenticate.body.id);

      if (responseProfile?.status === 200) {
        localStorage.setItem(
          "data_form",
          JSON.stringify(responseProfile.body.fields)
        );
        if (responseProfile.body.formType === "F") {
          navigate("formClient/physical");
        } else {
          navigate("formClient/legal");
        }
      } else {
        setExists && setExists(false);
        navigate("physical-or-legal");
      }
      setCookie("altio_auth", responseAutenticate.body.tokenInfo.access_token, {
        path: "/",
      });
    } else {
      setLoading(false);
      setMessage(responseAutenticate?.messageError);
    } */
  };

  const onSubmit = async (data: any, e: any) => {
    const userDataCode = {
      username: data.email,
      password: data.password,
      userPortal: "Formulario",
    };
    const userData = {
      username: data.email,
      password: data.password,
      code: data.code2fa,
      userPortal: "Formulario",
    };

    const typeAction = e.target.name;
    switch (typeAction) {
      case "getCode":
        await handleGetCode(userDataCode);
        break;
      case "getSession":
        await handleSession(userData);
        break;
      default:
        break;
    }
  };

  return (
    <form>
      <TitleForm>Iniciar sesión</TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name={"email"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Email"
                type="email"
                variant="standard"
                autoComplete="email"
                disabled={!hiddenCode}
                {...register("email")}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"password"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                label="Contraseña"
                size="small"
                type={showPassword ? "text" : "password"}
                variant="standard"
                autoComplete="passweord"
                disabled={!hiddenCode}
                {...register("password")}
                error={!!errors.password}
                helperText={!!errors.password && errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        {loading && (
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        )}
        <Grid item xs={12} hidden={hiddenCode}>
          <Controller
            name={"code2fa"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                label="Ingrese código"
                size="small"
                type="text"
                variant="standard"
                {...register("code2fa")}
                error={!!errors.code2fa}
                helperText={!!errors.code2fa && errors.code2fa?.message}
              />
            )}
          />
        </Grid>
      </Grid>
      {!hiddenCode && (
        <Stack alignItems={"flex-end"} mt={2}>
          <Button
            variant="text"
            color="primary"
            data-name="getCode"
            name="getCode"
            onClick={handleSubmit(onSubmit)}
          >
            Reenviar Código
          </Button>
        </Stack>
      )}
      <Stack pt={3} pb={1}>
        {hiddenCode ? (
          <ButtonGradient
            type="button"
            name="getCode"
            onClick={handleSubmit(onSubmit)}
          >
            Ingresar
          </ButtonGradient>
        ) : (
          <ButtonGradient
            type="button"
            name="getSession"
            onClick={handleSubmit(onSubmit)}
          >
            Ingresar
          </ButtonGradient>
        )}

        {message && (
          <Typography sx={{ mt: 1 }} color="success.main" align="center">
            {message}
          </Typography>
        )}
        {messageLogin && (
          <Typography
            color="primary"
            sx={{ mt: 1, mb: 2, textAlign: "center" }}
          >
            {messageLogin}
          </Typography>
        )}
      </Stack>
      <Typography align="center" sx={{ mt: 2 }} variant="body1">
        <LinkTo to="/recover-password">¿Olvidaste tu contraseña?</LinkTo>
      </Typography>
      <Typography align="center" sx={{ mt: 2 }} variant="body1">
        <LinkTo to="/resendNotificationActivateUser">
          Reenviar código de activar usuario
        </LinkTo>
      </Typography>
      <Button variant="contained" color="primary" sx={{ mt: 2 }} fullWidth>
        <TextLink to="/register">Registrate aquí</TextLink>
      </Button>
    </form>
  );
};

export default Login;
