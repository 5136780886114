import {
  BrowserRouter,
  Routes,
  Route,
  defer,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { AuthLayout } from "./components/security/AuthLayout";
import { getAuthData } from "./utils/LocalStorageManager";
import Auth from "./views/sessions/Auth";
import Login from "./views/sessions/login/Login";
import Register from "./views/sessions/register/Register";
import ForgotPassword from "./views/sessions/password/ForgotPassword";
import RecoverPassword from "./views/sessions/password/RecoverPassword";
import ValidateRegister from "./views/sessions/register/ValidateRegister";
import ActiveRegister from "./views/sessions/register/ActiveRegister";
import GeneralFormLayout from "./layouts/GeneralFormLayout";
import PhysicalOrLegalForm from "./views/PhysicalOrLegalForm";
import PhysicalForm from "./views/physicalForm/PhysicalForm";
import LegalForm from "./views/legalForm/LegalForm";
import PrivateRoute from "./PrivateRoute";
import { PhysicalFormProvider } from "./context/PhysicalFormProvider";
import { LegalFormProvider } from "./context/LegalFormProvider";
import ResendNotificationActivateUser from "./views/sessions/login/ResendNotificationActivateUser";

export const AppRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route
      element={<AuthLayout />}
      loader={async () => defer({ authPromise: getAuthData() })}
    >
      <Route path="/" element={<Auth />}>
        <Route index element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="validate-register/:id" element={<ValidateRegister />} />
        <Route
          path="active-register/:code/:username"
          element={<ActiveRegister />}
        />
        <Route path="recover-password" element={<RecoverPassword />} />
        <Route
          path="forgot-password/:code/:email"
          element={<ForgotPassword />}
        />
        <Route
          path="resendNotificationActivateUser"
          element={<ResendNotificationActivateUser />}
        />
      </Route>

      <Route
        path="/physical-or-legal"
        element={
          <PrivateRoute>
            <PhysicalOrLegalForm />
          </PrivateRoute>
        }
      />
      <Route
        path="formClient/"
        element={
          <PrivateRoute>
            <GeneralFormLayout />
          </PrivateRoute>
        }
      >
        <Route
          path="physical"
          element={
            <PhysicalFormProvider>
              <PhysicalForm />
            </PhysicalFormProvider>
          }
        />
        <Route
          path="legal"
          element={
            <LegalFormProvider>
              <LegalForm />
            </LegalFormProvider>
          }
        />
      </Route>
    </Route>
  )
);
/* const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />}>
          <Route index element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="validate-register/:id" element={<ValidateRegister />} />
          <Route
            path="active-register/:code/:username"
            element={<ActiveRegister />}
          />
          <Route path="recover-password" element={<RecoverPassword />} />
          <Route
            path="forgot-password/:code/:email"
            element={<ForgotPassword />}
          />
        </Route>

        <Route
          path="/physical-or-legal"
          element={
            <PrivateRoute>
              <PhysicalOrLegalForm />
            </PrivateRoute>
          }
        />
        <Route
          path="formClient/"
          element={
            <PrivateRoute>
              <GeneralFormLayout />
            </PrivateRoute>
          }
        >
          <Route
            path="physical"
            element={
              <PhysicalFormProvider>
                <PhysicalForm />
              </PhysicalFormProvider>
            }
          />
          <Route
            path="legal"
            element={
              <LegalFormProvider>
                <LegalForm />
              </LegalFormProvider>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}; */

export default AppRoutes;
