import { FC, useEffect, useState } from "react";
import usePhysycalForm from "../../hooks/usePhysycalForm";
import useProfile from "../../hooks/useProfile";
import {
  Stack,
  Grid,
  TextField,
  FormLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Divider,
  styled,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import ButtonGradient from "../../components/ButtonGradient";
import TitleForm from "../../components/TitleForm";
import InputLabelToForm from "../../components/InputLabelToForm";
import * as Yup from "yup";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  PhysycalCustomerIdentificationType,
  userDataType,
} from "../../types/types";
import { phoneRegExp, formSchemaOptions, formSchemaCountry } from "../../utils";
import { data as datos } from "../../data/data";
import { saveProfile, updateProfile } from "../../services/Services";
import { requestAK } from "../../services/BiometricAuth";
import { getItem } from "../../utils/LocalStorageManager";
import MessageManager from "../../components/utils/MessageManager";

const CustomTypography = styled(Typography)(() => ({
  position: "relative",
  padding: "4px 0 2px",
  opacity: "1",
  WebkitTextFillColor: "rgba(0, 0, 0, 0.38)",
  "&:before": {
    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
    left: "0",
    bottom: "0",
    content: '""',
    position: "absolute",
    right: "0",
    WebkitTransition:
      "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    pointerEvents: "none",
  },
}));

const CustomerIdenfication: FC = () => {
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [dataUserLogin, setDataUserLogin] = useState<userDataType | null>(null);
  const [responseAK, setResponseAK] = useState<any>(null);
  const {
    setActiveStep,
    setStep1Answered,
    step1Field,
    setStep1Field,
    handleNext,
  } = usePhysycalForm();
  const {
    dataForm,
    loader,
    completeData,
    loadDataServer,
    data,
    address,
    loaderAllData,
    getSectorAddress,
  } = useProfile();
  // eslint-disable-next-line

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step1Field;
  }

  const countryASC =
    data &&
    data.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const validation = Yup.object().shape({
    IDType: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    numberIdentity: Yup.string().nullable().required("Campo es requerido"),
    expirationDateID: Yup.date().typeError("Campo es requerido"),
    expedition_place: Yup.string().required("Campo es requerido"),
    name: Yup.string().required("Campo es requerido"),
    lastName: Yup.string().required("Campo es requerido"),
    gender: Yup.string().required("Campo es requerido"),
    dateOfBirth: Yup.date().typeError("Campo es requerido"),
    birthPlace: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    maritalStatus: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    academic_level: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    profession: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    email: Yup.string()
      .required("Campo es requerido")
      .email("Email es invalido"),
    phone: Yup.string()
      .required("Campo es requerido")
      .matches(phoneRegExp, "Celular no es valido")
      .min(8, "Celular no es valido")
      .max(12, "Celular no es valido"),
    since_when_is_contributor_altio: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    maritalIDType: Yup.object()
      .nullable()
      .when("maritalStatus", {
        is: (maritalStatus: any) => {
          if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
      }),
    maritalNumberIdentity: Yup.string().when("maritalStatus", {
      is: (maritalStatus: any) => {
        if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    maritalIDexpirationDate: Yup.date()
      .nullable()
      .when("maritalStatus", {
        is: (maritalStatus: any) => {
          if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
            return true;
          }
        },
        then: Yup.date().typeError("Campo es requerido"),
      }),
    maritalName: Yup.string().when("maritalStatus", {
      is: (maritalStatus: any) => {
        if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    maritalLastName: Yup.string().when("maritalStatus", {
      is: (maritalStatus: any) => {
        if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),

    marital_expedition_place: Yup.string().when("maritalStatus", {
      is: (maritalStatus: any) => {
        if (maritalStatus?.id === "2" || maritalStatus?.id === "3") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    nationality: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
    doubleNationality: Yup.boolean().required("Campo es requerido"),
    secondNationality: Yup.object()
      .nullable()
      .when("doubleNationality", {
        is: true,
        then: Yup.object()

          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    dominican_resident: Yup.boolean().required("Campo es requerido"),
    country: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
    province: Yup.object()
      .nullable()
      .when("country", {
        is: (country: any) => {
          if (country?.id === "63") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    sector: Yup.object()
      .nullable()
      .when("country", {
        is: (country: any) => {
          if (country?.id === "63") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    street: Yup.string().required("Campo es requerido"),
    houseApartNumber: Yup.string(),
    building: Yup.string(),
    telephoneResidence: Yup.string().matches(phoneRegExp, {
      message: "Teléfono no es valido",
      excludeEmptyString: true,
    }),
    politicallyExposedPerson: Yup.string().required("Campo es requerido"),
    positionPoliticExposedPerson: Yup.string().when(
      "politicallyExposedPerson",
      {
        is: "1",
        then: Yup.string().required("Campo es requerido"),
      }
    ),
    appointmentDatePoliticExposedPerson: Yup.date()
      .nullable()
      .when("politicallyExposedPerson", {
        is: "1",
        then: Yup.date().typeError("Campo es requerido"),
      }),
    removal_date_politic_exposed_person: Yup.date().nullable(),
    /* .when("politicallyExposedPerson", {
        is: "1",
        then: Yup.date().typeError("Campo es requerido"),
      }) */ partnerPoliticallyExposed:
      Yup.string().required("Campo es requerido"),
    PEPName: Yup.string().when("partnerPoliticallyExposed", {
      is: "1",
      then: Yup.string().required("Campo es requerido"),
    }),
    affinity: Yup.string()
      .nullable()
      .when("partnerPoliticallyExposed", {
        is: "1",
        then: Yup.string().required("Campo es requerido"),
      }),
    /* affinity: Yup.object()
      .nullable()
      .when("partnerPoliticallyExposed", {
        is: "1",
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }), */
    positionPEP: Yup.string().when("partnerPoliticallyExposed", {
      is: "1",
      then: Yup.string().required("Campo es requerido"),
    }),
    appointmentDate: Yup.date()
      .nullable()
      .when("partnerPoliticallyExposed", {
        is: "1",
        then: Yup.date().typeError("Campo es requerido"),
      }),
    removalDate: Yup.date().nullable(),
  });

  /**Consulta información del usario en el IndexDB */
  useEffect(() => {
    const getDataUser = async () => {
      const user: any = await getItem("dataUser");
      console.log("getDataUserLogin: ", user);
      setDataUserLogin(user);
    };
    getDataUser();
  }, []);

  const defaultValues: PhysycalCustomerIdentificationType = {
    //numberIdentity: findData.numberIdentity ? findData.numberIdentity : "",
    numberIdentity:
      findData.numberIdentity ?? dataUserLogin?.identification ?? "",
    IDType: findData.IDType
      ? datos.id_type.find((f: any) => f.id === findData.IDType)
      : [],

    expirationDateID: findData.expirationDateID
      ? new Date(findData.expirationDateID)
      : null,

    expedition_place: findData.expedition_place
      ? findData.expedition_place
      : "",
    name: findData.name ?? dataUserLogin?.firstName ?? "",
    //name: findData.name ? findData.name : "",
    //lastName: findData.lastName ? findData.lastName : "",
    lastName: findData.lastName ?? dataUserLogin?.lastName ?? "",
    gender: findData.gender ? findData.gender : "",
    dateOfBirth: findData.dateOfBirth ? new Date(findData.dateOfBirth) : null,
    birthPlace: findData.birthPlace
      ? data &&
        countryASC.find((f: any) => f.id === Number(findData.birthPlace))
      : [],
    maritalStatus: findData.maritalStatus
      ? datos.maritalStatus.find((f: any) => f.id === findData.maritalStatus)
      : [],
    academic_level: findData.academic_level
      ? data &&
        data.al_ta_academic_level?.find(
          (f: any) => f.id === Number(findData.academic_level)
        )
      : [],
    profession: findData.profession
      ? data &&
        data.al_ta_profession.find(
          (f: any) => f.id === Number(findData.profession)
        )
      : [],
    //email: findData.email ? findData.email : "",
    email: findData.email ?? dataUserLogin?.username ?? "",
    phone: findData.phone ? findData.phone : "",
    since_when_is_contributor_altio: findData.since_when_is_contributor_altio
      ? data &&
        data.al_ta_relationship_duration.find(
          (f: any) => f.id === Number(findData.since_when_is_contributor_altio)
        )
      : [],
    maritalIDType: findData.maritalIDType
      ? datos.id_type.find((f: any) => f.id === findData.maritalIDType)
      : [],
    maritalNumberIdentity: findData.maritalNumberIdentity
      ? findData.maritalNumberIdentity
      : "",
    maritalIDexpirationDate: findData.maritalIDexpirationDate
      ? new Date(findData.maritalIDexpirationDate)
      : null,
    maritalName: findData.maritalName ? findData.maritalName : "",
    maritalLastName: findData.maritalLastName ? findData.maritalLastName : "",
    marital_expedition_place: findData.expedition_place
      ? findData.expedition_place
      : "",
    nationality: findData.nationality
      ? data &&
        countryASC.find((f: any) => f.id === Number(findData.nationality))
      : [],
    doubleNationality: findData.doubleNationality
      ? findData.doubleNationality === "true" && true
      : false,
    secondNationality: findData.secondNationality
      ? data &&
        countryASC.find((f: any) => f.id === Number(findData.secondNationality))
      : [],
    dominican_resident: findData.dominican_resident
      ? findData.dominican_resident === "true" && true
      : false,
    country: findData.country
      ? data && countryASC.find((f: any) => f.id === Number(findData.country))
      : [],
    province: findData.province
      ? address &&
        address.provinces.find((f: any) => f.id === Number(findData.province))
      : [],
    sector: [],
    /*  sector: findData.sector
      ? address.sector.find((f: any) => f.id === Number(findData.sector))
      : [], */
    street: findData.street ? findData.street : "",
    houseApartNumber: findData.houseApartNumber
      ? findData.houseApartNumber
      : "",
    building: findData.building ? findData.building : "",
    telephoneResidence: findData.telephoneResidence
      ? findData.telephoneResidence
      : "",
    politicallyExposedPerson: findData.politicallyExposedPerson
      ? findData.politicallyExposedPerson
      : "",
    positionPoliticExposedPerson: findData.positionPoliticExposedPerson
      ? findData.positionPoliticExposedPerson
      : "",
    appointmentDatePoliticExposedPerson:
      findData.appointmentDatePoliticExposedPerson
        ? new Date(findData.appointmentDatePoliticExposedPerson)
        : null,
    removal_date_politic_exposed_person:
      findData.removal_date_politic_exposed_person
        ? new Date(findData.removal_date_politic_exposed_person)
        : null,
    partnerPoliticallyExposed: findData.partnerPoliticallyExposed
      ? findData.partnerPoliticallyExposed
      : "",
    PEPName: findData.PEPName ? findData.PEPName : "",
    affinity: findData.affinity
      ? datos.degrees_of_consanguinity.find((f: any) => f === findData.affinity)
      : "",
    positionPEP: findData.positionPEP ? findData.positionPEP : "",
    appointmentDate: findData.appointmentDate
      ? new Date(findData.appointmentDate)
      : null,
    removalDate: findData.removalDate ? new Date(findData.removalDate) : null,
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    reset,
    getValues,
    setValue,
  } = useForm<PhysycalCustomerIdentificationType>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors, isDirty, dirtyFields } = formState;
  const watchMaritalStatus: any = watch("maritalStatus");
  const watchDoubleNationality = watch("doubleNationality");
  const watchPoliticallyExposedPerson = watch("politicallyExposedPerson");
  const watchPartnerPoliticallyExposed = watch("partnerPoliticallyExposed");
  const watch_country: any = watch("country");
  const watch_province: any = watch("province");
  const w_numberIdentity: any = watch("numberIdentity");
  const w_IDType: any = watch("IDType");
  const [sectorData, setSectorData] = useState([]);

  const miarray: any = [];

  useEffect(() => {
    reset(defaultValues);
    console.log("dataUserLogin: ", dataUserLogin);
  }, [dataForm, dataUserLogin]);

  useEffect(() => {
    if (watchMaritalStatus.id !== "2" && watchMaritalStatus.id !== "3") {
      resetField("maritalIDType");
      resetField("maritalNumberIdentity");
      resetField("maritalIDexpirationDate");
      resetField("maritalName");
      resetField("maritalLastName");
    }
  }, [watchMaritalStatus]);

  useEffect(() => {
    if (!watchDoubleNationality) {
      resetField("secondNationality");
    }

    if (watchPoliticallyExposedPerson === "2") {
      resetField("positionPoliticExposedPerson");
      resetField("appointmentDatePoliticExposedPerson");
    }

    if (watchPartnerPoliticallyExposed === "2") {
      resetField("PEPName");
      resetField("affinity");
      resetField("positionPEP");
      resetField("appointmentDate");
      resetField("removalDate");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    watchDoubleNationality,
    watchPoliticallyExposedPerson,
    watchPartnerPoliticallyExposed,
  ]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.country) {
      resetField("province", { defaultValue: [] });

      resetField("sector", { defaultValue: [] });
    } else if (watch_country?.id !== 63) {
      resetField("province");
      resetField("sector");
    }
  }, [watch_country]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.province) {
      resetField("sector", { defaultValue: [] });
    } else {
      resetField("sector");
    }
    const getSector = async () => {
      const s: any = await getSectorAddress(watch_province.provinceCode);
      //console.log("s", s);
      const filterSector = s?.find(
        (f: any) => f.id === Number(findData.sector)
      );
      setValue("sector", filterSector);
      setSectorData(s);
    };

    if (watch_province?.provinceCode) {
      getSector();
    }
  }, [watch_province]);

  useEffect(() => {
    if (watchPartnerPoliticallyExposed !== "1") {
      resetField("PEPName", { defaultValue: "" });
      resetField("affinity", { defaultValue: "" });
      /* resetField("affinity", { defaultValue: [] }); */
      resetField("positionPEP", { defaultValue: "" });
      resetField("appointmentDate", { defaultValue: null });
      resetField("removalDate", { defaultValue: null });
    }
  }, [watchPartnerPoliticallyExposed]);

  useEffect(() => {
    if (watchPoliticallyExposedPerson !== "1") {
      resetField("positionPoliticExposedPerson", { defaultValue: "" });
      resetField("appointmentDatePoliticExposedPerson", { defaultValue: null });
    }
  }, [watchPoliticallyExposedPerson]);

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];
    let age: string = "";

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        //data[key] = value !== null && value.id ? value.id : `"${value}"`;
        data[key] =
          value !== null && value.id
            ? typeof value.id !== "string"
              ? JSON.stringify(value.id)
              : value.id
            : `"${value}"`;
      }
    }

    let birth = new Date(data.dateOfBirth);
    let birth_year = birth.getFullYear();
    let current_date = new Date();
    let current_year = current_date.getFullYear();
    let age_customer = current_year - birth_year;
    if (age_customer > 55) {
      age = "1";
    } else if (age_customer >= 35 && age_customer <= 55) {
      age = "2";
    } else {
      age = "3";
    }
    data.age = age;
    data.step_one_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : `"${value}"`,
        });
      } else {
        Object.assign(update, { [key]: value });
      }
      if (dirtyFields.dateOfBirth) {
        Object.assign(update, { age: age });
      }
      if (key === "country") {
        if (value !== 63) {
          Object.assign(update, { province: "" });
          Object.assign(update, { municipality: "" });
          Object.assign(update, { sector: "" });
        }
      }
      if (key === "partnerPoliticallyExposed") {
        if (value !== 2) {
          Object.assign(update, { PEPName: "" });
          Object.assign(update, { affinity: "" });
          Object.assign(update, { positionPEP: "" });
          Object.assign(update, { appointmentDate: "" });
          Object.assign(update, { removalDate: "" });
        }
      }

      if (key === "politicallyExposedPerson") {
        if (value !== 2) {
          Object.assign(update, { positionPoliticExposedPerson: "" });
          Object.assign(update, { appointmentDatePoliticExposedPerson: "" });
        }
      }
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep1Field && setStep1Field(data);
      setStep1Answered && setStep1Answered(true);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_one_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "F",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Identificación del Cliente",
      };
      localStorage.setItem("stepForm", "Identificación del Cliente");

      const saveData = await saveProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...update,
          },
          formType: "F",
          userId: sessionStorage.getItem("user_id"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const handleSearchBiometric = async () => {
    const CLIENT_ID = "92222255-96ed-4d05-bfcd-fc4bbfc4fa5f";
    const FLOW_ID = "668ff44b9dc98b0023c43145";
    const formData = {
      client_id: CLIENT_ID,
      response_type: "code",
      flow_id: FLOW_ID,
      user: {
        id_num: "18719689",
        //id_num: w_numberIdentity,
        country: "VE",
        doc_type: "CI",
      },
    };

    try {
      setLoadingSendData(true);
      const postAK = await requestAK(formData);
      setResponseAK(postAK);
      setLoadingSendData(false);
      console.log("postAK", postAK);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <>
      <form
        method="GET"
        action="https://api.qa.autentikar.com/s/altiosafi/oauth/authorize"
      >
        <input
          name="client_id"
          type="hidden"
          value="92222255-96ed-4d05-bfcd-fc4bbfc4fa5f"
        />
        <input name="response_type" type="hidden" value="code" />
        <input name="flow_id" type="hidden" value="668ff44b9dc98b0023c43145" />
        {/* <input
          name="user"
          type="hidden"
          value={`{"id_num":"${w_numberIdentity}","country":"CR","doc_type":"${
            w_IDType.id === "1" ? "CI" : "CI"
          }"}`}
        /> */}
        <input
          name="user"
          type="hidden"
          value='{"id_num":"02301495681","country":"RD","doc_type":"CI"}'
        />
        <TitleForm sx={{ mb: 4 }}>Identificación del Cliente</TitleForm>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 4 }}
          columnSpacing={{ xs: 2, sm: 4 }}
        >
          <Grid item sm={4} xs={12}>
            <TitleForm variant="body1" align="left" sx={{ mb: 0, mr: 1 }}>
              Tipo de identificación:
            </TitleForm>
            <CustomTypography variant="body1" color="initial">
              {/* {w_IDType.description} */}
              Cédula
            </CustomTypography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TitleForm variant="body1" align="left" sx={{ mb: 0, mr: 1 }}>
              Identificación:
            </TitleForm>
            <CustomTypography variant="body1" color="initial">
              {w_numberIdentity}
            </CustomTypography>
          </Grid>

          <Grid item sm={4} xs={12}>
            <ButtonGradient type="submit" sx={{ width: { xs: "100%" } }}>
              Validar
            </ButtonGradient>
          </Grid>
        </Grid>
      </form>
      <Divider
        sx={{
          pt: 3,
          mb: 3,
          borderColor: "#efe5df",
          width: "50%",
          ml: "auto",
          mr: "auto",
        }}
      />
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        </Stack>
      )}
      {responseAK?.status === true && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TitleForm sx={{ mb: 4 }}>Identificación del Cliente</TitleForm>
          {!loaderAllData ? (
            <>
              <Stack spacing={2} direction="row" justifyContent="center">
                <CircularProgress color="inherit" />
              </Stack>
            </>
          ) : (
            <>
              <Grid
                container
                rowSpacing={{ xs: 2, sm: 4 }}
                columnSpacing={{ xs: 2, sm: 4 }}
              >
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="IDType"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          onChange(newValue);
                        }}
                        options={datos.id_type}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled
                        //disabled={completeData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Tipo de Identificación"
                            variant="standard"
                            error={!!errors.IDType}
                            helperText={errors.IDType && errors.IDType.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"numberIdentity"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Documento de Identidad"
                        type="text"
                        variant="standard"
                        value={value}
                        disabled
                        //disabled={completeData}
                        {...register("numberIdentity")}
                        error={
                          errors.numberIdentity &&
                          Boolean(errors.numberIdentity)
                        }
                        helperText={
                          errors.numberIdentity && errors.numberIdentity.message
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"expirationDateID"}
                    control={control}
                    defaultValue={null}
                    render={({ field, ...props }) => (
                      <LocalizationProvider
                        dateAdapter={DateFnsUtils}
                        adapterLocale={esLocale}
                      >
                        <DatePicker
                          value={field.value}
                          disabled={completeData}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          label="Fecha de Expiración"
                          minDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 10
                              )
                            )
                          }
                          className={errors.expirationDateID && "Mui-error"}
                          maxDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() + 10
                              )
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              variant="standard"
                              helperText={
                                errors.expirationDateID &&
                                errors.expirationDateID.message
                              }
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"expedition_place"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Lugar de Expedición"
                        type="text"
                        variant="standard"
                        autoComplete="expedition_place"
                        disabled={completeData}
                        value={value}
                        {...register("expedition_place")}
                        error={
                          errors.expedition_place &&
                          Boolean(errors.expedition_place)
                        }
                        helperText={
                          errors.expedition_place &&
                          errors.expedition_place.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"name"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Nombres"
                        type="text"
                        variant="standard"
                        autoComplete="name"
                        disabled
                        //disabled={completeData}
                        value={value}
                        {...register("name")}
                        error={errors.name && Boolean(errors.name)}
                        helperText={errors.name && errors.name.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"lastName"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Apellidos"
                        type="text"
                        variant="standard"
                        value={value}
                        disabled
                        //disabled={completeData}
                        autoComplete="lastName"
                        {...register("lastName")}
                        error={errors.lastName && Boolean(errors.lastName)}
                        helperText={errors.lastName && errors.lastName.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"gender"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        className={completeData ? "disabled-select" : ""}
                      >
                        <InputLabelToForm
                          error={errors.gender && Boolean(errors.gender)}
                        >
                          Sexo
                        </InputLabelToForm>
                        <Select
                          label="Género"
                          variant="standard"
                          value={value}
                          {...register("gender")}
                          error={errors.gender && Boolean(errors.gender)}
                        >
                          <MenuItem value="Femenino">Femenino</MenuItem>
                          <MenuItem value="Masculino">Masculino</MenuItem>
                        </Select>
                        {errors.gender && (
                          <FormHelperText color="error">
                            {errors.gender.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"dateOfBirth"}
                    control={control}
                    defaultValue={null}
                    render={({ field, ...props }) => (
                      <LocalizationProvider
                        dateAdapter={DateFnsUtils}
                        adapterLocale={esLocale}
                      >
                        <DatePicker
                          value={field.value}
                          disabled={completeData}
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          label="Fecha de Nacimiento"
                          minDate={
                            new Date(
                              new Date().setFullYear(
                                new Date().getFullYear() - 100
                              )
                            )
                          }
                          maxDate={new Date()}
                          className={errors.dateOfBirth && "Mui-error"}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              variant="standard"
                              helperText={
                                errors.dateOfBirth && errors.dateOfBirth.message
                              }
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="birthPlace"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={data && countryASC}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="País de Nacimiento"
                              variant="standard"
                              error={!!errors.birthPlace}
                              helperText={
                                errors.birthPlace && errors.birthPlace.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="maritalStatus"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          if (newValue === null) {
                            newValue = "";
                          }
                          onChange(newValue);
                        }}
                        options={datos.maritalStatus}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Estado Civil"
                              variant="standard"
                              error={!!errors.maritalStatus}
                              helperText={
                                errors.maritalStatus &&
                                errors.maritalStatus.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="academic_level"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={
                          data && data.al_ta_academic_level
                            ? data.al_ta_academic_level
                            : []
                        }
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Nivel Académico"
                            variant="standard"
                            error={!!errors.academic_level}
                            helperText={
                              errors.academic_level &&
                              errors.academic_level.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="profession"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={data && data.al_ta_profession}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Profesión"
                            variant="standard"
                            error={!!errors.profession}
                            helperText={
                              errors.profession && errors.profession.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"email"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Correo Electrónico"
                        type="email"
                        variant="standard"
                        autoComplete="email"
                        value={value}
                        disabled
                        //disabled={completeData}
                        {...register("email")}
                        error={errors.email && Boolean(errors.email)}
                        helperText={errors.email && errors.email.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"phone"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Celular"
                        type="phone"
                        variant="standard"
                        value={value}
                        disabled={completeData}
                        autoComplete="phone"
                        {...register("phone")}
                        error={errors.phone && Boolean(errors.phone)}
                        helperText={errors.phone && errors.phone.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="since_when_is_contributor_altio"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={data && data.al_ta_relationship_duration}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="¿Desde cuándo es aportante en ALTIO SAFI?"
                            variant="standard"
                            error={!!errors.since_when_is_contributor_altio}
                            helperText={
                              errors.since_when_is_contributor_altio &&
                              errors.since_when_is_contributor_altio.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="nationality"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue: any) => {
                          if (newValue === null) {
                            newValue = "";
                          }
                          onChange(newValue);
                        }}
                        options={data && countryASC}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Nacionalidad"
                              variant="standard"
                              error={!!errors.nationality}
                              helperText={
                                errors.nationality && errors.nationality.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <FormLabel component="legend" disabled={completeData}>
                    ¿Doble Nacionalidad?
                  </FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="doubleNationality"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Switch
                          disabled={completeData}
                          value={value}
                          checked={value}
                          {...register("doubleNationality")}
                          onChange={(val: any) => {
                            return onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
                {watchDoubleNationality && (
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      control={control}
                      name="secondNationality"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue: any) => {
                            if (newValue === null) {
                              newValue = "";
                            }
                            onChange(newValue);
                          }}
                          options={data && countryASC}
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled={completeData}
                          renderInput={(params) => {
                            const inputProps = params.inputProps;
                            inputProps.autoComplete = "new-password";
                            return (
                              <TextField
                                {...params}
                                fullWidth
                                label="Indique 2da Nacionalidad"
                                variant="standard"
                                error={!!errors.secondNationality}
                                helperText={
                                  errors.secondNationality &&
                                  errors.secondNationality.message
                                }
                              />
                            );
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
                <Grid item md={4} sm={6} xs={12}>
                  <FormLabel component="legend" disabled={completeData}>
                    ¿Es Residente Dominicano?
                  </FormLabel>
                  <Controller
                    rules={{ required: true }}
                    control={control}
                    name="dominican_resident"
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Switch
                          disabled={completeData}
                          value={value}
                          checked={value}
                          {...register("dominican_resident")}
                          onChange={(val: any) => {
                            return onChange(val);
                          }}
                        />
                      );
                    }}
                  />
                </Grid>
              </Grid>
              {(watchMaritalStatus.description === "Casado" ||
                watchMaritalStatus.description === "Unión Libre") && (
                <>
                  <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
                    Datos del Cónyuge o Pareja en Unión Libre
                  </TitleForm>
                  <Grid container spacing={4}>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="maritalIDType"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            options={datos.id_type}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Tipo de Identificación"
                                variant="standard"
                                error={!!errors.maritalIDType}
                                helperText={
                                  errors.maritalIDType &&
                                  errors.maritalIDType.message
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"maritalNumberIdentity"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Documento de Identidad"
                            type="text"
                            variant="standard"
                            value={value}
                            disabled={completeData}
                            {...register("maritalNumberIdentity")}
                            error={
                              errors.maritalNumberIdentity &&
                              Boolean(errors.maritalNumberIdentity)
                            }
                            helperText={
                              errors.maritalNumberIdentity &&
                              errors.maritalNumberIdentity.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"maritalIDexpirationDate"}
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => (
                          <LocalizationProvider
                            dateAdapter={DateFnsUtils}
                            adapterLocale={esLocale}
                          >
                            <DatePicker
                              value={field.value}
                              disabled={completeData}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              label="Fecha de Expiración"
                              className={
                                errors.maritalIDexpirationDate && "Mui-error"
                              }
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 10
                                  )
                                )
                              }
                              maxDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 10
                                  )
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  helperText={
                                    errors.maritalIDexpirationDate &&
                                    errors.maritalIDexpirationDate.message
                                  }
                                  {...params}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"marital_expedition_place"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Lugar de Expedición"
                            type="text"
                            variant="standard"
                            autoComplete="marital_expedition_place"
                            value={value}
                            disabled={completeData}
                            {...register("marital_expedition_place")}
                            error={
                              errors.marital_expedition_place &&
                              Boolean(errors.marital_expedition_place)
                            }
                            helperText={
                              errors.marital_expedition_place &&
                              errors.marital_expedition_place.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"maritalName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nombres"
                            type="text"
                            variant="standard"
                            autoComplete="maritalName"
                            value={value}
                            disabled={completeData}
                            {...register("maritalName")}
                            error={
                              errors.maritalName && Boolean(errors.maritalName)
                            }
                            helperText={
                              errors.maritalName && errors.maritalName.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"maritalLastName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Apellidos"
                            type="text"
                            variant="standard"
                            autoComplete="maritalLastName"
                            value={value}
                            disabled={completeData}
                            {...register("maritalLastName")}
                            error={
                              errors.maritalLastName &&
                              Boolean(errors.maritalLastName)
                            }
                            helperText={
                              errors.maritalLastName &&
                              errors.maritalLastName.message
                            }
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
                Dirección Residencial
              </TitleForm>
              <Grid container spacing={4}>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    control={control}
                    name="country"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={data && countryASC}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="País"
                              variant="standard"
                              error={!!errors.country}
                              helperText={
                                errors.country && errors.country.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  />
                </Grid>
                {watch_country?.id === 63 && (
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="province"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            options={address.provinces}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.provinceCode === value.provinceCode
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Provincia"
                                  variant="standard"
                                  error={!!errors.province}
                                  helperText={
                                    !!errors.province && "Campo es requerido"
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="sector"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            options={sectorData?.length > 0 ? sectorData : []}
                            /*  options={address.sector.filter(
                          (m: any) =>
                            m.provinceCode === watch_province?.provinceCode
                        )} */
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData || sectorData?.length === 0}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Sector"
                                  variant="standard"
                                  error={!!errors.sector}
                                  helperText={
                                    !!errors.sector && "Campo es requerido"
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"street"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Calle"
                        type="street"
                        variant="standard"
                        autoComplete="street"
                        value={value}
                        disabled={completeData}
                        {...register("street")}
                        error={errors.street && Boolean(errors.street)}
                        helperText={errors.street && errors.street.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"houseApartNumber"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="N° Calle / Apto"
                        type="houseApartNumber"
                        variant="standard"
                        autoComplete="houseApartNumber"
                        value={value}
                        disabled={completeData}
                        {...register("houseApartNumber")}
                        error={
                          errors.houseApartNumber &&
                          Boolean(errors.houseApartNumber)
                        }
                        helperText={
                          errors.houseApartNumber &&
                          errors.houseApartNumber.message
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"building"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Edificio"
                        type="building"
                        variant="standard"
                        autoComplete="building"
                        value={value}
                        disabled={completeData}
                        {...register("building")}
                        error={errors.building && Boolean(errors.building)}
                        helperText={errors.building && errors.building.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <Controller
                    name={"telephoneResidence"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Teléfono de Residencia"
                        type="telephoneResidence"
                        variant="standard"
                        value={value}
                        disabled={completeData}
                        autoComplete="telephoneResidence"
                        {...register("telephoneResidence")}
                        error={
                          errors.telephoneResidence &&
                          Boolean(errors.telephoneResidence)
                        }
                        helperText={
                          errors.telephoneResidence &&
                          errors.telephoneResidence.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
                Persona Expuesta Políticamente
              </TitleForm>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Controller
                    name={"politicallyExposedPerson"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        className={completeData ? "disabled-select" : ""}
                      >
                        <InputLabelToForm
                          error={
                            errors.politicallyExposedPerson &&
                            Boolean(errors.politicallyExposedPerson)
                          }
                        >
                          ¿Es o ha sido una persona expuesta políticamente (PEP)
                          conforme la ley 155-17 contra el lavado de activos y
                          su reglamento?
                        </InputLabelToForm>
                        <Select
                          variant="standard"
                          value={value}
                          {...register("politicallyExposedPerson")}
                          error={
                            errors.politicallyExposedPerson &&
                            Boolean(errors.politicallyExposedPerson)
                          }
                        >
                          <MenuItem value="1">Sí</MenuItem>
                          <MenuItem value="2">No</MenuItem>
                        </Select>
                        {errors.politicallyExposedPerson && (
                          <FormHelperText>
                            {errors.politicallyExposedPerson.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                {watchPoliticallyExposedPerson === "1" && (
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"positionPoliticExposedPerson"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Cargo"
                            type="text"
                            variant="standard"
                            value={value}
                            disabled={completeData}
                            autoComplete="positionPoliticExposedPerson"
                            {...register("positionPoliticExposedPerson")}
                            error={
                              errors.positionPoliticExposedPerson &&
                              Boolean(errors.positionPoliticExposedPerson)
                            }
                            helperText={
                              errors.positionPoliticExposedPerson &&
                              errors.positionPoliticExposedPerson.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"appointmentDatePoliticExposedPerson"}
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => (
                          <LocalizationProvider
                            dateAdapter={DateFnsUtils}
                            adapterLocale={esLocale}
                          >
                            <DatePicker
                              value={field.value}
                              disabled={completeData}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              label="Fecha de Designación"
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 10
                                  )
                                )
                              }
                              maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                            {errors.appointmentDatePoliticExposedPerson && (
                              <FormHelperText>
                                {
                                  errors.appointmentDatePoliticExposedPerson
                                    .message
                                }
                              </FormHelperText>
                            )}
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"removal_date_politic_exposed_person"}
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => (
                          <LocalizationProvider
                            dateAdapter={DateFnsUtils}
                            adapterLocale={esLocale}
                          >
                            <DatePicker
                              value={field.value}
                              disabled={completeData}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              label="Fecha de Cesación"
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 10
                                  )
                                )
                              }
                              maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                            {errors.removal_date_politic_exposed_person && (
                              <FormHelperText>
                                {
                                  errors.removal_date_politic_exposed_person
                                    .message
                                }
                              </FormHelperText>
                            )}
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Controller
                    name={"partnerPoliticallyExposed"}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <FormControl
                        fullWidth
                        className={completeData ? "disabled-select" : ""}
                      >
                        <InputLabelToForm
                          error={
                            errors.partnerPoliticallyExposed &&
                            Boolean(errors.partnerPoliticallyExposed)
                          }
                        >
                          ¿Tiene algún parentesco o es asociado cercano de una
                          persona expuesta políticamente (PEP) ?
                        </InputLabelToForm>
                        <Select
                          variant="standard"
                          value={value}
                          {...register("partnerPoliticallyExposed")}
                          error={
                            errors.partnerPoliticallyExposed &&
                            Boolean(errors.partnerPoliticallyExposed)
                          }
                        >
                          <MenuItem value="1">Sí</MenuItem>
                          <MenuItem value="2">No</MenuItem>
                        </Select>
                        {errors.partnerPoliticallyExposed && (
                          <FormHelperText>
                            {errors.partnerPoliticallyExposed.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </Grid>
                {watchPartnerPoliticallyExposed === "1" && (
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"PEPName"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Nombre del PEP"
                            type="PEPName"
                            variant="standard"
                            autoComplete="PEPName"
                            value={value}
                            disabled={completeData}
                            {...register("PEPName")}
                            error={errors.PEPName && Boolean(errors.PEPName)}
                            helperText={
                              errors.PEPName && errors.PEPName.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name="affinity"
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue) => {
                              onChange(newValue);
                            }}
                            options={datos.degrees_of_consanguinity}
                            getOptionLabel={(option: any) => option || ""}
                            isOptionEqualToValue={(option: any, value: any) =>
                              option === value
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Afinidad"
                                  variant="standard"
                                  error={!!errors.affinity}
                                  helperText={
                                    errors.affinity && errors.affinity.message
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      />
                      {/* <Controller
                    control={control}
                    name="affinity"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={datos.degrees_of_consanguinity}
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={completeData}
                        renderInput={(params) => {
                          const inputProps = params.inputProps;
                          inputProps.autoComplete = "new-password";
                          return (
                            <TextField
                              {...params}
                              fullWidth
                              label="Afinidad"
                              variant="standard"
                              error={!!errors.affinity}
                              helperText={
                                errors.affinity && errors.affinity.message
                              }
                            />
                          );
                        }}
                      />
                    )}
                  /> */}
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"positionPEP"}
                        control={control}
                        render={({ field: { onChange, value } }) => (
                          <TextField
                            fullWidth
                            size="small"
                            label="Cargo"
                            type="text"
                            variant="standard"
                            autoComplete="positionPEP"
                            value={value}
                            disabled={completeData}
                            {...register("positionPEP")}
                            error={
                              errors.positionPEP && Boolean(errors.positionPEP)
                            }
                            helperText={
                              errors.positionPEP && errors.positionPEP.message
                            }
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"appointmentDate"}
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => (
                          <LocalizationProvider
                            dateAdapter={DateFnsUtils}
                            adapterLocale={esLocale}
                          >
                            <DatePicker
                              value={field.value}
                              disabled={completeData}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              label="Fecha de Designación"
                              className={errors.appointmentDate && "Mui-error"}
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 10
                                  )
                                )
                              }
                              maxDate={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                            {errors.appointmentDate && (
                              <FormHelperText>
                                {errors.appointmentDate.message}
                              </FormHelperText>
                            )}
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        name={"removalDate"}
                        control={control}
                        defaultValue={null}
                        render={({ field, ...props }) => (
                          <LocalizationProvider
                            dateAdapter={DateFnsUtils}
                            adapterLocale={esLocale}
                          >
                            <DatePicker
                              value={field.value}
                              disabled={completeData}
                              onChange={(date) => {
                                field.onChange(date);
                              }}
                              label="Fecha de Salida"
                              className={errors.removalDate && "Mui-error"}
                              minDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() - 10
                                  )
                                )
                              }
                              maxDate={
                                new Date(
                                  new Date().setFullYear(
                                    new Date().getFullYear() + 5
                                  )
                                )
                              }
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  variant="standard"
                                  {...params}
                                />
                              )}
                            />
                            {errors.removalDate && (
                              <FormHelperText>
                                {errors.removalDate.message}
                              </FormHelperText>
                            )}
                          </LocalizationProvider>
                        )}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
              <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
                *La ley contra el lavado de activos y el financiamiento del
                terrorismo (ley 155-17) califica como persona expuesta
                políticamente a: "cualquier individuo que desempeña o ha
                desempeñado, durante los últimos tres (3) años, altas funciones
                públicas, por elección o nombramientos ejecutivos, en un país
                extranjero o en territorio nacional, incluyendo altos
                funcionarios de organizaciones internacionales. Incluye, pero no
                se limita a, jefes de estado o de gobierno, funcionarios
                gubernamentales, judiciales o militares de alta jerarquía, altos
                ejecutivos de empresas estatales o funcionarios, así como
                aquellos que determine el comité nacional de lavado de activos
                previa consulta con el ministerio de la administración pública.
                Los cargos considerados PEP serán todos aquellos funcionarios
                obligados a presentar declaración jurada de bienes. Se asimilan
                todas aquellas personas que hayan desempeñado o desempeñen estas
                funciones o su equivalente para gobiernos extranjeros."
              </Typography>
              {loadingSendData && (
                <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
                  <CircularProgress
                    size={24}
                    sx={{ margin: "5px auto", color: "#6fc555" }}
                  />
                </Stack>
              )}
              <Stack
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mt: 1 }}
              >
                {completeData ? (
                  <ButtonGradient
                    type="button"
                    onClick={handleNext}
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Continuar
                  </ButtonGradient>
                ) : (
                  <ButtonGradient
                    type="submit"
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Continuar
                  </ButtonGradient>
                )}
              </Stack>
            </>
          )}
        </form>
      )}
    </>
  );
};

export default CustomerIdenfication;
