import { Stack, Link, Typography } from "@mui/material";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
} from "../iconsSvg/AltioSvgIcons";

const SocialNetworks = () => {
  return (
    <>
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={2}
        direction="row"
      >
        <Link
          href="https://www.facebook.com/altiosafird"
          target="_blank"
          underline="none"
          color="primary"
        >
          <FacebookIcon />
        </Link>
        <Link
          href="https://www.instagram.com/altiosafird/"
          target="_blank"
          underline="none"
          color="primary"
        >
          <InstagramIcon />
        </Link>

        <Link
          href="https://www.linkedin.com/company/gamcapital/mycompany/?viewAsMember=true"
          target="_blank"
          underline="none"
          color="primary"
        >
          <LinkedinIcon />
        </Link>
      </Stack>
      <Stack justifyContent="center" alignItems="center">
        <Link
          href="mailto:servicios@altiosafi.com"
          target="_blank"
          variant="body1"
          underline="none"
          color="primary"
        >
          servicios@altiosafi.com
        </Link>
        <Typography color="primary">© 2022 by ALTIO SAFI.</Typography>
      </Stack>
    </>
  );
};

export default SocialNetworks;
