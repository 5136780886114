import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FancyInputFile from "./FancyInputFile";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useApp } from "../hooks/useApp";
import useProfile from "../hooks/useProfile";
import {
  Grid,
  Typography,
  IconButton,
  useTheme,
  Button,
  Stack,
  Divider,
  InputLabel,
  Dialog,
} from "@mui/material";
import { useForm } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { uploadBulkDocuments, deleteDocuments } from "../services/Services";

import DeleteModal from "./utils/DeleteModal";
import FileViewModal from "./utils/FileViewModal";

interface DocumentStageType {
  categoryType: string | null;
  documentByClient: null;
  handleFetchDocumentsByClient: any;
  clientId: any;
  title?: string;
  require?: boolean;
  notification: boolean;
  promoterId?: string;
}

const DocumentStage = ({
  categoryType,
  documentByClient,
  handleFetchDocumentsByClient,
  clientId,
  title,
  require,
  notification,
  promoterId,
}: DocumentStageType) => {
  const theme = useTheme();
  const {
    authInfo,
    setLoading,
    setErrorMsg,
    modalData,
    setModalData,
    setSuccessMsg,
    errorMsg,
  } = useApp();
  const { dataForm } = useProfile();
  const { handleSubmit } = useForm({
    mode: "onChange",
  });

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
    console.log("findData: ", findData);
  }

  const [customLoader, setCustomLoader] = useState(false);
  const [documentByClientByRole, setDocumentByClientByRole] = useState<
    any | null
  >(null);
  let newF = { newField: false };

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchDocuments();
    };
    dataInit();
  }, [documentByClient]);

  const handleFetchDocuments = async () => {
    try {
      setDocumentByClientByRole(
        documentByClient &&
          documentByClient !== null &&
          //@ts-ignore
          documentByClient.length > 0 &&
          //@ts-ignore
          documentByClient.map((d: any) => ({ ...d, ...newF }))
      );
    } catch (error: any) {
      setCustomLoader(false);
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleAdd = async (data: any) => {
    console.log("sube archivo", data);
    let fileName = data.file[0].name.split(/\.(?=[^.]*$)/);
    fileName[0] =
      fileName[0].replace(/[^A-Z0-9.]+/gi, "_") + Math.round(Date.now() / 1000);
    const formData = new FormData();
    formData.append("file", data.file[0], fileName.join("."));
    for (const key in data) {
      if (key !== "file") {
        formData.append(key, data[key]);
      }
    }

    try {
      let createData = await uploadBulkDocuments(formData);
      if (!createData) {
        setErrorMsg && setErrorMsg("Error en proceso de guardar documento");
        setLoading && setLoading(false);
        return;
      }

      await handleFetchDocumentsByClient(clientId, categoryType);
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error);
      await handleFetchDocumentsByClient(clientId, categoryType);
      console.log(error);
    }
  };

  const handleDelete = async (idDoc: string) => {
    handleCancelModal();
    setLoading && setLoading(true);
    try {
      let deleteData = await deleteDocuments(idDoc, authInfo.username);
      if (!deleteData) {
        setErrorMsg && setErrorMsg("Error en proceso de eliminar documento");
        setLoading && setLoading(false);
        return;
      }
      setLoading && setLoading(false);
      setSuccessMsg && setSuccessMsg("Registro eliminado con éxito");

      await handleFetchDocumentsByClient(clientId, categoryType);
    } catch (error: any) {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: "",
          modalObject: null,
        });
    }
  }, [modalData, errorMsg, setModalData]);

  const handleOpenModal = async (event: any) => {
    event.preventDefault();

    const modalAction = event.currentTarget.getAttribute("data-name");
    let object = null;
    const id = event.currentTarget.getAttribute("data-id");
    const nameD = event.currentTarget.getAttribute("data-name-document");
    const extensionD = event.currentTarget.getAttribute("data-extension");

    if (modalAction === "delete") {
      object = { document: nameD, id: id };
    }

    if (modalAction === "view") {
      object = { id: id, nameD: nameD, ext: extensionD };
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object,
      });
  };

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: "",
        modalObject: null,
      });
  };

  const addField = async (e: any) => {
    const nameDoc = e.currentTarget.getAttribute("data-name");
    const newDocumentByClient = documentByClientByRole.map((d: any) => {
      if (d.name !== nameDoc) return d;
      return { ...d, newField: true };
    });
    setDocumentByClientByRole(newDocumentByClient);
    /* const nameDoc = e.currentTarget.getAttribute("data-name");
    const newDocumentByClient = documentByClient.map((d: any) => {
      if (d.name !== nameDoc) return d;
      return { ...d, newField: true };
    });
    setDocumentByClient(newDocumentByClient); */
  };

  const onSubmit = async (data: any, e: any) => {
    e.preventDefault();
    console.log("entra");
    const typeAction = e.target.name;
    switch (modalData?.modalType) {
      case "create":
        await handleAdd(data);
        break;
      case "delete":
        await handleDelete(
          //@ts-ignore
          modalData?.modalObject?.id, //@ts-ignore
          modalData?.modalObject?.document
        );

        break;
      default:
        break;
    }
  };

  return (
    <>
      <Typography
        variant="h5"
        color="greyDue.dark"
        sx={{ fontWeight: 400, fontSize: "1.25rem" }}
      >
        <ChevronRightIcon
          sx={{
            marginRight: "0px",
            color: "secondary.main",
            transform: "translateY(3px)",
            fontSize: "1.25rem",
          }}
        />{" "}
        {title ? title : "Categoría de Documentos"}
      </Typography>
      <Divider />
      {customLoader ? (
        <Typography>Cargando...</Typography>
      ) : (
        <Grid container spacing={4} maxWidth="lg" sx={{ pt: 2, pb: 8 }}>
          {documentByClientByRole &&
            documentByClientByRole.map((dr: any, i: any) => (
              <Grid
                item
                xs={12}
                key={i}
                sx={{
                  "&:after": {
                    content: '""',
                    width: "calc(100% - 165px)",
                    height: "1px",
                    display: "block",
                    background: "rgb(33, 37, 41)",
                    marginTop: "15px",
                    opacity: "0.25",
                  },
                }}
              >
                <InputLabel sx={{ color: theme.palette.primary.dark }}>
                  {dr.name}{" "}
                  {dr.id !== 2
                    ? (require || dr.required) && (
                        <Typography
                          variant="button"
                          sx={{ color: theme.palette.success.main }}
                        >
                          *
                        </Typography>
                      )
                    : findData !== undefined &&
                      (findData.employment_status === "2" ||
                        findData.employment_status === "3" ||
                        findData.employment_status === "4") && (
                        <Typography
                          variant="button"
                          sx={{ color: theme.palette.success.main }}
                        ></Typography>
                      )}
                </InputLabel>
                {dr?.uploaded?.length > 0 ? (
                  <>
                    {dr.uploaded.map((dup: any, i: any) => (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        clientId={clientId}
                        idDocumentByClient={dup.id}
                        documentId={dup.document.id}
                        nameDocument={dup.originalName}
                        systemNameDocument={
                          dup.systemName ? dup.systemName : ""
                        }
                        extension={dup.extension}
                        handleUpload={handleAdd}
                        handleDelete={handleDelete}
                        handleOpenModal={handleOpenModal}
                        //require={require ? require : dr.required}
                        require={
                          dr.id !== 2
                            ? require
                              ? require
                              : dr.required
                            : findData !== undefined &&
                              (findData.employment_status === "2" ||
                                findData.employment_status === "3" ||
                                findData.employment_status === "4") &&
                              false
                        }
                        maxSize={dr.maxsizes}
                        notification={notification}
                        promoterId={promoterId}
                      />
                    ))}
                    {dr.newField && (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        clientId={clientId}
                        idDocumentByClient={""}
                        documentId={dr.id}
                        handleUpload={handleAdd}
                        require={require ? require : dr.required}
                        extensionList={dr.extension}
                        maxSize={dr.maxsizes}
                        notification={notification}
                        promoterId={promoterId}
                      />
                    )}
                    <Stack alignItems="flex-end">
                      <IconButton
                        sx={{
                          color: "#198754",
                        }}
                        aria-label="add"
                        component="label"
                        data-name={dr.name}
                        onClick={addField}
                      >
                        <AddCircleIcon sx={{ fontSize: "1.563rem" }} />
                      </IconButton>
                    </Stack>
                  </>
                ) : (
                  <>
                    <FancyInputFile
                      key={i}
                      handleOptions={undefined}
                      label=" "
                      clientId={clientId}
                      idDocumentByClient={""}
                      documentId={dr.id}
                      handleUpload={handleAdd}
                      require={require ? require : dr.required}
                      extensionList={dr.extension}
                      maxSize={dr.maxsizes}
                      notification={notification}
                      promoterId={promoterId}
                    />
                  </>
                )}
              </Grid>
            ))}
          {modalData?.modalType === "delete" && (
            <Dialog
              open={modalData.modalOpen}
              onClose={handleCancelModal}
              fullWidth
            >
              <DeleteModal
                //@ts-ignore
                textChildren={modalData?.modalObject.document}
                actionButton={
                  <>
                    {" "}
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 2,
                        mr: 2,
                      }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Aceptar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      sx={{
                        mt: 2,
                      }}
                      onClick={handleCancelModal}
                      autoFocus
                    >
                      Cancelar
                    </Button>{" "}
                  </>
                }
              />
            </Dialog>
          )}
          {modalData?.modalType === "view" && (
            <Dialog
              open={modalData.modalOpen}
              onClose={handleCancelModal}
              maxWidth="xl"
              fullWidth
            >
              <FileViewModal
                //@ts-ignore
                document={modalData?.modalObject}
                cancelModal={handleCancelModal}
              />
            </Dialog>
          )}
        </Grid>
      )}
    </>
  );
};

export default DocumentStage;
