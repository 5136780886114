import { ThemeContext } from '@emotion/react';
import { createTheme, experimental_sx as sx  } from '@mui/material/styles'

let theme = createTheme()
theme = createTheme({
  palette: {
    primary: {
      main: '#144752',
    light: '#6fc555',
      dark: '#103a43',
      contrastText: '#ffffff'
    },
    secondary: {
      main: '#39b34a',
    },
    success: {
      main: '#d32f2f',
    },
    text: {
      primary: '#666666',
    },

  },
  typography: {
    fontFamily: [
      'Mulish',
    ].join(','),
    h5: {
      fontSize: '1.7rem',
      fontWeight: 800,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      }

    },
    button: {
      textTransform: 'none',
      fontSize: '1rem',
    },
    body1: {
      color: '#666666'
    },
    body2: {
      color: '#666666'
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
              borderBottom: "2px solid #CACACA",
          },
          '& label': {
            color: '#666666',
          }
        },
      },
    },
    MuiInput:{
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
        underline: {
          '&:before': {
            borderBottom: "2px solid #CACACA",
          },
        }
      }
    },
    MuiInputBase:{
      styleOverrides: {
        root: {
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
        input: {
          padding:' 4px 0 2px',
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
        },
      }
    },
    MuiInputLabel: {

    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '0px',
          color: '#d32f2f'
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding:' 4px 0 2px',
          '& .MuiInput-underline:before': {
            borderBottom: "2px solid #CACACA",
          },
          '&.Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          }
        }
      }
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit!important',
            opacity: '0.5'
          }
        }
      }
    },
  },
});

export default theme

