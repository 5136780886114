import { FC, useState } from "react";
import { Link } from "react-router-dom";
import {
  Stack,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@mui/material";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationRecoverPassword } from "../validation";
import { resendNotificationActivateUser } from "../../../services/Services";

interface passType {
  email: string;
}

const ResendNotificationActivateUser: FC = () => {
  const formOptions = { resolver: yupResolver(validationRecoverPassword) };

  const { register, handleSubmit, formState, control } =
    useForm<passType>(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [successMessage, setSuccessMessage] = useState<any>("");

  const handleResendActivateUser = async (email: string) => {
    setMessageError("");
    setSuccessMessage("");
    try {
      setLoading(true);
      const userData = {
        username: email,
      };
      const response = await resendNotificationActivateUser(email);
      if (response) {
        setSuccessMessage(response);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setMessageError(error.message);
      setSuccessMessage("");
    }
  };

  const onSubmit = async (data: any) => {
    await handleResendActivateUser(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm
        sx={{
          fontSize: "1.6rem",
          mb: 2,
        }}
      >
        Reenviar Correo Activar Usuario
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name={"email"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Email"
                type="email"
                variant="standard"
                autoComplete="email"
                {...register("email")}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <Stack pt={3} pb={1} justifyContent="center" alignItems="center">
        {loading && (
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        )}
        {successMessage !== "" && (
          <Typography
            color="primary"
            sx={{ mt: 1, mb: 2, textAlign: "center" }}
          >
            {successMessage}
          </Typography>
        )}
        {messageError !== "" && (
          <Typography color="error" sx={{ mt: 1, mb: 2, textAlign: "center" }}>
            {messageError}
          </Typography>
        )}
        <ButtonGradient type="submit" fullWidth>
          Reenviar correo
        </ButtonGradient>

        <Typography
          align="center"
          sx={{ mt: 2 }}
          variant="body2"
          component={Link}
          to="/"
        >
          Iniciar sesión
        </Typography>
      </Stack>
    </form>
  );
};

export default ResendNotificationActivateUser;
