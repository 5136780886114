import { CONSTANT } from "./Constants";

export const basesURL = {
  app_base_url: window.REACT_APP_BASE_URL,
  api_context: window.REACT_APP_API_CONTEXT,
  ak_host: window.AK_HOST,
  ak_subdomain: window.AK_SUBDOMAIN,
};

export const URL = {
  AK_AUTENTIKAR:
    basesURL.ak_host + "/s/" + basesURL.ak_subdomain + "/oauth/authorize",
};
