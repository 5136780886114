import { FC, useEffect, useState, useCallback } from "react";
import usePhysycalForm from "../../hooks/usePhysycalForm";
import useProfile from "../../hooks/useProfile";
import { useApp } from "../../hooks/useApp";
import { useDropzone } from "react-dropzone";
import {
  Stack,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Divider,
  CircularProgress,
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  styled,
  alpha,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  useTheme,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FinalCalInvestorProfile } from "../../types/types";
import { formSchemaOptions } from "../../utils";
import { data as datos } from "../../data/data";
import axios from "axios";
import { apis } from "../../apis/apis";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import InputLabelToForm from "../../components/InputLabelToForm";
import {
  updateProfile,
  clientNotification,
  admintNotification,
  getAccountRegister,
  getAuthorizedSignatures,
  getQuestionnaireProfile,
  getKYC_fisico,
  getProfileDoc,
  getListcategoryDocuments,
  uploadBulkDocuments,
  deleteDocuments,
  getDocumentBlank,
  getRequestDocumentClient,
  requestSendNotification,
} from "../../services/Services";
import DocumentStage from "../../components/DocumentStage";
import MessageManager from "../../components/utils/MessageManager";

const ListText = styled(ListItemText)(() => ({
  marginTop: 0,
  marginBottom: 0,
  "& span": {
    fontSize: "1rem",
  },
}));

const DropZone = styled("div")(({ theme }) => ({
  flex: "1",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: alpha(theme.palette.secondary.dark, 0.3),
  borderStyle: "dashed",
  backgroundColor: "#FFFFFF",
  color: theme.palette.text.primary,
  outline: "none",
  transition: "border .24s ease-in-out",
}));

interface Category {
  id: number;
  name: string;
  required: boolean;
  extension: any;
}

interface FileCategory {
  idDocumento: number;
  filename: string;
}

interface UploadResult {
  categories: FileCategory[];
  files: FormData;
}

const InvestorTypeResults: FC = () => {
  const theme = useTheme();
  const {
    dataForm,
    investmentProfile,
    getInvestmentProfile,
    loadDataServer,
    steps_completed,
    data,
    address,
  } = useProfile();
  const { authInfo, setErrorMsg, setSuccessMsg } = useApp();

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = [];
  }

  const validation = Yup.object().shape({
    agree_cat_investor_profile: Yup.string().required("Campo es requerido"),
    type_investor_considered: Yup.object().when("agree_cat_investor_profile", {
      is: "No",
      then: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      otherwise: Yup.object().nullable(),
    }),
    what_cat_investor_considered: Yup.object().when(
      "type_investor_considered",
      {
        is: (type_investor_considered: any) => {
          if (type_investor_considered?.description == "No Profesional") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
        otherwise: Yup.object().nullable(),
      }
    ),
    investmentPromoter: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  });

  const defaultValues: FinalCalInvestorProfile = {
    agree_cat_investor_profile: findData.agree_cat_investor_profile
      ? findData.agree_cat_investor_profile
      : "",
    type_investor_considered: findData.type_investor_considered
      ? data &&
        data.al_ta_type_investor_considered.find(
          (f: any) => f.id === Number(findData.type_investor_considered)
        )
      : [],
    what_cat_investor_considered: findData.what_cat_investor_considered
      ? data &&
        data.al_ta_what_cat_investor_considered.find(
          (f: any) => f.id === Number(findData.what_cat_investor_considered)
        )
      : [],
    investmentPromoter: findData.investmentPromoter
      ? data &&
        data.al_ta_investment_promoter.find(
          (f: any) => f.id === Number(findData.investmentPromoter)
        )
      : [],
  };

  const { handleBack, step4Answered } = usePhysycalForm();

  const [loading, setLoading] = useState(false);
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false);
  const [loadedPDFPerfil, setLoadedPDFPerfil] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [uploadResult, setUploadResult] = useState<UploadResult | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [documentByClient, setDocumentByClient] = useState<any | null>(null);
  const [errorSendNotification, setErrorSendNotification] = useState("");
  const [sendNotification, setSendNotification] = useState("");
  const uploadButton = document.getElementById(
    "upload-button"
  ) as HTMLInputElement;

  const {
    register,
    handleSubmit,
    reset,
    resetField,
    formState,
    control,
    watch,
    trigger,
  } = useForm<FinalCalInvestorProfile>({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(validation),
  });
  const { errors, isValidating } = formState;
  const watchCatProfile: any = watch("agree_cat_investor_profile");
  const watchtype_investor_considered: any = watch("type_investor_considered");
  const watch_investmentPromoter: any = watch("investmentPromoter");
  const id = sessionStorage.getItem("user_id");
  const miarray: any = [];
  const [documentByClientInitial, setDocumentByClientInitial] =
    useState<any>(null);
  const [exemptAgentDocument, setExemptAgentDocument] = useState<any>(null);
  const [documentDeclareIncome, setDocumentDeclareIncome] = useState<any>(null);

  useEffect(() => {
    reset(defaultValues);
    const loadData = async () => {
      await handleLoadDocuments();
      return;
    };
    loadData();
  }, [dataForm]);

  useEffect(() => {
    if (watchtype_investor_considered?.description !== "No Profesional") {
      resetField("what_cat_investor_considered");
    }
  }, [watchtype_investor_considered]);

  useEffect(() => {
    if (watchCatProfile === "Sí") {
      resetField("type_investor_considered");
      resetField("what_cat_investor_considered");
    }
  }, [watchCatProfile]);

  const handleDownloadKYC = async () => {
    setLoadedPDFKYC(true);
    if (id !== null) {
      await getKYC_fisico(id);
      await getAccountRegister(id);
      await getAuthorizedSignatures(id);
      await getQuestionnaireProfile(id);
      await getProfileDoc(id);
    }

    setLoadedPDFKYC(false);
  };

  /* const handleDownloadPerfil = async () => {
    setLoadedPDFPerfil(true);
    await axios
      .get(`${apis.investmentProfile.perfil}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Evaluacion-del-perfil-persona-fisica.pdf"
        );
        document.body.appendChild(link);
        link.click();
      });
    setLoadedPDFPerfil(false);
  }; */
  let userData;
  const handleNotification = async () => {
    userData = {
      firstname: findData.name + " " + findData.lastName,
      formtype: "Físico",
      username: findData.email,
    };

    await clientNotification(userData, "completeForm");
    await admintNotification(userData);
  };

  const convertTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date: Date) => {
    return [
      convertTo2Digits(date.getDate()),
      convertTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };

  useEffect(() => {
    const loadDocuments = async () => {
      await handleLoadDocumentsInitial();
    };
    loadDocuments();
  }, [id]);

  const handleFetchDocumentsByClient = async (
    id: string,
    categoryType?: string | null
  ) => {
    try {
      let documentsList = await getRequestDocumentClient(id, categoryType);
      return documentsList;
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message);
    }
  };

  const handleLoadDocumentsInitial = async () => {
    if (id) {
      const initialDocument = await handleFetchDocumentsByClient(
        id,
        "Documentos iniciales"
      );
      if (initialDocument) {
        setDocumentByClientInitial(initialDocument);
      }
      //Categoría Agente exento de retención
      const loadExemptAgentDocument = await handleFetchDocumentsByClient(
        id,
        "Agente exento de retención"
      );
      if (loadExemptAgentDocument) {
        setExemptAgentDocument(loadExemptAgentDocument);
      }
      //Categoría Declara renta
      const declareIncome = await handleFetchDocumentsByClient(
        id,
        "Declara renta"
      );
      if (declareIncome) {
        setDocumentDeclareIncome(declareIncome);
      }
    }
  };

  const handleLoadDocuments = async () => {
    try {
      const load = await getListcategoryDocuments();
      const dataDocuments = async () => {
        if (id) {
          const loadDocuments: any = await handleFetchDocumentsByClient(
            id,
            null
          );
          let documents = [...loadDocuments];

          let documentsFilter: any[] = [];

          const unique = documents.filter((item: any) => {
            const isDuplicate = documentsFilter.includes(item.id);

            if (!isDuplicate) {
              documentsFilter.push(item.id);
              return true;
            }
          });
          setDocumentByClient(unique);
        }
      };
      dataDocuments();
    } catch (error) {}
  };

  const handleCloseModalFiles = () => {
    setModalOpen(false);
    setUploadResult(null);
    setFiles([]);
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFileCategories: FileCategory[] = [];
      const newFiles: File[] = [];

      acceptedFiles.forEach((file) => {
        const matchingCategory = documentByClient?.find(
          (category: any) =>
            file.name
              .toLowerCase()
              .replace(/\s_|_|#|-|@|<>/g, "")
              .indexOf(category.name.toLowerCase()) !== -1
        );

        if (matchingCategory) {
          const fileExtension = file.name.split(".").pop()?.toLowerCase();
          if (
            fileExtension &&
            JSON.parse(matchingCategory.extension).includes(`.${fileExtension}`)
          ) {
            const fileAlreadyAdded = uploadResult?.files.get(file.name);
            if (!fileAlreadyAdded) {
              newFileCategories.push({
                idDocumento: matchingCategory.id,
                filename: file.name,
              });
              newFiles.push(file);
            }
          }
        } else {
          const fileAlreadyAdded = uploadResult?.files.get(file.name);
          if (!fileAlreadyAdded) {
            newFileCategories.push({
              idDocumento: -1, // Use -1 to indicate "Other" category
              filename: file.name,
            });
            newFiles.push(file);
            if (uploadButton) {
              uploadButton.disabled = true;
            }
          }
        }
      });

      if (newFiles.length > 0) {
        setUploadResult((prevState: UploadResult | null) => ({
          ...prevState!,
          files: (() => {
            const formData = new FormData();
            newFiles.forEach((file) => {
              formData.append("files", file);
            });
            return formData;
          })(),
          categories: [...(prevState?.categories ?? []), ...newFileCategories],
        }));
        setFiles(acceptedFiles);
        setModalOpen(true);
        //setSelectedFile(newFiles[0]);
      }
    },
    [uploadButton, uploadResult]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const categoryId = event.target.value;
    if (uploadResult) {
      const updatedCategories = [...uploadResult.categories];
      const categoryIndex = updatedCategories.findIndex(
        (category) => category.filename === files[index].name
      );
      if (categoryIndex !== -1) {
        updatedCategories[categoryIndex] = {
          ...updatedCategories[categoryIndex],
          idDocumento: Number(categoryId),
        };
      } else {
        updatedCategories.push({
          idDocumento: Number(categoryId),
          filename: files[index].name,
        });
      }
      setUploadResult({
        ...uploadResult,
        categories: updatedCategories,
      });
    }
  };

  const handleUpload = async () => {
    setLoading && setLoading(true);
    if (uploadResult) {
      console.log(uploadResult);
      const formData = new FormData();
      // Agregar todos los archivos al array uploadResult.files
      const filesArray: any = [];
      uploadResult.files.forEach((file: any) => {
        filesArray.push(file);
      });
      // Convertir el array de archivos en un objeto Blob
      const filesBlob = new Blob(filesArray);

      uploadResult.categories.forEach((category, index) => {
        formData.append(`categories[${index}].filename`, category.filename);
        formData.append(
          `categories[${index}].documentId`,
          `${category.idDocumento}`
        );
        formData.append(`categories[${index}].promoterId`, category.filename);
      });
      id && formData.append("idClient", id);
      formData.append("user", authInfo?.username);

      uploadResult.files.forEach((file: any) => {
        formData.append("files", file);
      });

      try {
        let request: any = await uploadBulkDocuments(formData);
        if (request?.error) {
          setErrorMsg && setErrorMsg(request.message);
          setModalOpen(false);
          await handleLoadDocuments();
          setLoading && setLoading(false);
          return;
        }
        setLoading && setLoading(false);
        setModalOpen(false);
        setUploadResult(null);
        await handleLoadDocuments();
        setSuccessMsg && setSuccessMsg(request.message);
      } catch (error: any) {
        setErrorMsg && setErrorMsg(error.message);
        setLoading && setLoading(false);
      }
    } else {
      setLoading && setLoading(false);
      setErrorMsg && setErrorMsg("Debe seleccionar una categoría");
    }
  };

  const handleFileDelete = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    if (uploadResult) {
      const updatedCategories = uploadResult.categories.filter(
        (category) => category.filename !== files[index].name
      );

      const updatedFiles = new FormData();
      updatedCategories.forEach((category) => {
        const file = newFiles.find((f) => f.name === category.filename);
        if (file) {
          updatedFiles.append("files", file);
        }
      });
      setUploadResult({
        ...uploadResult,
        files: updatedFiles,
        categories: updatedCategories,
      });
    }
  };

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] = value !== null && value.id ? value.id : `"${value}"`;
      }
    }
    data.all_steps_complete = "true";

    if (findData.all_steps_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
          form_date: formatDate(new Date()),
        },
        formType: "F",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Confirmación de Perfil",
      };
      localStorage.setItem("stepForm", "Confirmación de Perfil");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        await handleNotification();
        setLoadingSendData(false);
        setMessage2("Información guardada");
        loadDataServer && loadDataServer(localStorage.getItem("user_id"));
        setTimeout(() => {
          setMessage2("");
        }, 600);
      } else {
        setLoadingSendData(false);
        setMessage("Ha ocurrido un error");
      }
    }
  };

  const handleSendNotification = async () => {
    try {
      setLoading(true);
      setSendNotification("");
      setErrorSendNotification("");
      if (id) {
        const send = await requestSendNotification(
          id,
          findData.investmentPromoter
        );
        if (send) {
          setLoading(false);
          setSendNotification("Notificación envíada al promotor");
        }
      }
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      setErrorSendNotification(error.message);
    }
  };

  return (
    <div>
      <Grid
        container
        rowSpacing={{ xs: 2, sm: 4 }}
        columnSpacing={{ xs: 2, sm: 4 }}
        sx={{
          maxWidth: "950px",
          mx: {
            md: "auto!important",
          },
          pr: {
            md: "32px",
          },
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <TitleForm sx={{ mt: 4, mb: 4 }}> Resultado Evaluación</TitleForm>
          <Typography variant="body1" align="left" sx={{ mb: 3 }}>
            Según la información suministrada por el cliente acerca de su nivel
            profesional, fuente de fondos, conocimiento de productos
            financieros, apetito de riesgo y rentabilidad esperada, la
            recomendación según su perfil de riesgo se ajusta a:
          </Typography>
          {loading ? (
            <Stack spacing={2} direction="row">
              <CircularProgress
                size={24}
                sx={{ margin: "5px auto", color: "#6fc555" }}
              />
            </Stack>
          ) : (
            <>
              <Typography align="center" variant="h6" color="primary">
                {
                  //@ts-ignore
                  investmentProfile?.result?.INVESTOR_TYPE
                }
              </Typography>

              {
                //@ts-ignore
                investmentProfile?.result?.INVESTOR_CATEGORY && (
                  <>
                    <Divider
                      sx={{
                        pt: 3,
                        mb: 3,
                        borderColor: "secondary.main",
                        width: "50%",
                        maxWidth: "450px",
                        ml: "auto",
                        mr: "auto",
                      }}
                    />

                    <Typography
                      align="center"
                      variant="h5"
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        backgroundColor: "primary.main",
                        color: "#fff",
                        pb: "4px",
                        margin: "0 auto",
                        borderRadius: "4px",
                        "&:hover": {
                          backgroundColor: "primary.main",
                          opacity: [0.9, 0.8, 0.9],
                        },
                      }}
                      color="light.contrastText"
                    >
                      {
                        //@ts-ignore
                        investmentProfile?.result?.INVESTOR_CATEGORY
                      }
                    </Typography>
                  </>
                )
              }
              <Divider
                sx={{
                  pt: 3,
                  mb: 0,
                  borderColor: "secondary.main",
                  width: "50%",
                  maxWidth: "450px",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TitleForm sx={{ mt: 4, mb: 4 }}>Confirmación de Perfil</TitleForm>
            <Grid container spacing={4} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Controller
                  name={"agree_cat_investor_profile"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      className={
                        findData.all_steps_complete ? "disabled-select" : ""
                      }
                    >
                      <InputLabelToForm
                        error={
                          errors.agree_cat_investor_profile &&
                          Boolean(errors.agree_cat_investor_profile)
                        }
                      >
                        ¿Cliente está de acuerdo con la categorización de su
                        perfil de inversionista?
                      </InputLabelToForm>
                      <Select
                        variant="standard"
                        value={value}
                        autoFocus
                        {...register("agree_cat_investor_profile")}
                        error={
                          errors.agree_cat_investor_profile &&
                          Boolean(errors.agree_cat_investor_profile)
                        }
                      >
                        <MenuItem value="Sí">Sí</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      {errors.agree_cat_investor_profile && (
                        <FormHelperText>
                          {errors.agree_cat_investor_profile.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              {watchCatProfile === "No" && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="type_investor_considered"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          options={
                            data && data.al_ta_type_investor_considered
                              ? data.al_ta_type_investor_considered
                              : []
                          }
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled={findData.all_steps_complete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                              variant="standard"
                              error={!!errors.type_investor_considered}
                              helperText={
                                errors.type_investor_considered &&
                                errors.type_investor_considered.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {watchtype_investor_considered?.id === 2 && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="what_cat_investor_considered"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={
                          data && data.al_ta_what_cat_investor_considered
                            ? data.al_ta_what_cat_investor_considered
                            : []
                        }
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={findData.all_steps_complete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={`Si el cliente se considera "Inversionista no Profesional", ¿En que categoria de Inversionista se considera?`}
                            variant="standard"
                            error={!!errors.what_cat_investor_considered}
                            helperText={
                              errors.what_cat_investor_considered &&
                              errors.what_cat_investor_considered.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Grid
              container
              spacing={4}
              sx={{ mb: 4, pt: 4, alignItems: "center" }}
            >
              <Grid item sm={6} xs={12}>
                <Button
                  variant="outlined"
                  href="https://altiosafi.com/about#ourTeam"
                  target="_blank"
                >
                  Conoce aquí nuestros promotores de inversión
                </Button>
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name="investmentPromoter"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data?.al_ta_investment_promoter}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={findData.all_steps_complete}
                      // disabled={!step4Answered}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Selecciona tu promotor de inversión"
                          variant="standard"
                          error={!!errors.investmentPromoter}
                          helperText={
                            errors.investmentPromoter &&
                            errors.investmentPromoter.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Se recomienda que tu portafolio contenga deuda o bonos
              gubernamentales, también es posible incluir cuotas de fondos
              mutuos o fondos de inversión estructurados en base a instrumentos
              respaldados por el Gobierno Central. <br />
              Estos instrumentos financieros deben estar contratados a plazos
              cortos de entre 30 días y un año. La principal característica de
              las inversiones a realizar es buscar un rendimiento que compense
              la inflación y que posea un nivel de liquidez aceptable para
              disponer de sus recursos en caso de un imprevisto. De igual
              manera, este tipo de inversionistas pueden utilizar productos que
              mitiguen el riesgo de mercado implícito en instrumentos de Renta
              Fija, como por ejemplo operaciones con acuerdos de recompra con
              niveles de precio previamente establecidos. <br />
              Se recomienda que los portafolios para inversionistas
              conservadores deben tener cero renta variable (acciones) y cero
              commodities, se debe buscar que los clientes que no toleran
              riesgos tengan la seguridad de que sus recursos están lo más
              protegidos que se pueda contra imprevistos del mercado o de los
              emisores".
            </Typography>
            <Stack
              justifyContent="flex-end"
              alignItems="flex-end"
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 6 }}
            >
              {steps_completed && (
                <>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Regresar
                  </Button>
                  {!findData.all_steps_complete && (
                    <ButtonGradient
                      type="submit"
                      sx={{ width: { xs: "100%", sm: "auto" } }}
                    >
                      Guardar
                    </ButtonGradient>
                  )}
                </>
              )}
            </Stack>
            {findData.all_steps_complete && id && (
              <>
                <Divider
                  sx={{
                    pt: 3,
                    mb: 0,
                    borderColor: "secondary.main",
                    width: "50%",
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <TitleForm sx={{ mt: 4, mb: 4, fontWeight: 500 }}>
                  {" "}
                  ¡Primer paso completado!
                </TitleForm>
                <Divider
                  sx={{
                    pt: 1,
                    mb: 3,
                    borderColor: "secondary.main",
                    width: "100%",
                    ml: "auto",
                    mr: "auto",
                  }}
                />
                <Typography
                  variant="h6"
                  color="secondary.main"
                  sx={{ fontWeight: 500, mb: 3 }}
                >
                  Próximos pasos:
                </Typography>

                <Grid container spacing={6} maxWidth="lg" sx={{ mb: 5 }}>
                  <Grid item xs={12}>
                    {id && (
                      <DocumentStage
                        categoryType={"Documentos Iniciales"}
                        documentByClient={documentByClientInitial}
                        handleFetchDocumentsByClient={
                          handleLoadDocumentsInitial
                        }
                        clientId={id}
                        title="Documentos iniciales"
                        notification={false}
                        promoterId={findData.investmentPromoter}
                      />
                    )}
                    {id && findData.axempt_agent_withholding === "Sí" && (
                      <DocumentStage
                        categoryType={"Agente exento de retención"}
                        documentByClient={exemptAgentDocument}
                        handleFetchDocumentsByClient={
                          handleLoadDocumentsInitial
                        }
                        clientId={id}
                        title="Documentos de agente exento de retención"
                        notification={true}
                        promoterId={findData.investmentPromoter}
                        require={true}
                      />
                    )}
                    {id && findData.declare_income === "Sí" && (
                      <DocumentStage
                        categoryType={"Declara renta"}
                        documentByClient={documentDeclareIncome}
                        handleFetchDocumentsByClient={
                          handleLoadDocumentsInitial
                        }
                        clientId={id}
                        title="Documentos declaración de renta"
                        notification={true}
                        promoterId={findData.investmentPromoter}
                        require={true}
                      />
                    )}
                  </Grid>
                </Grid>

                <Stack
                  direction={{ xs: "column" }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                  alignItems="center"
                  spacing={{ xs: 1, sm: 2 }}
                  sx={{ mb: 5 }}
                >
                  {errorSendNotification !== "" && (
                    <Typography variant="body1" color="error">
                      {errorSendNotification}
                    </Typography>
                  )}
                  {sendNotification !== "" && (
                    <Typography variant="body1" color="success">
                      {sendNotification}
                    </Typography>
                  )}
                  {findData.all_steps_complete === "true" &&
                    (findData.employment_status === "2" ||
                    findData.employment_status === "3" ||
                    findData.employment_status === "4"
                      ? !documentByClientInitial?.some(
                          (d: any) =>
                            d.id !== 2 &&
                            d.required === true &&
                            d.uploaded.length < 1
                        )
                      : !documentByClientInitial?.some(
                          (d: any) =>
                            d.required === true && d.uploaded.length < 1
                        )) &&
                    (findData.declare_income === "Sí"
                      ? documentDeclareIncome?.some(
                          (d: any) => d.uploaded.length > 0
                        )
                      : true) &&
                    (findData.axempt_agent_withholding === "Sí"
                      ? exemptAgentDocument?.some(
                          (d: any) => d.uploaded.length > 0
                        )
                      : true) && (
                      <ButtonGradient
                        type="button"
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                        onClick={handleSendNotification}
                      >
                        Enviar documentos
                      </ButtonGradient>
                    )}
                  {/* {findData.all_steps_complete === "true" &&
                    !documentByClientInitial?.some(
                      (d: any) => d.required === true && d.uploaded.length < 1
                    ) &&
                    (findData.declare_income === "Sí"
                      ? documentDeclareIncome?.some(
                          (d: any) => d.uploaded.length > 0
                        )
                      : true) &&
                    (findData.axempt_agent_withholding === "Sí"
                      ? exemptAgentDocument?.some(
                          (d: any) => d.uploaded.length > 0
                        )
                      : true) && (
                      <ButtonGradient
                        type="button"
                        sx={{ width: { xs: "100%", sm: "auto" } }}
                        onClick={handleSendNotification}
                      >
                        Enviar documentos
                      </ButtonGradient>
                    )} */}
                </Stack>
                <Stack
                  direction={{ xs: "column" }}
                  justifyContent={{ xs: "center", sm: "flex-start" }}
                  alignItems="center"
                  spacing={{ xs: 1, sm: 2 }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleDownloadKYC}
                    disabled={!findData.all_steps_complete}
                    sx={{
                      fontWeight: 700,
                      borderRadius: "0.75rem",
                      padding: "2px 15px",
                    }}
                  >
                    {loadedPDFKYC && (
                      <CircularProgress
                        size={24}
                        sx={{ marginRight: "5px", color: "#6fc555" }}
                      />
                    )}
                    Descarga aquí
                  </Button>
                  <Typography variant="body1">
                    Los formularios que acabas de completar y firma cada uno de
                    ellos.
                  </Typography>
                  {/* <Button
                variant="outlined"
                onClick={handleDownloadPerfil}
                disabled={!findData.all_steps_complete}
              >
                {loadedPDFPerfil && (
                  <CircularProgress
                    size={24}
                    sx={{ marginRight: "5px", color: "#6fc555" }}
                  />
                )}
                Descargar Evaluación del Perfil
              </Button> */}
                </Stack>
              </>
            )}

            {loadingSendData && (
              <Stack spacing={2} direction="row">
                <CircularProgress
                  size={24}
                  sx={{ margin: "5px auto", color: "#6fc555" }}
                />
              </Stack>
            )}
            {message !== "" && (
              <Typography sx={{ mt: 4 }} color="success.main" align="center">
                {message}
              </Typography>
            )}
            {message2 !== "" && (
              <Typography sx={{ mt: 4 }} color="primary" align="center">
                {message2}
              </Typography>
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestorTypeResults;
