import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import SocialNetworks from "../components/footer/SocialNetworks";
import Header from "../components/Header";
import PaperToForm from "../components/PaperToForm";

const GeneralFormLayout = () => {
  return (
    <Container maxWidth="lg" sx={{ pt: { xs: 10, sm: 15 }, pb: 8 }}>
      <Header />

      <PaperToForm sx={{ mb: 2, pb: 6 }}>
        <Outlet />
      </PaperToForm>
      <SocialNetworks />
    </Container>
  );
};

export default GeneralFormLayout;
