import { FC, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  ButtonBase,
  useTheme,
  styled,
  Typography,
  IconButton,
  Stack,
  Link,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Controller, set, useForm } from "react-hook-form";
import { getDocumentBlank } from "../services/Services";
import { useApp } from "../hooks/useApp";
import MessageManager from "./utils/MessageManager";
import { CONSTANT } from "../utils/Constants";
import { useDropzone } from "react-dropzone";
import { apis } from "../apis/apis";

const Field = styled(TextField)(({ theme }) => ({
  flexBasis: "calc(100% - 320px)",
  marginBottom: "0",
  marginTop: "0",
  "& .MuiFormLabel-root.Mui-disabled": {
    color: theme.palette.text.primary,
    transform: "translate(0, -1.5px) scale(0.75)",
  },
}));

const Dropzone = styled("div")(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "relative",
}));

const BoxField = styled(Box)(({ theme }) => ({
  "& .MuiInputBase-root": {
    marginTop: "0",
    position: "relative",
    overflow: "hidden",
    border: "1px solid #ced4da",
    borderLeft: "0",
    borderRadius: "0 0.375rem 0.375rem 0",
    "& input": {
      paddingBottom: "3px",
      paddingLeft: "5px",
      paddingTop: "0",
      width: "100%",
    },
  },
}));

const TypographyField = styled(Typography)(({ theme }) => ({
  background: "#e9ecef",
  padding: "2px 10px",
  borderRadius: ".375rem 0 0 .375rem",
  border: "1px solid #ced4da",
  height: "28px",
  width: "155px",
}));

const Label = styled("label")(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface InputFileType {
  label: string;
  error?: any;
  innerRef?: any;
  handleOptions?: any;
  clientId?: any;
  documentId: number;
  notification: boolean;
  promoterId?: string;
  nameDocument?: string;
  systemNameDocument?: string;
  extension?: string;
  extensionList?: string;
  idDocumentByClient?: any;
  handleUpload?: any;
  handleOpenModal?: any;
  handleDelete?: any;
  require?: boolean;
  requiredDocuments?: boolean;
  disabledByStage?: boolean;
  maxSize: number;
}

const FancyInputFile: FC<InputFileType> = ({
  label,
  error,
  innerRef,
  handleOptions,
  clientId,
  documentId,
  notification,
  promoterId,
  nameDocument,
  systemNameDocument,
  extension,
  extensionList,
  idDocumentByClient,
  handleUpload,
  handleOpenModal,
  handleDelete,
  require,
  requiredDocuments,
  disabledByStage,
  maxSize,
  ...props
}) =>
  //ref
  {
    const { register, handleSubmit } = useForm();

    const theme = useTheme();
    const navigate = useNavigate();
    const { authInfo, modalData } = useApp();
    const [currFile, setCurrFile] = useState<File[] | null>(null);
    const [alert, setAlert] = useState({
      open: false,
      type: CONSTANT.SUCCESS_MSG,
      msg: "",
      details: "",
    });

    let jsonExt: any =
      extensionList &&
      extensionList
        .replace(".pdf", "pdf")
        .replace(".doc", "doc")
        .replace(".docx", "docx")
        .replace(".xls", "xls")
        .replace(".xlsx", "xlsx")
        .replace("image/png", "png")
        .replace("image/jpeg", "jpeg")
        .replace("image/jpg", "jpg")
        .replace(".eml", "eml");

    const allowExt: any = extensionList
      ? JSON.parse(jsonExt)
      : ["pdf", "doc", "docx", "xls", "xlsx", "jpeg", "jpg", "png", "eml"];

    useEffect(() => {
      if (currFile) {
        const submitForm = async () => {
          await handleUpload({
            documentId: documentId,
            clientId: clientId,
            promoterId: promoterId,
            notification: false,
            user: authInfo.username,
            file: currFile,
          });
          handleSubmit(onSubmit);
        };

        submitForm();
      }
    }, [currFile]);

    const resetField = (field: string) => {
      switch (field) {
        case "file":
          setCurrFile(null);
          break;
        default:
          break;
      }
    };

    const onSubmit = async (data: any) => {
      switch (modalData?.modalType) {
        default:
          await handleUpload({ ...data, file: currFile });
          break;
      }
    };

    const onDrop = useCallback((acceptedFiles: File[]) => {
      if (acceptedFiles && acceptedFiles[0]?.name) {
        const fileName = acceptedFiles[0]?.name
          ?.split(".")
          ?.pop()
          ?.toUpperCase();

        const isAllowExt = allowExt.some(
          (ext: any) => ext.toUpperCase() === fileName?.toUpperCase()
        );
        if (!isAllowExt) {
          setAlert({
            open: true,
            type: CONSTANT.ERROR_MSG,
            msg: "Tipo de archivo no permitido",
            details: `Solo ${jsonExt.replace(/[^A-Z,/]+/gi, " ")}`,
          });
          resetField("file");
          return;
        }
        if (maxSize) {
          if (acceptedFiles[0].size > maxSize) {
            setAlert({
              open: true,
              type: CONSTANT.ERROR_MSG,
              msg: "Tamaño de archivo no permitido",
              details: `El archivo supera el tamaño máximo permitido de ${
                maxSize / 1000000
              }Mb`,
            });
            resetField("file");
            return;
          }
        } else {
          if (acceptedFiles[0].size > 1e7) {
            setAlert({
              open: true,
              type: CONSTANT.ERROR_MSG,
              msg: "Tamaño de archivo no permitido",
              details: "El archivo supera el tamaño máximo permitido de 10Mb",
            });
            resetField("file");
            return;
          }
        }
        setCurrFile(acceptedFiles);
      }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: true,
      noKeyboard: true,
      multiple: false,
    });

    return (
      <>
        <form>
          <Box
            position="relative"
            color={!!error ? theme.palette.error.main : "#898788"}
            sx={{
              color: "#898788",
              "&:focus": {
                color: "#ffc720",
              },
            }}
          >
            <BoxField>
              <Label htmlFor="">{label}</Label>
              <Tooltip title={""} placement="top">
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ position: "relative" }}
                >
                  <TypographyField
                    variant="body1"
                    color="text.primary"
                    sx={{ fontSize: "0.85rem" }}
                  >
                    Seleccionar archivo
                  </TypographyField>
                  <Field
                    variant="standard"
                    InputProps={{ disableUnderline: true }}
                    margin="normal"
                    fullWidth
                    disabled
                    value={
                      currFile && currFile[0].name
                        ? currFile[0].name
                        : nameDocument
                        ? nameDocument
                        : ""
                    }
                  />

                  <ButtonBase
                    component="label"
                    sx={{
                      padding: theme.spacing(2),
                      position: "absolute",
                      width: "calc(100% - 165px)",
                      bottom: "0",
                      height: "100%",
                    }}
                    data-client-id={typeof clientId}
                    disabled={Number(idDocumentByClient) > 0 || disabledByStage}
                  >
                    <Dropzone {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        id={`${documentId}`}
                        name="file"
                        accept={
                          extensionList
                            ? extensionList.replace(/[^A-Z,./]+/gi, "")
                            : `image/png, image/jpeg, image/jpg,.pdf,.doc,.docx,.xls,.xlsx,.eml`
                        }
                        data-ext={
                          extensionList &&
                          extensionList.replace(/[^A-Z,/"']+/gi, "")
                        }
                      />
                      {isDragActive ? (
                        <Typography
                          variant="body1"
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {" "}
                          Suelta los archivos aquí ...
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Dropzone>
                  </ButtonBase>
                  <Stack direction="row" sx={{ ml: "auto" }}>
                    <Tooltip title="Ver documento" placement="top">
                      {extension &&
                      (extension.toUpperCase() === "PDF" ||
                        extension.toUpperCase() === "JPG" ||
                        extension.toUpperCase() === "PNG") ? (
                        <IconButton
                          sx={{
                            color: "#ffc107",
                          }}
                          aria-label="view"
                          data-id={idDocumentByClient}
                          data-name-document={systemNameDocument}
                          data-extension={extension}
                          component={Link}
                          href={`${
                            apis.documents.viewdDocuments +
                              "?id=" +
                              idDocumentByClient +
                              "&originalName=" +
                              systemNameDocument ?? ""
                          }`}
                          target="_blank"
                          data-name="view"
                          disabled={!(Number(idDocumentByClient) > 0)}
                        >
                          <VisibilityIcon sx={{ fontSize: "1.75rem" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          sx={{
                            color: "#ffc107",
                          }}
                          aria-label="view"
                          component="label"
                          data-id={idDocumentByClient}
                          data-name-document={systemNameDocument}
                          data-extension={extension}
                          onClick={handleOpenModal}
                          data-name="view"
                          disabled={!(Number(idDocumentByClient) > 0)}
                        >
                          <VisibilityIcon sx={{ fontSize: "1.75rem" }} />
                        </IconButton>
                      )}
                    </Tooltip>
                    <Tooltip title="Descargar documento" placement="top">
                      <IconButton
                        sx={{
                          color: "#5271ff",
                        }}
                        aria-label="download"
                        component="label"
                        disabled={!(Number(idDocumentByClient) > 0)}
                        onClick={() =>
                          idDocumentByClient &&
                          nameDocument &&
                          getDocumentBlank(idDocumentByClient, nameDocument)
                        }
                      >
                        <FileDownloadIcon sx={{ fontSize: "1.55rem" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar documento" placement="top">
                      <IconButton
                        color="success"
                        aria-label="trash"
                        component="label"
                        data-id={idDocumentByClient}
                        data-name-document={systemNameDocument}
                        onClick={handleOpenModal}
                        data-name="delete"
                        data-dis={disabledByStage}
                      >
                        <DeleteIcon
                          sx={{ fontSize: "1.363rem" }}
                          className={`${disabledByStage}`}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Tooltip>
            </BoxField>
          </Box>
          <input
            hidden
            value={clientId}
            {...register("clientId", { required: true })}
          />
          <input
            hidden
            value={"false"}
            //value={notification === true ? "true" : "false"}
            {...register("notification", { required: true })}
          />
          <input
            hidden
            value={documentId}
            {...register("documentId", { required: true })}
          />
          <input
            hidden
            value={promoterId}
            {...register("promoterId", { required: true })}
          />
          <input
            hidden
            value={authInfo?.username}
            {...register("user", { required: true })}
          />
          {require && requiredDocuments && (
            <Typography sx={{ color: theme.palette.success.main }}>
              Documento requerido
            </Typography>
          )}
        </form>
        {alert.open && (
          <MessageManager
            type={alert.type}
            msg={alert.msg}
            details={alert.details}
            callback={() => setAlert({ ...alert, open: false })}
          />
        )}
      </>
    );
  };

export default FancyInputFile;
