import { Paper, styled } from "@mui/material";

const PaperGrid = styled(Paper)(({ theme }) => ({
  maxWidth: "100%",
  padding: theme.spacing(3),
  borderRadius: "25px",
  background: "#F8F5F3",
}));

const PaperToForm = (props: any) => {
  return (
    <PaperGrid elevation={6} {...props}>
      {" "}
      {props.children}
    </PaperGrid>
  );
};

export default PaperToForm;
