import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const PrivateRoute = ({ children }: any) => {
  const [cookies] = useCookies(["altio_auth"]);
  if (!cookies.altio_auth) {
    return <Navigate to={"/"} replace />;
  }
  return children;
};

export default PrivateRoute;
