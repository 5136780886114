import axios from "axios";
import { apis, URL } from "../apis/apis";
import { privateFetch } from "../utils/CustomFetch";
import { CONSTANT } from "../utils/Constants";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

enum Method {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
  PATCH = "PATCH",
}

export const getCode = async (userData: any) => {
  try {
    const response = await axios.post(
      apis.user.login,
      JSON.stringify(userData),
      { headers }
    );

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const getAutenticate = async (userData: any) => {
  try {
    const response = await axios.post(
      apis.user.autenticate,
      JSON.stringify(userData),
      { headers }
    );
    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const getRecoverPassword = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.recoverPassword, {
      method: Method.POST,
      headers: headers,
      body: JSON.stringify(userData),
    });
    const response = await getApi.json();
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response,
    };
  } catch (error) {
    console.log(error);
    return { body: error };
  }
};

export const updatePassword = async (data: {
  id: string;
  password: string;
}) => {
  let responseData = {};
  await axios
    .put(apis.user.changePassword, JSON.stringify(data), {
      headers: headers,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const resendNotificationActivateUser = async (username: any) => {
  let responseData = {};
  console.log(username?.email);
  const params = new URLSearchParams([["username", username?.email]]);
  await axios
    .post(`${apis.user.resendRecoverPassword}?username=${username?.email}`, {
      headers: headers,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message);
    });
  return responseData;
};

export const getProfile = async (userId: any) => {
  try {
    const responseProfile = await axios.get(
      `${apis.investmentProfile.CRUD}${userId}`
    );
    return {
      status: responseProfile.status,
      message: responseProfile.data.message,
      body: responseProfile.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const getCalculateProfile = async (userId: any) => {
  try {
    const responseProfile = await axios.get(
      `${apis.investmentProfile.calculate}${userId}`
    );
    return {
      status: responseProfile.status,
      message: responseProfile.data.message,
      body: responseProfile.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const saveProfile = async (userData: any) => {
  try {
    const response = await axios.post(apis.investmentProfile.CRUD, userData, {
      headers,
    });

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const updateProfile = async (userData: any) => {
  try {
    const response = await axios.post(apis.investmentProfile.update, userData, {
      headers,
    });

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const clientNotification = async (data: any, form: string) => {
  const params = new URLSearchParams([
    ["firstname", data.firstname],
    ["form", data.formtype],
    ["username", data.username],
  ]);
  try {
    const response = await axios.post(
      `${apis.investmentProfile.notification}${form}`,
      {
        headers,
      },
      { params }
    );

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const admintNotification = async (data: any) => {
  const params = new URLSearchParams([
    ["firstname", data.firstname],
    ["form", data.formtype],
    ["username", data.username],
  ]);
  try {
    const response = await axios.post(
      `${apis.investmentProfile.notification}completeClientForm`,
      {
        headers,
      },
      { params }
    );

    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

export const getAllData = async (type: string) => {
  try {
    const getApi = await fetch(`${apis.maintenance.allData}?type=${type}`, {
      method: Method.GET,
      headers: headers,
    });
    const response = await getApi.json();
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response,
    };
  } catch (error) {
    console.log(error);
    return { body: error };
  }
};

/* export const getAllData = async (type: string) => {
  let body: any = {};
  let status = {};
  let statusText = {};
  await privateFetch
    .get(`${apis.maintenance.allData}?type=${type}`, {
      headers: {
        ...CONSTANT.HEADER_TYPE,
      },
    })
    .then(async (response) => {
      body = await response.data;
      status = body.status;
    })
    .catch((error) => {
      console.log(error);
      return { body: error };
    });
  return { body, status };
}; */

export const getAddressData = async () => {
  try {
    const getApiP = await fetch(apis.maintenance.provinces, {
      method: Method.GET,
      headers: headers,
    });
    const provinces = await getApiP.json();

    const getApiM = await fetch(apis.maintenance.municipality, {
      method: Method.GET,
      headers: headers,
    });
    const municipality = await getApiM.json();

    return {
      statusP: getApiP.status,
      statusTextP: getApiP.statusText,
      provinces: provinces,

      statusM: getApiM.status,
      statusTextM: getApiM.statusText,
      municipality: municipality,
    };
  } catch (error) {
    console.log(error);
    return { body: error };
  }
};

export const getAddressSector = async (provinceID: string) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["provinceId", provinceID],
    ["page", "0"],
    ["size", "1000"],
  ]);
  await privateFetch
    .get(apis.maintenance.sector, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de sectores");
    });
  return responseData;
};

export const getKYC_fisico = async (id?: string | undefined) => {
  await fetch(`${apis.investmentProfile.kyc}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "conozca-su-cliente-persona-fisica.pdf";
      alink.click();
    });
  });
};

export const getAccountRegister = async (id?: string | undefined) => {
  await fetch(`${apis.investmentProfile.accountRegister}${id}`).then(
    (response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Registro-de-cuentas-bancarias.pdf";
        alink.click();
      });
    }
  );
};

export const getAuthorizedSignatures = async (id?: string | undefined) => {
  await fetch(`${apis.investmentProfile.authorizedSignatures}${id}`).then(
    (response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Tarjeta-de-firmas.pdf";
        alink.click();
      });
    }
  );
};

export const getQuestionnaireProfile = async (id?: string | undefined) => {
  await fetch(`${apis.investmentProfile.questionnaireProfile}${id}`).then(
    (response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Cuestionario-perfil-de-riesgo.pdf";
        alink.click();
      });
    }
  );
};

export const getProfileDoc = async (id?: string | undefined) => {
  await fetch(`${apis.investmentProfile.perfil}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = "Evaluacion-del-perfil.pdf";
      alink.click();
    });
  });
};

//Get Documents List
export const getListcategoryDocuments = async () => {
  //let responseData = {};
  try {
    const response = await fetch(apis.documents.categoryDocuments, {
      method: Method.GET,
      headers: headers,
    });
    const categories = await response.json();
    return {
      categories: categories,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
};

//Upload Bulk Documents
export const uploadBulkDocuments = async (data: any) => {
  let responseData = {};
  await privateFetch
    .post(apis.documents.uploadDocuments, data, {
      headers,
    })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response);
    });
  return responseData;
};

//Delete Documents
export const deleteDocuments = async (id: string, user: string) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["id", id],
    ["user", user],
  ]);
  await axios
    .delete(apis.documents.deleteDocuments, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch(async (error) => {
      throw new Error(error.response);
    });
  return responseData;
};

//View Documents
export const getDocumentBlank = async (
  idDocument: string,
  nameDocument: string
) => {
  let responseData: any = {};
  const params = new URLSearchParams([["id", idDocument]]);
  await privateFetch
    .get(apis.documents.downloadDocuments, {
      params,
      responseType: "blob",
    })
    .then(async (response) => {
      responseData = await response.data;
      const url = window.URL.createObjectURL(new Blob([responseData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nameDocument);
      document.body.appendChild(link);
      link.click();
    })
    .catch(async (error) => {
      throw new Error("Error al ver documento");
    });
  return responseData;
};

//View Documents Image
export const getDocumentImage = async (
  idDocument: string,
  nameDocument: string
) => {
  let responseData: any = {};
  const params = new URLSearchParams([["id", idDocument]]);
  await privateFetch
    .get(apis.documents.downloadDocuments, {
      params,
      responseType: "blob",
    })
    .then(async (response) => {
      responseData = await response.data;
      const url = window.URL.createObjectURL(new Blob([responseData]));
    })
    .catch(async (error) => {
      throw new Error("Error al ver documento");
    });
  return responseData;
};

//List Documents by Client
export const getRequestDocumentClient = async (
  id: any,
  categoryType?: string | null
) => {
  let responseData = {};
  const params = new URLSearchParams([
    ["customer", id],
    [categoryType !== null && "categoryType", categoryType],
  ]);
  await privateFetch
    .get(apis.documents.documentsByClient, { params })
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en consulta de documentos por cliente");
    });
  return responseData;
};

//Send Notification
export const requestSendNotification = async (
  clientId: any,
  promoterId?: string | null
) => {
  let responseData = {};
  await privateFetch
    .post(
      apis.documents.sendNotification +
        "?clientId=" +
        clientId +
        "&promoterId=" +
        promoterId
    )
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error("Error en envío de notificación");
    });
  return responseData;
};

//Get user Data
export const getUserData = async (username: string) => {
  let responseData = {};
  await privateFetch
    .get(URL.USER_CRUD + username)
    .then(async (response) => {
      responseData = await response.data;
    })
    .catch((error) => {
      throw new Error(error.message);
    });
  return responseData;
};
/* export const getUserData = async (username: string) => {
  try {
    const response = await privateFetch.get(`${apis.user.CRUD}${username}`);
    return {
      status: response.status,
      message: response.data.message,
      body: response.data,
    };
  } catch (e: any) {
    return {
      messageError: e.response.data.message,
    };
  }
}; */
