import { useState } from "react";
import { useNavigate } from "react-router-dom";
import usePhysycalForm from "../hooks/usePhysycalForm";
import useLegalForm from "../hooks/useLegalForm";
import useProfile from "../hooks/useProfile";
import { useApp } from "../hooks/useApp";
import {
  Avatar,
  Container,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Menu,
  MenuItem,
  ListItemIcon,
  styled,
} from "@mui/material";
import { AltioLogo } from "./iconsSvg/AltioSvgIcons";
import { useCookies } from "react-cookie";
import ButtonGradient from "./ButtonGradient";
import PersonIcon from "@mui/icons-material/Person";
import Logout from "@mui/icons-material/Logout";

const HeaderWrapp = styled("header")(({ theme }) => ({
  width: "100vw",
  textAlign: "left",
  position: "absolute",
  left: "0",
  top: "0",
  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(2),
  },
}));

const AvatarLink = styled(Avatar)(({ theme }) => ({
  width: 45,
  height: 45,
  background:
    "linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)",
  "&:hover": {
    background:
      "linear-gradient(to bottom, rgba(53,165,68,1) 0%, rgba(98,188,68,1)61%, rgba(99,188,68,1) 62%, rgba(138,196,63,1) 100%)",
  },
  [theme.breakpoints.down("sm")]: {
    width: 30,
    height: 30,
  },
}));

const Header = () => {
  const [removeCookie] = useCookies(["altio_auth"]);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [openMenu, setOpenMenu] = useState<null | HTMLElement>(null);
  const open = Boolean(openMenu);
  const { logoutProvider } = useApp();
  const { removeDataPhysical } = usePhysycalForm();
  const { removeLegalData } = useLegalForm();

  const {
    setDataForm,
    setInvestmentProfile,
    exists,
    setCompleteData,
    setSteps_completed,
  } = useProfile();

  const removeData = async () => {
    setDataForm && setDataForm(undefined);
    setInvestmentProfile && setInvestmentProfile([]);
    setCompleteData && setCompleteData(false);
    setSteps_completed && setSteps_completed([]);
  };

  const handleCloseSession = () => {
    logoutProvider();
    removeData();
    removeDataPhysical && removeDataPhysical();
    removeLegalData && removeLegalData();
    //removeCookie("altio_auth");
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("data_form");
    sessionStorage.removeItem("formType");
    localStorage.removeItem("user_id");
    localStorage.removeItem("data_form");
    localStorage.removeItem("stepForm");
    localStorage.removeItem("calculateProfile");
    localStorage.removeItem("allDataTablesJ");
    localStorage.removeItem("allDataTablesF");
    localStorage.removeItem("allDataTables");
    localStorage.removeItem("addressData");
    navigate("/");
  };

  const handleReturnHome = () => {
    console.log("Ingresa return home");
    handleClose();
    removeData();
    removeDataPhysical && removeDataPhysical();
    //navigate("/formClient/physical");
    //navigate("/physical-or-legal");
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleMenu = (e: React.MouseEvent<HTMLElement>) => {
    setOpenMenu(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return (
    <HeaderWrapp>
      <Container maxWidth="lg">
        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={4} sx={{ display: "flex", columnGap: "25px" }}>
            <AltioLogo />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: "flex" }}
            justifyContent="center"
            alignItems="center"
          >
            {/* {!exists && (
              <IconWithLink
                aria-label="home"
                onClick={() => setOpenDialog(true)}
                disableRipple
              >
                <HomeIcon
                  color="secondary"
                  sx={{ fontSize: { xs: "35px", sm: "45px" } }}
                />
                <Typography sx={{ display: { xs: "none", sm: "block" } }}>
                  Inicio
                </Typography>
              </IconWithLink>
            )} */}
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: "flex" }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              onClick={handleMenu}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableRipple
            >
              <AvatarLink>
                <PersonIcon sx={{ fontSize: { xs: "22px", sm: "35px" } }} />
              </AvatarLink>
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Menu
        anchorEl={openMenu}
        id="account-menu"
        open={open}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleCloseSession}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {" "}
        <DialogTitle id="alert-dialog-title" sx={{ pt: 4, px: 4 }}>
          ¿Está seguro de regresar a Inicio?
        </DialogTitle>
        <DialogContent sx={{ px: 4, pb: 0 }}>
          <DialogContentText id="alert-dialog-description">
            Si regresa a inicio perdera toda la información completada en los
            campos
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ pb: 4, px: 4 }}>
          <Button
            variant="contained"
            sx={{ mt: 2, mr: 2 }}
            onClick={handleClose}
            autoFocus
          >
            Cancelar
          </Button>
          <ButtonGradient onClick={handleReturnHome}>Aceptar</ButtonGradient>
        </DialogActions>
      </Dialog>
    </HeaderWrapp>
  );
};

export default Header;
