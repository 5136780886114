import { FC, useEffect, useState } from "react";
import usePhysycalForm from "../../hooks/usePhysycalForm";
import useProfile from "../../hooks/useProfile";
import {
  Stack,
  Grid,
  TextField,
  Autocomplete,
  Divider,
  Button,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ButtonGradient from "../../components/ButtonGradient";
import TitleForm from "../../components/TitleForm";
import * as Yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PhysycalLaborData } from "../../types/types";
import { phoneRegExp, formSchemaOptions, formSchemaCountry } from "../../utils";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";

const JobsInformation: FC = () => {
  const [loadingSendData, setLoadingSendData] = useState(false);
  const {
    setActiveStep,
    step2Answered,
    setStep2Answered,
    step2Field,
    setStep2Field,
    handleBack,
    handleNext,
  } = usePhysycalForm();
  const {
    dataForm,
    completeData,
    loadDataServer,
    data,
    address,
    getSectorAddress,
  } = useProfile();

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step2Field;
  }

  const countryASC =
    data &&
    data.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const defaultValues: PhysycalLaborData = {
    employment_status: findData.employment_status
      ? data.al_ta_employment_status.find(
          (f: any) => f.id === Number(findData.employment_status)
        )
      : [],
    ocupation: findData.ocupation ? findData.ocupation : "",
    /* ocupation: findData.ocupation
      ? data.al_ta_occupation.find(
          (f: any) => f.id === Number(findData.ocupation)
        )
      : [], */
    companyName: findData.companyName ?? "",
    economic_activity: findData.economic_activity
      ? data.al_ta_economic_activity.find(
          (f: any) => f.id === Number(findData.economic_activity)
        )
      : [],
    financially_dependent_third_party:
      findData.financially_dependent_third_party
        ? datos.selectBooleanString.find(
            (f: any) => f.id === findData.financially_dependent_third_party
          )
        : [],
    name_lastname_third_party: findData.name_lastname_third_party
      ? findData.name_lastname_third_party
      : "",
    id_type_third_party: findData.id_type_third_party
      ? datos.id_type.find((f: any) => f.id === findData.id_type_third_party)
      : [],
    number_identity_third_party: findData.number_identity_third_party
      ? findData.number_identity_third_party
      : "",
    relationship_third_party: findData.relationship_third_party
      ? datos.degrees_of_consanguinity.find(
          (f: any) => f === findData.relationship_third_party
        )
      : "",

    countryWorks: findData.countryWorks
      ? data.al_ta_countries.find(
          (f: any) => f.id === Number(findData.countryWorks)
        )
      : [],
    provinceWorks: findData.provinceWorks
      ? address.provinces.find(
          (f: any) => f.id === Number(findData.provinceWorks)
        )
      : [],
    sectorWorks: [],
    /* sectorWorks: findData.sectorWorks
      ? address.sector.find((f: any) => f.id === Number(findData.sectorWorks))
      : [], */
    streetWorks: findData.streetWorks ? findData.streetWorks : "",
    houseApartNumberWorks: findData.houseApartNumberWorks
      ? findData.houseApartNumberWorks
      : "",
    buildingWorks: findData.buildingWorks ? findData.buildingWorks : "",
    averageAnnualIncome: findData.averageAnnualIncome
      ? data.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.averageAnnualIncome)
        )
      : [],
    liquid_assets_total: findData.liquid_assets_total
      ? data.al_ta_total_liquid_assets.find(
          (f: any) => f.id === Number(findData.liquid_assets_total)
        )
      : [],
    total_passives: findData.total_passives
      ? data.al_ta_total_passive.find(
          (f: any) => f.id === Number(findData.total_passives)
        )
      : [],
    financialObligations: findData.financialObligations
      ? data.al_ta_financial_obligations.find(
          (f: any) => f.id === Number(findData.financialObligations)
        )
      : [],
    total_heritage: findData.total_heritage
      ? data.al_ta_total_heritage.find(
          (f: any) => f.id === Number(findData.total_heritage)
        )
      : [],
    sources_funds: findData.sources_funds
      ? data.al_ta_sources_income.find(
          (f: any) => f.id === Number(findData.sources_funds)
        )
      : [],
    other_sources_funds: findData.other_sources_funds
      ? findData.other_sources_funds
      : "",
    axempt_agent_withholding: findData.axempt_agent_withholding
      ? datos.selectBooleanString.find(
          (f: any) => f.id === findData.axempt_agent_withholding
        )
      : [],
    which_taxes: findData.which_taxes ? findData.which_taxes : "",
    declare_income: findData.declare_income
      ? datos.selectBooleanString.find(
          (f: any) => f.id === findData.declare_income
        )
      : [],
    liquid_assets_distribution_form:
      (findData.liquid_assets_distribution_form &&
        JSON.parse(findData.liquid_assets_distribution_form)) ?? [
        {
          liquid_assets_distribution_id: [],
          //liquid_assets_distribution_description: [],
        },
      ],
  };

  const formSchemaLiquidAssetsDistribution = {
    liquid_assets_distribution_id: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    /* liquid_assets_distribution_description: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"), */
  };

  const validation = Yup.object().shape({
    employment_status: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    ocupation: Yup.string().when("employment_status", {
      is: (employment_status: any) => {
        if (employment_status?.id === "1") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    companyName: Yup.string().when("employment_status", {
      is: (employment_status: any) => {
        if (employment_status?.id === "1") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    economic_activity: Yup.object().when("employment_status", {
      is: (employment_status: any) => {
        if (employment_status?.id === "2") {
          return true;
        }
      },
      then: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      otherwise: Yup.object().nullable(),
    }),
    financially_dependent_third_party: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    name_lastname_third_party: Yup.string().when(
      "financially_dependent_third_party",
      {
        is: "Sí",
        then: Yup.string().required("Campo es requerido"),
      }
    ),
    id_type_third_party: Yup.object()
      .nullable()
      .when("financially_dependent_third_party", {
        is: "Sí",
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
      }),
    number_identity_third_party: Yup.string().when(
      "financially_dependent_third_party",
      {
        is: "Sí",
        then: Yup.string().required("Campo es requerido"),
      }
    ),
    relationship_third_party: Yup.string(),
    countryWorks: Yup.object()
      .nullable()
      .when("employment_status", {
        is: (employment_status: any) => {
          if (employment_status?.id === "1" || employment_status?.id === "4") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    provinceWorks: Yup.object()
      .nullable()
      .when("countryWorks", {
        is: (countryWorks: any) => {
          if (countryWorks?.id === 63) {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    sectorWorks: Yup.object()
      .nullable()
      .when("countryWorks", {
        is: (countryWorks: any) => {
          if (countryWorks?.id === 63) {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaCountry)
          .typeError("Campo es requerido"),
      }),
    streetWorks: Yup.string().when("employment_status", {
      is: (employment_status: any) => {
        if (employment_status?.id === "1" || employment_status?.id === "4") {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    houseApartNumberWorks: Yup.string(),
    buildingWorks: Yup.string(),
    averageAnnualIncome: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    liquid_assets_total: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    total_passives: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    financialObligations: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    total_heritage: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    sources_funds: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    other_sources_funds: Yup.string().when("sources_funds", {
      is: (sources_funds: any) => {
        if (Number(sources_funds?.id) === 8) {
          return true;
        }
      },
      then: Yup.string().required("Campo es requerido"),
    }),
    axempt_agent_withholding: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    which_taxes: Yup.string().when("axempt_agent_withholding", {
      is: "Sí",
      then: Yup.string().required("Campo es requerido"),
    }),
    declare_income: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
    liquid_assets_distribution_form: Yup.array().of(
      Yup.object().shape(formSchemaLiquidAssetsDistribution)
    ),
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    resetField,
    getValues,
    reset,
    setValue,
  } = useForm<PhysycalLaborData>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors, isDirty, dirtyFields } = formState;
  const miarray: any = [];

  useEffect(() => {
    reset(defaultValues);
  }, [dataForm]);

  const watch_employment_status: any = watch("employment_status");
  const watch_country: any = watch("countryWorks");
  const watch_province: any = watch("provinceWorks");
  const watch_financially_dependent_third_party: any = watch(
    "financially_dependent_third_party"
  );
  const watch_axempt_agent_withholding: any = watch("axempt_agent_withholding");
  const watch_sources_funds: any = watch("sources_funds");
  const watch_economic_activity: any = watch("economic_activity");
  const [sectorData, setSectorData] = useState([]);

  const {
    fields: liquid_assets_distributionField,
    append: liquid_assets_distributionAppend,
    remove: liquid_assets_distributionRemove,
  } = useFieldArray({
    control,
    name: "liquid_assets_distribution_form",
    rules: {
      minLength: 3,
    },
  });

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.sources_funds) {
      resetField("other_sources_funds", { defaultValue: "" });
      setValue("other_sources_funds", "", { shouldDirty: true });
    } else if (watch_sources_funds?.id !== 8) {
      resetField("other_sources_funds");
    }
  }, [watch_sources_funds]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.countryWorks) {
      resetField("provinceWorks", { defaultValue: [] });
      resetField("sectorWorks", { defaultValue: [] });
      setValue("provinceWorks", [], { shouldDirty: true });
      setValue("sectorWorks", [], { shouldDirty: true });
    } else if (watch_country?.id !== 63) {
      resetField("provinceWorks");
      resetField("sectorWorks");
    }
    //console.log("watch_country: ", watch_country);
  }, [watch_country]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.provinceWorks) {
      resetField("sectorWorks", { defaultValue: [] });
    } else {
      resetField("sectorWorks");
    }
    const getSector = async () => {
      const s: any = await getSectorAddress(watch_province.provinceCode);
      const filterSector = s?.find(
        (f: any) => f.id === Number(findData.sectorWorks)
      );
      setValue("sectorWorks", filterSector);
      setSectorData(s);
    };
    if (watch_province?.provinceCode) {
      getSector();
    }
  }, [watch_province]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.employment_status) {
      setValue("economic_activity", [], { shouldDirty: true });
    } else if (watch_employment_status?.id !== 2) {
      setValue("economic_activity", []);
    }
  }, [watch_employment_status]);

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];
    const liquid_assets_distribution: any[] = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id
            ? typeof value.id !== "string"
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value);
      }
    }

    JSON.parse(data.liquid_assets_distribution_form).forEach((e: any) => {
      liquid_assets_distribution.push(
        Number(e.liquid_assets_distribution_id.id)
      );
    });
    data.liquid_assets_distribution = JSON.stringify(
      liquid_assets_distribution
    );
    data.step_two_complete = "true";

    let update = {};

    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
      Object.assign(update, {
        liquid_assets_distribution: JSON.stringify(liquid_assets_distribution),
      });
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep2Field && setStep2Field(data);
      setStep2Answered && setStep2Answered(true);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_two_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "F",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Información Laboral",
      };
      localStorage.setItem("stepForm", "Información Laboral");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      } else {
        setLoadingSendData(false);
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...update,
          },
          formType: "F",
          userId: sessionStorage.getItem("user_id"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm sx={{ mb: 4 }}>Información Laboral & Financiera</TitleForm>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="employment_status"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={data.al_ta_employment_status}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Estado Laboral"
                      variant="standard"
                      autoFocus
                      error={!!errors.employment_status}
                      helperText={
                        errors.employment_status &&
                        errors.employment_status.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_employment_status?.id === 1 && (
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="ocupation"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Ocupación en la empresa"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  multiline
                  rows={1}
                  maxRows={6}
                  {...register("ocupation")}
                  error={errors.ocupation && Boolean(errors.ocupation)}
                  helperText={errors.ocupation && errors.ocupation.message}
                />
              )}
            />
          </Grid>
        )}
        {/* <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_occupation}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Ocupación en la empresa"
                        variant="standard"
                        autoFocus
                        error={!!errors.ocupation}
                        helperText={
                          errors.ocupation && errors.ocupation.message
                        }
                      />
                    );
                  }}
                /> */}
        {/*  <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={"ocupation"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Ocupación"
                type="text"
                variant="standard"
                value={value}
                disabled={completeData}
                {...register("ocupation")}
                error={errors.ocupation && Boolean(errors.ocupation)}
                helperText={errors.ocupation && errors.ocupation.message}
              />
            )}
          />
        </Grid> */}
        {watch_employment_status?.id === 1 && (
          <Grid item md={4} sm={6} xs={12} sx={{ mt: "3px" }}>
            <Controller
              name={"companyName"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre de la empresa"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  {...register("companyName")}
                  error={errors.companyName && Boolean(errors.companyName)}
                  helperText={errors.companyName && errors.companyName.message}
                />
              )}
            />
          </Grid>
        )}
        {watch_employment_status?.id === 2 && (
          <Grid item md={8} sm={6} xs={12}>
            <Controller
              control={control}
              name="economic_activity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  options={data.al_ta_economic_activity}
                  getOptionLabel={(option: any) => option.description || ""}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.id === value.id
                  }
                  value={value}
                  disabled={completeData}
                  renderInput={(params) => {
                    const inputProps = params.inputProps;
                    inputProps.autoComplete = "new-password";
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Actividad Económica"
                        variant="standard"
                        autoFocus
                        error={!!errors.economic_activity}
                        helperText={
                          errors.economic_activity &&
                          errors.economic_activity.message
                        }
                      />
                    );
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="financially_dependent_third_party"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Depende económicamente de un tercero?"
                      variant="standard"
                      error={!!errors.financially_dependent_third_party}
                      helperText={
                        errors.financially_dependent_third_party &&
                        errors.financially_dependent_third_party.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {watch_financially_dependent_third_party?.id === "Sí" && (
        <>
          <Divider
            sx={{
              pt: 3,
              mb: 0,
              borderColor: "#efe5df",
              width: "50%",
              ml: "auto",
              mr: "auto",
            }}
          />
          <Grid container spacing={4} sx={{ mt: 0, mb: 4 }}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"name_lastname_third_party"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre y Apellidos"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={completeData}
                    {...register("name_lastname_third_party")}
                    error={
                      errors.name_lastname_third_party &&
                      Boolean(errors.name_lastname_third_party)
                    }
                    helperText={
                      errors.name_lastname_third_party &&
                      errors.name_lastname_third_party.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="id_type_third_party"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={datos.id_type}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled={completeData}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de Documento"
                          variant="standard"
                          error={!!errors.id_type_third_party}
                          helperText={
                            errors.id_type_third_party &&
                            errors.id_type_third_party.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={"number_identity_third_party"}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de Documento"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={completeData}
                    {...register("number_identity_third_party")}
                    error={
                      errors.number_identity_third_party &&
                      Boolean(errors.number_identity_third_party)
                    }
                    helperText={
                      errors.number_identity_third_party &&
                      errors.number_identity_third_party.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="relationship_third_party"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={datos.degrees_of_consanguinity}
                    getOptionLabel={(option: any) => option || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option === value
                    }
                    value={value}
                    disabled={completeData}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Parentesco o Relación"
                          variant="standard"
                          error={!!errors.relationship_third_party}
                          helperText={
                            errors.relationship_third_party &&
                            errors.relationship_third_party.message
                          }
                        />
                      );
                    }}
                  />
                )}
              />
              {/* <Controller
                control={control}
                name="relationship_third_party"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={datos.degrees_of_consanguinity}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled={completeData}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Parentesco o Relación"
                          variant="standard"
                          error={!!errors.relationship_third_party}
                          helperText={
                            errors.relationship_third_party &&
                            errors.relationship_third_party.message
                          }
                        />
                      );
                    }}
                  />
                )}
              /> */}
            </Grid>
          </Grid>
          <Divider
            sx={{
              pt: 3,
              mb: 6,
              borderColor: "#efe5df",
              width: "50%",
              ml: "auto",
              mr: "auto",
            }}
          />
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Dirección
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="countryWorks"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data && countryASC}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="País"
                    variant="standard"
                    error={!!errors.countryWorks}
                    helperText={
                      errors.countryWorks && errors.countryWorks.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        {watch_country?.id === 63 && (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="provinceWorks"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={address.provinces}
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.provinceCode === value.provinceCode
                    }
                    value={value}
                    disabled={completeData}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Provincia"
                          variant="standard"
                          error={!!errors.provinceWorks}
                          helperText={
                            !!errors.provinceWorks && "Campo es requerido"
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="sectorWorks"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    options={sectorData?.length > 0 ? sectorData : []}
                    /* options={
                      address &&
                      address.sector.filter(
                        (m: any) =>
                          m.provinceCode === watch_province?.provinceCode
                      )
                    } */
                    getOptionLabel={(option: any) => option.description || ""}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.id === value.id
                    }
                    value={value}
                    disabled={completeData || sectorData?.length === 0}
                    renderInput={(params) => {
                      const inputProps = params.inputProps;
                      inputProps.autoComplete = "new-password";
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sector"
                          variant="standard"
                          error={!!errors.sectorWorks}
                          helperText={
                            !!errors.sectorWorks && "Campo es requerido"
                          }
                        />
                      );
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={"streetWorks"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Calle"
                type="text"
                variant="standard"
                autoComplete="streetWorks"
                value={value}
                disabled={completeData}
                {...register("streetWorks")}
                error={errors.streetWorks && Boolean(errors.streetWorks)}
                helperText={errors.streetWorks && errors.streetWorks.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={"houseApartNumberWorks"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="N° Calle / Apto"
                type="text"
                variant="standard"
                autoComplete="houseApartNumberWorks"
                value={value}
                disabled={completeData}
                {...register("houseApartNumberWorks")}
                error={
                  errors.houseApartNumberWorks &&
                  Boolean(errors.houseApartNumberWorks)
                }
                helperText={
                  errors.houseApartNumberWorks &&
                  errors.houseApartNumberWorks.message
                }
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={"buildingWorks"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Edificio"
                type="text"
                variant="standard"
                autoComplete="buildingWorks"
                value={value}
                disabled={completeData}
                {...register("buildingWorks")}
                error={errors.buildingWorks && Boolean(errors.buildingWorks)}
                helperText={
                  errors.buildingWorks && errors.buildingWorks.message
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información financiera
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="averageAnnualIncome"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Promedio de Ingresos Anuales"
                      variant="standard"
                      error={!!errors.averageAnnualIncome}
                      helperText={
                        errors.averageAnnualIncome &&
                        errors.averageAnnualIncome.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="liquid_assets_total"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_total_liquid_assets}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total Activos Líquidos"
                      variant="standard"
                      error={!!errors.liquid_assets_total}
                      helperText={
                        errors.liquid_assets_total &&
                        errors.liquid_assets_total.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_passives"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_total_passive}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total Pasivos"
                      variant="standard"
                      error={!!errors.total_passives}
                      helperText={
                        errors.total_passives && errors.total_passives.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="financialObligations"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_financial_obligations}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Obligaciones Financieras"
                      variant="standard"
                      error={!!errors.financialObligations}
                      helperText={
                        errors.financialObligations &&
                        errors.financialObligations.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_total_heritage}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio Total"
                      variant="standard"
                      error={!!errors.total_heritage}
                      helperText={
                        errors.total_heritage && errors.total_heritage.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Origen de fondos
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={data.al_ta_sources_income}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Los recursos que entregué o entregaré para la inversión en uno o más fondos de inversión abiertos administrados por ALTIO provienen de las siguientes fuentes."
                      variant="standard"
                      error={!!errors.sources_funds}
                      helperText={
                        errors.sources_funds && errors.sources_funds.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_sources_funds?.id === 8 && (
          <Grid item xs={12}>
            <Controller
              name={"other_sources_funds"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Otro"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  {...register("other_sources_funds")}
                  error={
                    errors.other_sources_funds &&
                    Boolean(errors.other_sources_funds)
                  }
                  helperText={
                    errors.other_sources_funds &&
                    errors.other_sources_funds.message
                  }
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
        * Los recursos que maneje a través de los fondos de inversión abiertos
        administrados por ALTIO, se desarrollan de conformidad con la ley contra
        el lavado de activos y financiamiento del terrorismo (ley 155-17), y la
        normativa complementaria, y por tanto, no provienen de ninguna actividad
        ilícita. No admitiré que terceros efectúen aportes en mi nombre ni
        efectuaré transacciones destinadas a actividades ilícitas o a favor de
        personas relacionadas con las mismas.
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información tributaria
      </TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="axempt_agent_withholding"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Es usted un agente exento de retención u otros impuestos?"
                      variant="standard"
                      error={!!errors.axempt_agent_withholding}
                      helperText={
                        errors.axempt_agent_withholding &&
                        errors.axempt_agent_withholding.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        {watch_axempt_agent_withholding?.id === "Sí" && (
          <Grid item xs={12}>
            <Controller
              name={"which_taxes"}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="¿Cuáles?"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={completeData}
                  {...register("which_taxes")}
                  error={errors.which_taxes && Boolean(errors.which_taxes)}
                  helperText={errors.which_taxes && errors.which_taxes.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
        * Si seleccionó “sí” en la pregunta anterior, incluir certificación de
        la DGII o equivalente.
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="declare_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = "";
                  }
                  onChange(newValue);
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Declara Renta?"
                      variant="standard"
                      error={!!errors.declare_income}
                      helperText={
                        errors.declare_income && errors.declare_income.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
        * Si es extranjero, anexar “formulario residencia fiscal” completo
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {liquid_assets_distributionField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data.al_ta_liquid_assets_distribution}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <Button
                    type="button"
                    variant="outlined"
                    sx={{
                      width: "100%",
                      maxWidth: "185px",
                      ml: 2,
                      fontSize: "0.75rem",
                    }}
                    onClick={() => {
                      liquid_assets_distributionRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="small" sx={{ mr: 1 }} />
                    Eliminar activo líquido
                  </Button>
                )}
              </Grid>
              {/*  <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_description`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data.al_ta_total_liquid_assets}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Valor Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_description && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_description?.message
                          }
                        />
                      )}
                    />
                  )}
                />

              </Grid> */}
            </Grid>
          </div>
        );
      })}
      {liquid_assets_distributionField.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              liquid_assets_distributionAppend({
                liquid_assets_distribution_id: [],
                //liquid_assets_distribution_description: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar Activo
            Líquido
          </Button>
        </Grid>
      )}
      <Typography variant="body2">* Mínimo 1 activo líquido </Typography>
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        </Stack>
      )}
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step2Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="submit"
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
    </form>
  );
};

export default JobsInformation;
