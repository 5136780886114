import { useNavigate } from "react-router-dom";
import { Stack, styled, Typography, Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import PaperToForm from "../components/PaperToForm";
import ButtonGradient from "../components/ButtonGradient";
import { Altio } from "../components/iconsSvg/AltioSvgIcons";
import TitleForm from "../components/TitleForm";
import SocialNetworks from "../components/footer/SocialNetworks";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useCookies } from "react-cookie";
import useProfile from "../hooks/useProfile";
import { useApp } from "../hooks/useApp";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(4, 2),
}));

const LinkTo = styled(Link)(({ theme }) => ({
  textDecoration: "none",
}));

const CloseSession = styled(Button)(({ theme }) => ({
  display: "flex",
  marginLeft: "auto",
  "&:hover": {
    textDecoration: "underline",
    background: "transparent!important",
  },
}));

const IconWithLink = styled(IconButton)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  background: "transparent!important",
  "& svg": {
    transition: "all 0.2s linear",
  },
  "&:hover svg": {
    transform: "translateY(-3px)",
    filter: "drop-shadow(2px 2px 5px rgb(0 0 0 / 0.3))",
  },
}));

const PhysicalOrLegalForm = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["altio_auth"]);
  const navigate = useNavigate();
  const { setDataForm, setInvestmentProfile, exists, setSteps_completed } =
    useProfile();
  const { logoutProvider } = useApp();

  const removeData = async () => {
    logoutProvider();
    setDataForm && setDataForm(undefined);
    setInvestmentProfile && setInvestmentProfile([]);
    sessionStorage.removeItem("user_id");
    sessionStorage.removeItem("data_form");
    sessionStorage.removeItem("formType");
    removeCookie("altio_auth");
    localStorage.removeItem("user_id");
    localStorage.removeItem("data_form");
    localStorage.removeItem("stepForm");
    localStorage.removeItem("calculateProfile");
    localStorage.removeItem("allDataTablesJ");
    localStorage.removeItem("allDataTablesF");
    localStorage.removeItem("allDataTables");
    localStorage.removeItem("addressData");
    setSteps_completed && setSteps_completed([]);
  };

  const handleCloseSession = async () => {
    await removeData();
    navigate("/");
  };

  return (
    <Content justifyContent="center" alignItems="center" spacing={2}>
      <Altio />
      <PaperToForm sx={{ width: "550px" }}>
        <TitleForm sx={{ mt: 5, mb: 3 }}>Tipo formulario</TitleForm>
        <Stack
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={6}
          sx={{ mt: 8, mb: 9 }}
        >
          <LinkTo to="/formClient/physical">
            <IconWithLink disableRipple>
              <AssignmentIndIcon color="secondary" sx={{ fontSize: "6rem" }} />
              <Typography variant="h6" color="text">
                Persona Física
              </Typography>
            </IconWithLink>
          </LinkTo>
          <LinkTo to="/formClient/legal">
            <IconWithLink disableRipple>
              <AccountBalanceIcon color="primary" sx={{ fontSize: "6rem" }} />
              <Typography variant="h6" color="text">
                Persona Jurídica
              </Typography>
            </IconWithLink>
          </LinkTo>
        </Stack>
        <CloseSession onClick={handleCloseSession}>Cerrar sesión</CloseSession>
      </PaperToForm>
      <SocialNetworks />
    </Content>
  );
};

export default PhysicalOrLegalForm;
