import { FC, useState, useEffect } from "react";
import {
  Stack,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useParams } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationForgotPassword } from "../validation";
import { registerTypes } from "../../../types/types";
import { updatePassword } from "../../../services/Services";

const ForgotPassword: FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { code } = useParams();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(undefined);
  const [messageError, setMessageError] = useState<any>("");
  const formOptions = { resolver: yupResolver(validationForgotPassword) };

  const { register, handleSubmit, formState, control } =
    useForm<registerTypes>(formOptions);
  const { errors } = formState;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleChangePassword = async (data: {
    password: string;
    repeatPassword: string;
  }) => {
    setMessageError("");
    try {
      setLoading(true);
      const userData = {
        password: data.password,
        id: code ?? "",
      };
      const responseAutenticate = await updatePassword(userData);
      if (responseAutenticate) {
        setResponse(responseAutenticate);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      setMessageError(error.message);
      setResponse(undefined);
    }
  };

  const onSubmit = async (data: any) => {
    await handleChangePassword(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Cambiar Contraseña</TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name={"password"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                variant="standard"
                autoComplete="password"
                {...register("password")}
                error={errors.password && Boolean(errors.password)}
                helperText={errors.password && errors.password.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name={"repeatPassword"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Confirmar Contraseña"
                type={showPassword ? "text" : "password"}
                variant="standard"
                autoComplete="repeatPassword"
                {...register("repeatPassword")}
                error={errors.repeatPassword && Boolean(errors.repeatPassword)}
                helperText={
                  errors.repeatPassword && errors.repeatPassword.message
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {messageError !== "" && (
        <Typography
          variant="body1"
          color="error"
          align="left"
          sx={{ my: 2, mx: "auto" }}
        >
          {messageError}
        </Typography>
      )}
      {response && (
        <Typography
          variant="body1"
          color="initial"
          align="center"
          sx={{ my: 2, mx: "auto" }}
        >
          {response.info}
          {/* {response.estatus === 400
            ? response.body.fieldErrors[0]
            : response.body.info} */}
        </Typography>
      )}
      <Stack pt={3} pb={1} justifyContent="center" alignItems="center">
        <ButtonGradient type="submit" fullWidth>
          {loading && (
            <CircularProgress
              size={24}
              sx={{ marginRight: "5px", color: "#6fc555" }}
            />
          )}
          Cambiar Contraseña
        </ButtonGradient>
        <Typography
          align="center"
          sx={{ mt: 2 }}
          variant="body2"
          component={Link}
          to="/"
        >
          Iniciar sesión
        </Typography>
      </Stack>
    </form>
  );
};

export default ForgotPassword;
