import { useState, FC, useLayoutEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import axios from "axios";
import { apis } from "../../../apis/apis";

const ActiveRegister: FC = () => {
  const { code, username } = useParams();
  const [validateUser, setValidateUser] = useState(false);
  const [message, setMessage] = useState("");

  const activeUser = async () => {
    try {
      const response = await axios.get(
        `${apis.user.activate}?code=${code}&username=${username}`
      );
      if (response) {
        setValidateUser(true);
      }
    } catch (e: any) {
      console.log(e.message);
      setMessage(e.response.data.message);
    }
  };

  useLayoutEffect(() => {
    activeUser();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack justifyContent="center" alignItems="center" p={2}>
      {validateUser ? (
        <>
          <Typography variant="body1" align="center" sx={{ mb: 3 }}>
            ¡Ha autenticado y activado su cuenta correctamente!
          </Typography>
          <ButtonGradient fullWidth component={Link} to="/">
            Iniciar sesión
          </ButtonGradient>
        </>
      ) : (
        <>
          <TitleForm>¡Ha ocurrido un error!</TitleForm>
          <Typography variant="subtitle1" color="success.main">
            {message}
          </Typography>
        </>
      )}
    </Stack>
  );
};

export default ActiveRegister;
