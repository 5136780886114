import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as instagram } from "../../assets/icons/instagram.svg";
import { ReactComponent as linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as altio } from "../../assets/logos/altio.svg";
import { ReactComponent as logo } from "../../assets/logos/logo-altio-horizontal.svg";

export const FacebookIcon = () => {
  return (
    <SvgIcon
      component={facebook}
      viewBox="0 0 15.7 30.36"
      color="inherit"
      fontSize="small"
    />
  );
};
export const InstagramIcon = () => {
  return (
    <SvgIcon
      component={instagram}
      viewBox="0 0 32.31 32.25"
      color="inherit"
      fontSize="small"
    />
  );
};
export const LinkedinIcon = () => {
  return (
    <SvgIcon
      component={linkedin}
      viewBox="0 0 31.4 31.33"
      color="inherit"
      fontSize="small"
    />
  );
};
export const TwitterIcon = () => {
  return (
    <SvgIcon
      component={twitter}
      viewBox="0 0 35.18 28.59"
      color="inherit"
      fontSize="small"
    />
  );
};
export const Altio = () => {
  return (
    <SvgIcon
      component={logo}
      viewBox="0 0 543.59 167.47"
      sx={{ fontSize: "4rem", width: 200, mb: 4 }}
    />
  );
};
export const AltioLogo = () => {
  return (
    <SvgIcon
      component={logo}
      viewBox="0 0 543.59 167.47"
      sx={{
        fontSize: "4rem",
        width: 200,
        maxWidth: { xs: "90px", sm: "25vw" },
      }}
    />
  );
};
