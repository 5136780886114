import { CONSTANT } from "../utils/Constants";
import { basesURL } from "../utils/UrlConstant";

//let documents = process.env.REACT_APP_API_DOCUMENTS;

let user = "user/";
let profile = "profile/";

const publicFetch = basesURL.app_base_url + basesURL.api_context;

export const URL = {
  USER_CRUD: CONSTANT.DISPATCHER_USER_PREFIX + "user/",
};

export const apis = {
  user: {
    CRUD: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}`,
    create: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}createForm`,
    login: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }login/twofactor`,
    autenticate: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }autenticate`,
    activate: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}activate`,
    recoverPassword: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }recoverPasswordForm`,
    changePassword: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }changePassword`,
    resendRecoverPassword: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }resend-notification`,
  },
  investmentProfile: {
    CRUD: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile}`,
    update: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile
    }update`,
    calculate: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile
    }calculate/`,
    kyc: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/formulario/`,
    perfil: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/perfil/`,
    notification: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }notification/`,
    accountRegister: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/account-register/`,
    authorizedSignatures: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/authorized-signatures/`,
    questionnaireProfile: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/questionnaire-profile/`,
  },
  maintenance: {
    allData: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}allData/`,
    provinces: `${
      publicFetch + CONSTANT.DISPATCHER_MAINTENANCE
    }provinces/?size=100`,
    municipality: `${
      publicFetch + CONSTANT.DISPATCHER_MAINTENANCE
    }municipality/`,
    sector: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}sectors/`,
  },

  documents: {
    categoryDocuments:
      publicFetch +
      CONSTANT.DISPATCHER_DOCUMENTS +
      `documents/category?page=0&size=10`,
    deleteDocuments:
      publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + `documents/delete`,
    downloadDocuments:
      publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + `documents/download`,
    viewdDocuments:
      publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + `documents/view`,
    uploadDocuments:
      publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + `documents/upload`,
    documentsByClient: `/documents/documents`,
    sendNotification:
      publicFetch +
      CONSTANT.DISPATCHER_DOCUMENTS +
      `documents/sendNotification`,
  },
};

//https://altio-dispatcher.bitcode-enterprise.dev/v1/documents/documents/sendNotification?clientId=5&promoterId=1

//https://altio-dispatcher.bitcode-enterprise.dev/v1/documents//documents/sendNotification?clientId=76&promoterId=9
