import localforage from "localforage";
import jwt_decode from "jwt-decode";
//import { SVSessionValidateRequest } from "../services/security/SecurityService";
import { getAutenticate } from "../services/Services";

/**
 * Set Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const setAuthData = async (keyName: string, authData: any) => {
  try {
    await setItem(keyName, authData);
    const value: any = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

/**
 * Get Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */

export const getAuthData = async () => {
  return await getItem("authAltioInfo");
};

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 * @author lferrer
 */
export const setItem = async (key: string, value: any) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion");
  });
};

/**
 * busca un item al store
 *
 * @param key
 * @author lferrer
 */
export const getItem = async (key: any) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion");
  });
};

/**
 * Elimina un item al store
 *
 * @param key
 * @author lferrer
 */
export const removeItem = async (key: any) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion");
  });
};
