import { FC, useState, useEffect } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Grid,
  TextField,
  Autocomplete,
  Stack,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Typography,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { validationAdditionalLegalInformation } from "./validation";
import { AdditionalLegalInformationType } from "../../types/types";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";

const AdditionalLegalInformation: FC = () => {
  const [loadingSendData, setLoadingSendData] = useState(false);
  const { dataForm, completeData, loadDataServer, data, dataJ, address } =
    useProfile();
  const {
    setActiveStep,
    step3,
    setStep3,
    step3Answered,
    handleBack,
    handleNext,
  } = useLegalForm();

  let findData: any = null;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step3;
  }

  const countryASC =
    data &&
    data.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const defaultValues: AdditionalLegalInformationType = {
    main_suppliers_entity: (findData.main_suppliers_entity &&
      JSON.parse(findData.main_suppliers_entity)) ?? [
      {
        social_name: "",
        identification_number: "",
        economic_sector: null,
        other_economic_sector: "",
        contact: "",
      },
    ],
    information_shareholders: (findData.information_shareholders &&
      JSON.parse(findData.information_shareholders)) ?? [
      {
        social_name: "",
        identification_number: "",
        percentage_stake: "",
        nationality_incorporation: null,
        US_nationality_or_residence: null,
      },
    ],
    members_management_body: (findData.members_management_body &&
      JSON.parse(findData.members_management_body)) ?? [
      {
        social_name: "",
        identification_number: "",
        position: "",
        nationality: null,
        US_nationality_or_residence: null,
      },
    ],
    manages_public_resources: findData.manages_public_resources
      ? datos.selectBoolean.find(
          (f: any) => f.id === findData.manages_public_resources
        )
      : null,
    includes_shareholders_or_partners:
      findData.includes_shareholders_or_partners
        ? datos.selectBoolean.find(
            (f: any) => f.id === findData.includes_shareholders_or_partners
          )
        : null,
    politically_exposed_person_members:
      (findData.politically_exposed_person_members &&
        JSON.parse(findData.politically_exposed_person_members)) ?? [
        {
          name: "",
          identification_number: "",
          institution_name: "",
          position: "",
          appointment_date: null,
          removal_date: null,
        },
      ],
    email1_correspondence: findData.email1_correspondence
      ? findData.email1_correspondence
      : "",
    email2_correspondence: findData.email2_correspondence
      ? findData.email2_correspondence
      : "",
    bank_accounts_register: (findData.bank_accounts_register &&
      JSON.parse(findData.bank_accounts_register)) ?? [
      {
        account_holder: "",
        entity_account: null,
        account_type: null,
        account_number: "",
        currency_type: null,
      },
    ],
    legal_representative: (findData.legal_representative &&
      JSON.parse(findData.legal_representative)) ?? [
      {
        name: "",
        id_type: null,
        document_number: "",
        issuing_country: null,
        date_of_birth: null,
        country_of_birth: null,
        UUEE_nationality: null,
        country: null,
        province: null,
        sector: null,
        address: "",
        building_name: "",
        floor_number: "",
        postal_code: "",
        residential_phone: "",
        office_phone: "",
        cellphone: "",
        email: "",
      },
    ],
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    getValues,
    watch,
    reset,
  } = useForm<AdditionalLegalInformationType>({
    defaultValues,
    resolver: yupResolver(validationAdditionalLegalInformation),
  });
  const { errors, isDirty, dirtyFields } = formState;
  const watch_politically_exposed_person: any = watch(
    "includes_shareholders_or_partners"
  );
  const watch_manages_public_resources: any = watch("manages_public_resources");
  const watch_main_suppliers_entity: any = watch("main_suppliers_entity");
  const watch_UUEE_nationality: any = watch(
    `legal_representative.${0}.UUEE_nationality`
  );
  const watch_UUEE_nationality1: any = watch(
    `legal_representative.${1}.UUEE_nationality`
  );
  const watch_country_0: any = watch(`legal_representative.${0}.country`);
  const watch_country_1: any = watch(`legal_representative.${1}.country`);
  const watch_province: any = watch(`legal_representative.${0}.province`);
  const watch_province1: any = watch(`legal_representative.${1}.province`);
  const watch_legal_representative = watch("legal_representative");

  const {
    fields: main_suppliers_entityFields,
    append: main_suppliers_entityAppend,
    remove: main_suppliers_entityRemove,
  } = useFieldArray({
    control,
    name: "main_suppliers_entity",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: information_shareholdersFields,
    append: information_shareholdersAppend,
    remove: information_shareholdersRemove,
  } = useFieldArray({
    control,
    name: "information_shareholders",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: members_management_bodyFields,
    append: members_management_bodyAppend,
    remove: members_management_bodyRemove,
  } = useFieldArray({
    control,
    name: "members_management_body",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: politically_exposed_person_membersFields,
    append: politically_exposed_person_membersAppend,
    remove: politically_exposed_person_membersRemove,
  } = useFieldArray({
    control,
    name: "politically_exposed_person_members",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: bank_accounts_registerFields,
    append: bank_accounts_registerAppend,
    remove: bank_accounts_registerRemove,
  } = useFieldArray({
    control,
    name: "bank_accounts_register",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: legal_representativeFields,
    append: legal_representativeAppend,
    remove: legal_representativeRemove,
  } = useFieldArray({
    control,
    name: "legal_representative",
    rules: {
      minLength: 1,
    },
  });

  const miarray: any = [];

  useEffect(() => {
    reset(defaultValues);
  }, [dataForm]);

  useEffect(() => {
    if (
      dataForm !== undefined &&
      (dirtyFields.includes_shareholders_or_partners ||
        dirtyFields.manages_public_resources)
    ) {
      resetField("politically_exposed_person_members", {
        defaultValue: [
          {
            name: "",
            identification_number: "",
            institution_name: "",
            position: "",
            appointment_date: null,
            removal_date: null,
          },
        ],
      });
    } else if (
      watch_politically_exposed_person?.id !== "1" ||
      watch_manages_public_resources?.id !== "1"
    ) {
      resetField("politically_exposed_person_members");
    }
  }, [watch_politically_exposed_person, watch_manages_public_resources]);

  useEffect(() => {
    if (dataForm !== undefined && dirtyFields.main_suppliers_entity) {
      if (watch_main_suppliers_entity?.[0]?.economic_sector?.id === "15") {
        resetField(`main_suppliers_entity.${0}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_suppliers_entity?.[1]?.economic_sector?.id === "15") {
        resetField(`main_suppliers_entity.${1}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_suppliers_entity?.[2]?.economic_sector?.id === "15") {
        resetField(`main_suppliers_entity.${2}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_suppliers_entity?.[3]?.economic_sector?.id === "15") {
        resetField(`main_suppliers_entity.${3}.other_economic_sector`, {
          defaultValue: "",
        });
      }
      if (watch_main_suppliers_entity?.[4]?.economic_sector?.id === "15") {
        resetField(`main_suppliers_entity.${4}.other_economic_sector`, {
          defaultValue: "",
        });
      }
    }
  }, [watch_main_suppliers_entity]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[0]?.country) {
      resetField(`legal_representative.${0}.province`, { defaultValue: [] });
      resetField(`legal_representative.${0}.sector`, { defaultValue: [] });
    } else if (watch_country_0?.id !== 6) {
      resetField(`legal_representative.${0}.province`);
      resetField(`legal_representative.${0}.sector`);
    }
  }, [watch_country_0]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[1]?.country) {
      resetField(`legal_representative.${1}.province`, { defaultValue: [] });
      resetField(`legal_representative.${1}.sector`, { defaultValue: [] });
    } else if (watch_country_1?.id !== 6) {
      resetField(`legal_representative.${1}.province`);
      resetField(`legal_representative.${1}.sector`);
    }
  }, [watch_country_1]);

  useEffect(() => {
    if (completeData === false) {
      /* if (
        findData !== null &&
        `${dirtyFields}.legal_representative.${0}.province`
      ) { */
      if (
        findData !== null &&
        dirtyFields?.legal_representative?.[0].province
      ) {
        resetField(`legal_representative.${0}.sector`, { defaultValue: [] });
      } else {
        resetField(`legal_representative.${0}.sector`);
      }
    }
  }, [watch_province]);

  useEffect(() => {
    if (findData !== null && dirtyFields?.legal_representative?.[1]?.province) {
      resetField(`legal_representative.${1}.sector`, { defaultValue: [] });
    } else {
      resetField(`legal_representative.${1}.sector`);
    }
  }, [watch_province1]);

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id ? value.id : JSON.stringify(value);
      }
    }
    data.step_three_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]: JSON.stringify(value !== null && value.id ? value.id : value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep3 && setStep3(data);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_three_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
        },
        formType: "J",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Información Adicional",
      };
      localStorage.setItem("stepForm", "Información Adicional");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        handleResponse();
      }
    } else {
      if (isDirty) {
        let fullUpData = {
          fields: {
            ...update,
          },
          formType: "J",
          userId: sessionStorage.getItem("user_id"),
          stepForm: localStorage.getItem("stepForm"),
        };
        const updateData = await updateProfile(fullUpData);
        if (updateData.status === 200) {
          handleResponse();
        }
      } else {
        setLoadingSendData(false);
        setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Datos de Accionistas, Proveedores y Socios </TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales proveedores en la entidad
      </TitleForm>
      {main_suppliers_entityFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_suppliers_entity.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_suppliers_entity.${index}.social_name` as const
                      )}
                      error={
                        errors?.main_suppliers_entity?.[index]?.social_name &&
                        true
                      }
                      helperText={
                        errors?.main_suppliers_entity?.[index]?.social_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`main_suppliers_entity.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de identificación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_suppliers_entity.${index}.identification_number` as const
                      )}
                      error={
                        errors?.main_suppliers_entity?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.main_suppliers_entity?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`main_suppliers_entity.${index}.economic_sector`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.economic_activity}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sector económico"
                          variant="standard"
                          error={
                            errors?.main_suppliers_entity?.[index]
                              ?.economic_sector && true
                          }
                          helperText={
                            errors?.main_suppliers_entity?.[index]
                              ?.economic_sector?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch_main_suppliers_entity?.[index].economic_sector?.id ===
                "15" && (
                <Grid item sm={6} xs={12}>
                  <Controller
                    name={`main_suppliers_entity.${index}.other_economic_sector`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Otro sector económico"
                        type="text"
                        variant="standard"
                        value={value}
                        disabled={completeData}
                        {...register(
                          `main_suppliers_entity.${index}.other_economic_sector` as const
                        )}
                        error={
                          errors?.main_suppliers_entity?.[index]
                            ?.other_economic_sector && true
                        }
                        helperText={
                          errors?.main_suppliers_entity?.[index]
                            ?.other_economic_sector?.message
                        }
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item sm={6} xs={11} sx={{ display: "flex" }}>
                <Controller
                  name={`main_suppliers_entity.${index}.contact`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Contacto"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `main_suppliers_entity.${index}.contact` as const
                      )}
                      error={
                        errors?.main_suppliers_entity?.[index]?.contact && true
                      }
                      helperText={
                        errors?.main_suppliers_entity?.[index]?.contact?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      main_suppliers_entityRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {main_suppliers_entityFields.length < 3 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              main_suppliers_entityAppend({
                social_name: "",
                identification_number: "",
                economic_sector: null,
                other_economic_sector: "",
                contact: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar proveedor
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información sobre los accionistas
      </TitleForm>
      <Typography variant="body2" sx={{ mt: 5, mb: 3 }}>
        * Favor listar todos los accionistas con participación mayor o igual al
        20%. En los casos de accionistas que sean personas jurídicas se deberá
        listar los mismos hasta llegar a la persona física con participación
        mayor o igual al 20%. Puede utilizar el anexo de este formulario hasta
        completar la información.
      </Typography>
      {information_shareholdersFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`information_shareholders.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `information_shareholders.${index}.social_name` as const
                      )}
                      error={
                        errors?.information_shareholders?.[index]
                          ?.social_name && true
                      }
                      helperText={
                        errors?.information_shareholders?.[index]?.social_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`information_shareholders.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de identificación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `information_shareholders.${index}.identification_number` as const
                      )}
                      error={
                        errors?.information_shareholders?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.information_shareholders?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`information_shareholders.${index}.percentage_stake`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Porcentaje de participación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `information_shareholders.${index}.percentage_stake` as const
                      )}
                      error={
                        errors?.information_shareholders?.[index]
                          ?.percentage_stake && true
                      }
                      helperText={
                        errors?.information_shareholders?.[index]
                          ?.percentage_stake?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`information_shareholders.${index}.nationality_incorporation`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && data.al_ta_countries}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nacionalidad o lugar de incorporación"
                          variant="standard"
                          error={
                            errors?.information_shareholders?.[index]
                              ?.nationality_incorporation && true
                          }
                          helperText={
                            errors?.information_shareholders?.[index]
                              ?.nationality_incorporation?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={11} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`information_shareholders.${index}.US_nationality_or_residence`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.selectBoolean}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Posee nacionalidad o residencia en EEUU"
                          variant="standard"
                          error={
                            errors?.information_shareholders?.[index]
                              ?.US_nationality_or_residence && true
                          }
                          helperText={
                            errors?.information_shareholders?.[index]
                              ?.US_nationality_or_residence?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      information_shareholdersRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {information_shareholdersFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              information_shareholdersAppend({
                social_name: "",
                identification_number: "",
                percentage_stake: "",
                nationality_incorporation: null,
                US_nationality_or_residence: null,
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
            accionistas
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Miembros del órgano de administración
      </TitleForm>
      {members_management_bodyFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`members_management_body.${index}.social_name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `members_management_body.${index}.social_name` as const
                      )}
                      error={
                        errors?.members_management_body?.[index]?.social_name &&
                        true
                      }
                      helperText={
                        errors?.members_management_body?.[index]?.social_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`members_management_body.${index}.identification_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de identificación"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `members_management_body.${index}.identification_number` as const
                      )}
                      error={
                        errors?.members_management_body?.[index]
                          ?.identification_number && true
                      }
                      helperText={
                        errors?.members_management_body?.[index]
                          ?.identification_number?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`members_management_body.${index}.position`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Cargo"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `members_management_body.${index}.position` as const
                      )}
                      error={
                        errors?.members_management_body?.[index]?.position &&
                        true
                      }
                      helperText={
                        errors?.members_management_body?.[index]?.position
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`members_management_body.${index}.nationality`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={data && data.al_ta_countries}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nacionalidad"
                          variant="standard"
                          error={
                            errors?.members_management_body?.[index]
                              ?.nationality && true
                          }
                          helperText={
                            errors?.members_management_body?.[index]
                              ?.nationality?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={11} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`members_management_body.${index}.US_nationality_or_residence`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.selectBoolean}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Posee nacionalidad o residencia en EEUU"
                          variant="standard"
                          error={
                            errors?.members_management_body?.[index]
                              ?.US_nationality_or_residence && true
                          }
                          helperText={
                            errors?.members_management_body?.[index]
                              ?.US_nationality_or_residence?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      members_management_bodyRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {members_management_bodyFields.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              members_management_bodyAppend({
                social_name: "",
                identification_number: "",
                position: "",
                nationality: null,
                US_nationality_or_residence: null,
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar miembro
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Participación en la administración pública
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="manages_public_resources"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿La entidad administra recursos públicos?"
                      variant="standard"
                      error={!!errors.manages_public_resources}
                      helperText={
                        errors.manages_public_resources &&
                        errors.manages_public_resources.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="includes_shareholders_or_partners"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => {
                  const inputProps = params.inputProps;
                  inputProps.autoComplete = "new-password";
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Alguno de los accionistas, socios, beneficiarios finales, miembros del órgano de administración o ejecutivos es una persona expuesta políticamente?"
                      variant="standard"
                      error={!!errors.includes_shareholders_or_partners}
                      helperText={
                        errors.includes_shareholders_or_partners &&
                        errors.includes_shareholders_or_partners.message
                      }
                    />
                  );
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {(watch_politically_exposed_person?.id === "1" ||
        watch_manages_public_resources?.id === "1") && (
        <>
          <Typography variant="body2" sx={{ mt: 5, mb: 3 }}>
            La ley contra el lavado de activos y el financiamiento del
            terrorismo (ley 155-17 califica como persona expuesta políticamente
            a: "cualquier individuo que desempeña o ha desempeñado, durante los
            últimos tres (3) años, altas funciones públicas, por elección o
            nombramientos ejecutivos, en un país extranjero o en territorio
            nacional, incluyendo altos funcionarios de organizaciones
            internacionales. Incluye, pero no se limita a, jefes de estado o de
            gobierno, funcionarios gubernamentales, judiciales o militares de
            alta jerarquía, altos ejecutivos de empresas estatales o
            funcionarios, así como aquellos que determine el comité nacional de
            lavado de activos previa consulta con el ministerio de la
            administración pública. Los cargos considerados PEP serán todos
            aquellos funcionarios obligados a presentar declaración jurada de
            bienes. Se asimilan todas aquellas personas que hayan desempeñado o
            desempeñen estas funciones o su equivalente para gobiernos
            extranjeros.
          </Typography>
          <Typography variant="body2" sx={{ mb: 3, fontWeight: 700 }}>
            Si la respuesta a una o más de las preguntas anteriores es “sí”,
            complete lo siguiente
          </Typography>
          <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
            Datos de la persona expuesta políticamente
          </TitleForm>
          {politically_exposed_person_membersFields.map((field, index) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 3,
                      borderColor: "#efe5df",
                      width: "50%",
                      ml: "auto",
                      mr: "auto",
                    }}
                  />
                )}
                <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`politically_exposed_person_members.${index}.name`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombres / Denominación social"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `politically_exposed_person_members.${index}.name` as const
                          )}
                          error={
                            errors?.politically_exposed_person_members?.[index]
                              ?.name && true
                          }
                          helperText={
                            errors?.politically_exposed_person_members?.[index]
                              ?.name?.message
                          }
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`politically_exposed_person_members.${index}.identification_number`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Número de identificación"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `politically_exposed_person_members.${index}.identification_number` as const
                          )}
                          error={
                            errors?.politically_exposed_person_members?.[index]
                              ?.identification_number && true
                          }
                          helperText={
                            errors?.politically_exposed_person_members?.[index]
                              ?.identification_number?.message
                          }
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`politically_exposed_person_members.${index}.institution_name`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombre institución"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `politically_exposed_person_members.${index}.institution_name` as const
                          )}
                          error={
                            errors?.politically_exposed_person_members?.[index]
                              ?.institution_name && true
                          }
                          helperText={
                            errors?.politically_exposed_person_members?.[index]
                              ?.institution_name?.message
                          }
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`politically_exposed_person_members.${index}.position`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Cargo"
                          type="text"
                          variant="standard"
                          disabled={completeData}
                          {...register(
                            `politically_exposed_person_members.${index}.position` as const
                          )}
                          error={
                            errors?.politically_exposed_person_members?.[index]
                              ?.position && true
                          }
                          helperText={
                            errors?.politically_exposed_person_members?.[index]
                              ?.position?.message
                          }
                          inputProps={{
                            autoComplete: "new-password",
                            form: {
                              autoComplete: "off",
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <Controller
                      name={`politically_exposed_person_members.${index}.appointment_date`}
                      control={control}
                      defaultValue={null}
                      render={({ field, ...props }) => (
                        <LocalizationProvider
                          dateAdapter={DateFnsUtils}
                          adapterLocale={esLocale}
                        >
                          <DatePicker
                            value={field.value}
                            disabled={completeData}
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            label="Fecha de Designación"
                            minDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 10
                                )
                              )
                            }
                            maxDate={new Date()}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                          {errors?.politically_exposed_person_members?.[index]
                            ?.appointment_date && (
                            <FormHelperText>
                              {
                                errors?.politically_exposed_person_members?.[
                                  index
                                ]?.appointment_date?.message
                              }
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={11}
                    sx={
                      index !== 0 && !completeData
                        ? { position: "relative", paddingRight: "60px" }
                        : { position: "relative" }
                    }
                  >
                    <Controller
                      name={`politically_exposed_person_members.${index}.removal_date`}
                      control={control}
                      defaultValue={null}
                      render={({ field, ...props }) => (
                        <LocalizationProvider
                          dateAdapter={DateFnsUtils}
                          adapterLocale={esLocale}
                        >
                          <DatePicker
                            value={field.value}
                            disabled={completeData}
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            label="Fecha de Termino"
                            minDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() - 10
                                )
                              )
                            }
                            maxDate={
                              new Date(
                                new Date().setFullYear(
                                  new Date().getFullYear() + 5
                                )
                              )
                            }
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                variant="standard"
                                {...params}
                              />
                            )}
                          />
                          {errors?.politically_exposed_person_members?.[index]
                            ?.removal_date && (
                            <FormHelperText>
                              {
                                errors?.politically_exposed_person_members?.[
                                  index
                                ]?.removal_date?.message
                              }
                            </FormHelperText>
                          )}
                        </LocalizationProvider>
                      )}
                    />
                    {index !== 0 && !completeData && (
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        onClick={() => {
                          politically_exposed_person_membersRemove(index);
                        }}
                        sx={{ position: "absolute", right: "0", top: "40px" }}
                      >
                        <RemoveCircleIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </div>
            );
          })}
          {politically_exposed_person_membersFields.length < 5 &&
            !completeData && (
              <Grid
                container
                justifyContent="flex-end"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  onClick={() => {
                    politically_exposed_person_membersAppend({
                      name: "",
                      identification_number: "",
                      institution_name: "",
                      position: "",
                      appointment_date: new Date(),
                      removal_date: null,
                    });
                  }}
                >
                  <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
                  miembro
                </Button>
              </Grid>
            )}
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
        Información representante legal o apoderados
      </TitleForm>
      {legal_representativeFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.name`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres y Apellidos"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="name"
                      {...register(`legal_representative.${index}.name`)}
                      error={
                        errors?.legal_representative?.[index]?.name && true
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.name?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`legal_representative.${index}.id_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={datos.id_type}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de Identificación"
                          variant="standard"
                          error={
                            errors?.legal_representative?.[index]?.id_type &&
                            true
                          }
                          helperText={
                            errors?.legal_representative?.[index]?.id_type
                              ?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.document_number`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número Documento"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="document_number"
                      {...register(
                        `legal_representative.${index}.document_number`
                      )}
                      error={
                        errors?.legal_representative?.[index]
                          ?.document_number && true
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.document_number
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`legal_representative.${index}.issuing_country`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="País emisor del documento"
                          variant="standard"
                          error={
                            errors?.legal_representative?.[index]
                              ?.issuing_country && true
                          }
                          helperText={
                            errors?.legal_representative?.[index]
                              ?.issuing_country?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.date_of_birth`}
                  control={control}
                  defaultValue={null}
                  render={({ field, ...props }) => (
                    <LocalizationProvider
                      dateAdapter={DateFnsUtils}
                      adapterLocale={esLocale}
                    >
                      <DatePicker
                        value={field.value}
                        disabled={completeData}
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        label="Fecha de Nacimiento"
                        minDate={
                          new Date(
                            new Date().setFullYear(
                              new Date().getFullYear() - 100
                            )
                          )
                        }
                        className={
                          errors?.legal_representative?.[index]
                            ?.date_of_birth && "Mui-error"
                        }
                        maxDate={new Date()}
                        renderInput={(params) => (
                          <TextField fullWidth variant="standard" {...params} />
                        )}
                      />
                      {errors?.legal_representative?.[index]?.date_of_birth && (
                        <FormHelperText>
                          {
                            errors?.legal_representative?.[index]?.date_of_birth
                              ?.message
                          }
                        </FormHelperText>
                      )}
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`legal_representative.${index}.country_of_birth`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="País de Nacimiento"
                          variant="standard"
                          error={
                            errors?.legal_representative?.[index]
                              ?.country_of_birth && true
                          }
                          helperText={
                            errors?.legal_representative?.[index]
                              ?.country_of_birth?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`legal_representative.${index}.UUEE_nationality`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={datos.selectBoolean}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="¿Posee usted nacionalidad o residencia permanente (Green Card) de los EEUU?"
                          variant="standard"
                          error={
                            !!errors?.legal_representative?.[index]
                              ?.UUEE_nationality
                          }
                          helperText={
                            errors?.legal_representative?.[index]
                              ?.UUEE_nationality?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              {watch_UUEE_nationality?.id === "1" && (
                <Grid item xs={12}>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    * Si respondió que “sí” a la pregunta anterior, deberá
                    completar el formulario w9-.
                  </Typography>
                </Grid>
              )}
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`legal_representative.${index}.country`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        onChange(newValue);
                      }}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => {
                        const inputProps = params.inputProps;
                        inputProps.autoComplete = "new-password";
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="País de Residencia"
                            variant="standard"
                            error={
                              !!errors?.legal_representative?.[index]?.country
                            }
                            helperText={
                              errors?.legal_representative?.[index]?.country
                                ?.message
                            }
                          />
                        );
                      }}
                    />
                  )}
                />
              </Grid>

              {
                //@ts-ignore
                Number(watch_legal_representative[index].country?.id) ===
                  63 && (
                  <>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.province`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={address.provinces}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.provinceCode === value.provinceCode
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => {
                              const inputProps = params.inputProps;
                              inputProps.autoComplete = "new-password";
                              return (
                                <TextField
                                  {...params}
                                  fullWidth
                                  label="Provincia"
                                  variant="standard"
                                  error={
                                    !!errors?.legal_representative?.[index]
                                      ?.province
                                  }
                                  helperText={
                                    errors?.legal_representative?.[index]
                                      ?.province && "Campo es requerido"
                                  }
                                />
                              );
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <Controller
                        control={control}
                        name={`legal_representative.${index}.sector`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            onChange={(event, newValue: any) => {
                              onChange(newValue);
                            }}
                            options={address.sector.filter(
                              (m: any) =>
                                m.provinceCode ===
                                watch_legal_representative[index]?.province
                                  ?.provinceCode
                            )}
                            getOptionLabel={(option: any) =>
                              option.description || ""
                            }
                            isOptionEqualToValue={(option: any, value: any) =>
                              option.id === value.id
                            }
                            value={value}
                            disabled={completeData}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label="Sector"
                                variant="standard"
                                error={
                                  !!errors?.legal_representative?.[index]
                                    ?.sector
                                }
                                helperText={
                                  !!errors?.legal_representative?.[index]
                                    ?.sector && "Campo es requerido"
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )
              }
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.address`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Dirección (Calle /  Número)"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="address"
                      {...register(`legal_representative.${index}.address`)}
                      error={!!errors?.legal_representative?.[index]?.address}
                      helperText={
                        errors?.legal_representative?.[index]?.address?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.building_name`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre Edificio / Torre"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="building_name"
                      {...register(
                        `legal_representative.${index}.building_name`
                      )}
                      error={
                        !!errors?.legal_representative?.[index]?.building_name
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.building_name
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.floor_number`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="N° Piso / Suite"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="floor_number"
                      {...register(
                        `legal_representative.${index}.floor_number`
                      )}
                      error={
                        !!errors?.legal_representative?.[index]?.floor_number
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.floor_number
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.postal_code`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Código postal"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="postal_code"
                      {...register(`legal_representative.${index}.postal_code`)}
                      error={
                        !!errors?.legal_representative?.[index]?.postal_code
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.postal_code
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.residential_phone`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono residencial"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="residential_phone"
                      {...register(
                        `legal_representative.${index}.residential_phone`
                      )}
                      error={
                        !!errors?.legal_representative?.[index]
                          ?.residential_phone
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.residential_phone
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.office_phone`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono de oficina"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="office_phon"
                      {...register(
                        `legal_representative.${index}.office_phone`
                      )}
                      error={
                        !!errors?.legal_representative?.[index]?.office_phone
                      }
                      helperText={
                        errors?.legal_representative?.[index]?.office_phone
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  name={`legal_representative.${index}.cellphone`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Teléfono Móvil"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="cellphone"
                      {...register(`legal_representative.${index}.cellphone`)}
                      error={!!errors?.legal_representative?.[index]?.cellphone}
                      helperText={
                        errors?.legal_representative?.[index]?.cellphone
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`legal_representative.${index}.email`}
                  control={control}
                  render={() => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Correo electrónico"
                      type="email"
                      variant="standard"
                      disabled={completeData}
                      autoComplete="email"
                      {...register(`legal_representative.${index}.email`)}
                      error={!!errors?.legal_representative?.[index]?.email}
                      helperText={
                        errors?.legal_representative?.[index]?.email?.message
                      }
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      legal_representativeRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {legal_representativeFields.length < 2 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              legal_representativeAppend({
                name: "",
                id_type: null,
                document_number: "",
                issuing_country: null,
                date_of_birth: null,
                country_of_birth: null,
                UUEE_nationality: null,
                country: null,
                province: null,
                sector: null,
                address: "",
                building_name: "",
                floor_number: "",
                postal_code: "",
                residential_phone: "",
                office_phone: "",
                cellphone: "",
                email: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
            representante legal
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 7, mb: 4 }}>
        Dirección de envío de correspondencia y comunicaciones
      </TitleForm>
      <Typography variant="body2" sx={{ mt: 5, mb: 2 }}>
        Autorizamos el envío de informes, reportes de estado de cuentas,
        extractos, comprobantes de suscripción o rescate, confirmaciones y ALTIO
        sociedad envío de instrucciones o cualquier documentación de tipo
        comercial o legal, que deba ser suministrada por administradora de
        fondos de inversión.
      </Typography>
      <Grid container spacing={4}>
        <Grid item sm={6} xs={12}>
          <Controller
            name={"email1_correspondence"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Correo electrónico"
                type="email"
                variant="standard"
                disabled={completeData}
                autoComplete="email1_correspondence"
                {...register("email1_correspondence")}
                error={
                  errors.email1_correspondence &&
                  Boolean(errors.email1_correspondence)
                }
                helperText={
                  errors.email1_correspondence &&
                  errors.email1_correspondence.message
                }
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name={"email2_correspondence"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Otro correo electrónico"
                type="email"
                variant="standard"
                disabled={completeData}
                autoComplete="email2_correspondence"
                {...register("email2_correspondence")}
                error={
                  errors.email2_correspondence &&
                  Boolean(errors.email2_correspondence)
                }
                helperText={
                  errors.email2_correspondence &&
                  errors.email2_correspondence.message
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Cuentas bancarias
      </TitleForm>
      {bank_accounts_registerFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_holder`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Titular de la cuenta"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `bank_accounts_register.${index}.account_holder` as const
                      )}
                      error={
                        errors?.bank_accounts_register?.[index]
                          ?.account_holder && true
                      }
                      helperText={
                        errors?.bank_accounts_register?.[index]?.account_holder
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.entity_account`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.entity}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Entidad"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.entity_account && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]
                              ?.entity_account?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.account_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={datos.accountType}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de cuenta"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.account_type && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]
                              ?.account_type?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`bank_accounts_register.${index}.account_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de cuenta"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `bank_accounts_register.${index}.account_number` as const
                      )}
                      error={
                        errors?.bank_accounts_register?.[index]
                          ?.account_number && true
                      }
                      helperText={
                        errors?.bank_accounts_register?.[index]?.account_number
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`bank_accounts_register.${index}.currency_type`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_currency_preference}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Moneda"
                          variant="standard"
                          error={
                            errors?.bank_accounts_register?.[index]
                              ?.currency_type && true
                          }
                          helperText={
                            errors?.bank_accounts_register?.[index]
                              ?.currency_type?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      bank_accounts_registerRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {bank_accounts_registerFields.length < 2 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              bank_accounts_registerAppend({
                account_holder: "",
                entity_account: [],
                account_type: [],
                account_number: "",
                currency_type: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar cuenta
          </Button>
        </Grid>
      )}
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        </Stack>
      )}
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step3Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="submit"
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
    </form>
  );
};

export default AdditionalLegalInformation;
