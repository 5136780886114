import { FC, useState } from "react";
import { Stack, Grid, Typography, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationRecoverPassword } from "../validation";
import { registerTypes } from "../../../types/types";
import { getRecoverPassword } from "../../../services/Services";

const RecoverPassword: FC = () => {
  const [response, setResponse] = useState<any>(undefined);
  const formOptions = { resolver: yupResolver(validationRecoverPassword) };

  const { register, handleSubmit, formState, control } =
    useForm<registerTypes>(formOptions);
  const { errors } = formState;
  const onSubmit = async (data: any) => {
    const userData = {
      username: data.email,
    };

    setResponse(undefined);
    const responseAutenticate = await getRecoverPassword(userData);
    setResponse(responseAutenticate);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <TitleForm>Cambiar Contraseña</TitleForm>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Controller
            name={"email"}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Email"
                type="email"
                variant="standard"
                autoComplete="email"
                {...register("email")}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
      </Grid>
      {response && (
        <Typography
          variant="body1"
          color="initial"
          align="center"
          sx={{ my: 2, mx: "auto" }}
        >
          {response.estatus === 400
            ? response.body.fieldErrors[0]
            : response.body.info}
        </Typography>
      )}
      <Stack pt={3} pb={1} justifyContent="center" alignItems="center">
        <ButtonGradient type="submit" fullWidth>
          Cambiar Contraseña
        </ButtonGradient>
        <Typography
          align="center"
          sx={{ mt: 2 }}
          variant="body2"
          component={Link}
          to="/"
        >
          Iniciar sesión
        </Typography>
      </Stack>
    </form>
  );
};

export default RecoverPassword;
