import { FC, useState, useEffect } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Grid,
  Typography,
  TextField,
  Autocomplete,
  Stack,
  Divider,
  IconButton,
  Button,
  Tooltip,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  Box,
  Chip,
  ListItemText,
  InputLabel,
  styled,
  Dialog,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import FancyDialog from "../../components/FancyDialog";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationLegalRepresentativeInformationType } from "./validation";
import { LegalRepresentativeInformationType } from "../../types/types";
import { data as datos } from "../../data/data";
import { updateProfile } from "../../services/Services";
import InputLabelToForm from "../../components/InputLabelToForm";

const LabelForm = styled(InputLabel)(({ theme }) => ({
  left: "-14px",
}));

const LegalRepresentativeInformation: FC = () => {
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const {
    dataForm,
    completeData,
    loadDataServer,
    getInvestmentProfile,
    data,
    address,
    dataJ,
  } = useProfile();
  const {
    setActiveStep,
    step4,
    setStep4,
    step4Answered,
    handleBack,
    handleNext,
  } = useLegalForm();

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = step4;
  }

  const countryASC =
    data &&
    data.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1;
      }
    });

  const defaultValues: LegalRepresentativeInformationType = {
    ability_absorb_losses: findData.ability_absorb_losses
      ? dataJ &&
        dataJ.al_ta_ability_absorb_losses.find(
          (f: any) => f.id === Number(findData.ability_absorb_losses)
        )
      : [],
    risk_tolerance: findData.risk_tolerance
      ? dataJ &&
        dataJ.al_ta_risk_tolerance.find(
          (f: any) => f.id === Number(findData.risk_tolerance)
        )
      : [],
    desired_profitability: findData.desired_profitability
      ? dataJ &&
        dataJ.al_ta_desired_profitability.find(
          (f: any) => f.id === Number(findData.desired_profitability)
        )
      : [],
    investment_time_horizon: findData.investment_time_horizon
      ? dataJ &&
        dataJ.al_ta_investment_time_horizon.find(
          (f: any) => f.id === Number(findData.investment_time_horizon)
        )
      : [],
    channel_catchment_client: findData.channel_catchment_client
      ? data &&
        data.al_ta_channel_catchment_client.find(
          (f: any) => f.id === Number(findData.channel_catchment_client)
        )
      : [],
    notHaveExperience: findData.notHaveExperience
      ? findData.notHaveExperience
      : false,
    fixedIncomeSecurities: findData.fixedIncomeSecurities
      ? JSON.parse(findData.fixedIncomeSecurities)
      : [],
    investmentFunds: findData.investmentFunds
      ? JSON.parse(findData.investmentFunds)
      : [],
    liquidityOperations: findData.liquidityOperations
      ? JSON.parse(findData.liquidityOperations)
      : [],
    variableIncomeSecurities: findData.variableIncomeSecurities
      ? JSON.parse(findData.variableIncomeSecurities)
      : [],
    otherServices: findData.otherServices
      ? JSON.parse(findData.otherServices)
      : [],
    knowledge_market_values: findData.knowledge_market_values
      ? dataJ.al_ta_knowledge_market_values.find(
          (f: any) => f.id === Number(findData.knowledge_market_values)
        )
      : null,
    information_media: findData.information_media
      ? dataJ.al_ta_information_media.find(
          (f: any) => f.id === Number(findData.information_media)
        )
      : null,
    professionalInvestor: findData.professionalInvestor
      ? dataJ.al_ta_professional_investor.find(
          (f: any) => f.id === Number(findData.professionalInvestor)
        )
      : null,
    expcStockMarketLeastThree: findData.expcStockMarketLeastThree
      ? findData.expcStockMarketLeastThree === "true" && true
      : false,
    equityCapacityLeastTwentyFive: findData.equityCapacityLeastTwentyFive
      ? findData.equityCapacityLeastTwentyFive === "true" && true
      : false,
    knowledgeVerified: findData.knowledgeVerified
      ? findData.knowledgeVerified === "true" && true
      : false,

    financialInstrumentsYear: (findData.financialInstrumentsYear &&
      JSON.parse(findData.financialInstrumentsYear)) ?? [
      {
        financialInstruments: null,
        experienceTime: null,
        numTransactionsYear: null,
        levelknowInstRisk: null,
      },
    ],
    social_name_signature: findData.social_name_signature
      ? findData.social_name_signature
      : "",
    instructions_signature: findData.instructions_signature
      ? findData.instructions_signature
      : "",
    authorized_signatures: (findData.authorized_signatures &&
      JSON.parse(findData.authorized_signatures)) ?? [
      {
        name: "",
        document_number: "",
        function: "",
      },
    ],
    liquid_assets_distribution_form:
      (findData.liquid_assets_distribution_form &&
        JSON.parse(findData.liquid_assets_distribution_form)) ?? [
        {
          liquid_assets_distribution_id: [],
          liquid_assets_distribution_description: [],
        },
      ],
  };

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    getValues,
    reset,
    trigger,
  } = useForm<LegalRepresentativeInformationType>({
    defaultValues,
    resolver: yupResolver(validationLegalRepresentativeInformationType),
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const { errors, isDirty, dirtyFields, isValid } = formState;
  const miarray: any = [];

  const {
    fields: financialInstrumentsYearField,
    append: financialInstrumentsYearAppend,
    remove: financialInstrumentsYearRemove,
  } = useFieldArray({
    control,
    name: "financialInstrumentsYear",
    rules: {
      minLength: 3,
    },
  });
  const {
    fields: authorized_signaturesFields,
    append: authorized_signaturesAppend,
    remove: authorized_signaturesRemove,
  } = useFieldArray({
    control,
    name: "authorized_signatures",
    rules: {
      minLength: 1,
    },
  });
  const {
    fields: liquid_assets_distributionField,
    append: liquid_assets_distributionAppend,
    remove: liquid_assets_distributionRemove,
  } = useFieldArray({
    control,
    name: "liquid_assets_distribution_form",
    rules: {
      minLength: 3,
    },
  });
  const [messageConditional, setMessageConditional] = useState(true);

  const watchProfessionalInvestor: any = watch("professionalInvestor");

  useEffect(() => {
    reset(defaultValues);
  }, [dataForm]);

  useEffect(() => {
    if (watchProfessionalInvestor?.id === "1") {
      resetField("expcStockMarketLeastThree");
      resetField("equityCapacityLeastTwentyFive");
      resetField("knowledgeVerified");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProfessionalInvestor]);

  const watchNotHaveExperience: any = watch("notHaveExperience");
  const watchproductFields = watch([
    "investmentFunds",
    "fixedIncomeSecurities",
    "liquidityOperations",
    "variableIncomeSecurities",
    "otherServices",
  ]);

  useEffect(() => {
    if (
      watchproductFields[0].length > 0 ||
      watchproductFields[1].length > 0 ||
      watchproductFields[2].length > 0 ||
      watchproductFields[3].length > 0 ||
      watchproductFields[4].length > 0
    ) {
      setMessageConditional(false);
    } else {
      setMessageConditional(true);
    }
  }, [watchproductFields, watchNotHaveExperience]);

  useEffect(() => {
    if (watchNotHaveExperience) {
      resetField("investmentFunds");
      resetField("fixedIncomeSecurities");
      resetField("liquidityOperations");
      resetField("variableIncomeSecurities");
      resetField("otherServices");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchNotHaveExperience]);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const validateForm = async () => {
    await trigger();
    if (isValid) {
      setOpenDialog(true);
    }
  };

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string | any;
    let value: { id: string; description: string }[] | any = [];

    const asingnumber = {
      fixedIncomeSecurities: 1,
      investmentFunds: 2,
      liquidityOperations: 3,
      variableIncomeSecurities: 4,
      otherServices: 5,
    };
    const op_values_finance_array = [];

    const instruments: any[] = [];
    const time_experience_instrument: any[] = [];
    const number_operations_year: any[] = [];
    const knowledge_level: any[] = [];
    const liquid_assets_distribution: any[] = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] =
          value !== null && value.id
            ? typeof value.id !== "string"
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value);
      }
      if (
        key === "fixedIncomeSecurities" ||
        key === "investmentFunds" ||
        key === "liquidityOperations" ||
        key === "variableIncomeSecurities" ||
        key === "otherServices"
      ) {
        op_values_finance_array.push({
          //@ts-ignore
          [asingnumber[key]]: value,
        });
      }
    }
    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      instruments.push(Number(e.financialInstruments.id));
      time_experience_instrument.push(Number(e.experienceTime.id));
      number_operations_year.push(Number(e.numTransactionsYear.id));
      knowledge_level.push(Number(e.levelknowInstRisk.id));
    });

    const bonos_BCRD_hacienda: any[] = [];
    const fondos_abiertos_y_cerrados: any[] = [];
    const operaciones_a_plazo_forwards: any[] = [];
    const prestamos_a_titulos_de_valores_mutuo: any[] = [];
    const accciones: any[] = [];
    const contratos_de_reportes_repos: any[] = [];
    const bonos_corporativos_papeles_comerciales: any[] = [];
    const derivados_de_cobertura_no_especulativos: any[] = [];
    const prestamos_de_margen: any[] = [];
    const notes: any[] = [];
    const letters: any[] = [];

    let financialInstrumentsName: any = [];
    JSON.parse(data.financialInstrumentsYear).forEach((e: any) => {
      if (Number(e.financialInstruments.id) === 10) {
        bonos_BCRD_hacienda.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 9) {
        fondos_abiertos_y_cerrados.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 14) {
        operaciones_a_plazo_forwards.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 11) {
        prestamos_a_titulos_de_valores_mutuo.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 12) {
        accciones.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 13) {
        contratos_de_reportes_repos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 18) {
        bonos_corporativos_papeles_comerciales.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 19) {
        derivados_de_cobertura_no_especulativos.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 20) {
        prestamos_de_margen.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 23) {
        notes.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
      if (Number(e.financialInstruments.id) === 24) {
        letters.push({
          financialInstruments: e.financialInstruments,
          experienceTime: e.experienceTime,
          numTransactionsYear: e.numTransactionsYear,
          levelknowInstRisk: e.levelknowInstRisk,
        });
      }
    });

    data.bonos_BCRD_hacienda = JSON.stringify(bonos_BCRD_hacienda);
    data.fondos_abiertos_y_cerrados = JSON.stringify(
      fondos_abiertos_y_cerrados
    );
    data.operaciones_a_plazo_forwards = JSON.stringify(
      operaciones_a_plazo_forwards
    );
    data.prestamos_a_titulos_de_valores_mutuo = JSON.stringify(
      prestamos_a_titulos_de_valores_mutuo
    );
    data.accciones = JSON.stringify(accciones);
    data.contratos_de_reportes_repos = JSON.stringify(
      contratos_de_reportes_repos
    );
    data.bonos_corporativos_papeles_comerciales = JSON.stringify(
      bonos_corporativos_papeles_comerciales
    );
    data.derivados_de_cobertura_no_especulativos = JSON.stringify(
      derivados_de_cobertura_no_especulativos
    );
    data.prestamos_de_margen = JSON.stringify(prestamos_de_margen);

    //nuevos instrumentos
    data.notes = JSON.stringify(notes);
    data.letters = JSON.stringify(letters);
    //-----fin nuevos

    JSON.parse(data.liquid_assets_distribution_form).forEach((e: any) => {
      liquid_assets_distribution.push(
        Number(e.liquid_assets_distribution_id.id)
      );
    });

    data.operations_values_finance = JSON.stringify(op_values_finance_array);
    data.instruments = JSON.stringify(instruments);
    data.time_experience_instrument = JSON.stringify(
      time_experience_instrument
    );
    data.number_operations_year = JSON.stringify(number_operations_year);
    data.knowledge_level = JSON.stringify(knowledge_level);
    data.liquid_assets_distribution = JSON.stringify(
      liquid_assets_distribution
    );
    data.step_four_complete = "true";

    let update = {};
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key);
      if (typeof value == "object") {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== "string"
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value),
        });
      } else {
        Object.assign(update, { [key]: value });
      }
    }

    const handleResponse = () => {
      const getProfileUser =
        loadDataServer && loadDataServer(sessionStorage.getItem("user_id"));
      setStep4 && setStep4(data);
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    if (findData.step_four_complete !== "true") {
      setOpenDialog(false);
      let fullData = {
        fields: {
          ...data,
        },
        formType: "J",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Información Representante Legal",
      };
      localStorage.setItem("stepForm", "Información Representante Legal");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        getInvestmentProfile &&
          getInvestmentProfile(sessionStorage.getItem("user_id"));
        setLoadingSendData(false);
        handleResponse();
      }
    } else {
      setLoadingSendData(false);
      setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  return (
    <form>
      <TitleForm sx={{ mb: 4 }}>Perfil del inversionista</TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            control={control}
            name="ability_absorb_losses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ?.al_ta_ability_absorb_losses}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Capacidad de absorber pérdidas de capital"
                    variant="standard"
                    error={!!errors.ability_absorb_losses}
                    helperText={
                      errors.ability_absorb_losses &&
                      errors.ability_absorb_losses.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <Controller
            control={control}
            name="risk_tolerance"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_risk_tolerance}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tolerancia al Riesgo"
                    variant="standard"
                    error={!!errors.risk_tolerance}
                    helperText={
                      errors.risk_tolerance && errors.risk_tolerance.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="desired_profitability"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_desired_profitability}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Rentabilidad Deseada"
                    variant="standard"
                    error={!!errors.desired_profitability}
                    helperText={
                      errors.desired_profitability &&
                      errors.desired_profitability.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_time_horizon"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_investment_time_horizon}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Horizonte de Tiempo de la Inversión"
                    variant="standard"
                    error={!!errors.investment_time_horizon}
                    helperText={
                      errors.investment_time_horizon &&
                      errors.investment_time_horizon.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="channel_catchment_client"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={data.al_ta_channel_catchment_client}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Canal de Captacion del Cliente"
                    variant="standard"
                    error={!!errors.channel_catchment_client}
                    helperText={
                      errors.channel_catchment_client &&
                      errors.channel_catchment_client.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Productos Utilizados o Deseados
      </TitleForm>
      {/* <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="notHaveExperience"
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <FormControlLabel
                control={
                  <Checkbox onChange={onChange} checked={value} {...field} />
                }
                label="No poseo experiencia en ninguno de estos productos"
                disabled={completeData}
              />
            )}
          />
        </Grid>
      </Grid> */}
      {watchNotHaveExperience === false && (
        <Grid container spacing={4} sx={{ mb: 2 }}>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="fixedIncomeSecurities"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth disabled={completeData}>
                  <LabelForm>Producto de renta fija</LabelForm>
                  <Select
                    variant="standard"
                    value={value}
                    multiple
                    {...register("fixedIncomeSecurities")}
                    renderValue={(selected: any) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={
                              datos.fixedIncomeSecurities.find(
                                (item: any) => item.id === value
                              ).description
                            }
                            color="primary"
                            size="small"
                            sx={{ mb: "2px" }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {datos.fixedIncomeSecurities.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    ))}
                  </Select>
                  {messageConditional && (errors as any).productsUsed && (
                    <FormHelperText>
                      {(errors as any).productsUsed.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="investmentFunds"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth disabled={completeData}>
                  <LabelForm>Fondos de Inversión</LabelForm>
                  <Select
                    variant="standard"
                    value={value}
                    multiple
                    {...register("investmentFunds")}
                    renderValue={(selected: any) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={
                              datos.investmentFunds.find(
                                (item: any) => item.id === value
                              ).description
                            }
                            color="primary"
                            size="small"
                            sx={{ mb: "2px" }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {datos.investmentFunds.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    ))}
                  </Select>
                  {messageConditional && (errors as any).productsUsed && (
                    <FormHelperText>
                      {(errors as any).productsUsed.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="liquidityOperations"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth disabled={completeData}>
                  <LabelForm>Operaciones de Liquidez</LabelForm>
                  <Select
                    variant="standard"
                    value={value}
                    multiple
                    {...register("liquidityOperations")}
                    renderValue={(selected: any) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={
                              datos.liquidityOperations.find(
                                (item: any) => item.id === value
                              ).description
                            }
                            color="primary"
                            size="small"
                            sx={{ mb: "2px" }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {datos.liquidityOperations.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    ))}
                  </Select>
                  {messageConditional && (errors as any).productsUsed && (
                    <FormHelperText>
                      {(errors as any).productsUsed.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="variableIncomeSecurities"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth disabled={completeData}>
                  <LabelForm>Valores de renta Variable</LabelForm>
                  <Select
                    variant="standard"
                    value={value}
                    multiple
                    {...register("variableIncomeSecurities")}
                    renderValue={(selected: any) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={
                              datos.variableIncomeSecurities.find(
                                (item: any) => item.id === value
                              ).description
                            }
                            color="primary"
                            size="small"
                            sx={{ mb: "2px" }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {datos.variableIncomeSecurities.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    ))}
                  </Select>
                  {messageConditional && (errors as any).productsUsed && (
                    <FormHelperText>
                      {(errors as any).productsUsed.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="otherServices"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth disabled={completeData}>
                  <LabelForm>Otros Servicios</LabelForm>
                  <Select
                    variant="standard"
                    value={value}
                    multiple
                    {...register("otherServices")}
                    renderValue={(selected: any) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value: any) => (
                          <Chip
                            key={value}
                            label={
                              datos.otherServices.find(
                                (item: any) => item.id === value
                              ).description
                            }
                            color="primary"
                            size="small"
                            sx={{ mb: "2px" }}
                          />
                        ))}
                      </Box>
                    )}
                  >
                    {datos.otherServices.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>
                        <Checkbox checked={value.indexOf(item.id) > -1} />
                        <ListItemText primary={item.description} />
                      </MenuItem>
                    ))}
                  </Select>
                  {messageConditional && (errors as any).productsUsed && (
                    <FormHelperText>
                      {(errors as any).productsUsed.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Conocimiento General del Mercado
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item md={3} sm={6} xs={12}>
          <Controller
            control={control}
            name="knowledge_market_values"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_knowledge_market_values}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Nivel de Conocimiento"
                    variant="standard"
                    error={!!errors.knowledge_market_values}
                    helperText={
                      errors.knowledge_market_values &&
                      errors.knowledge_market_values.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={5} sm={6} xs={12}>
          <Controller
            control={control}
            name="information_media"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_information_media}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Medios de Información utilizados sobre los mercados Financieros"
                    variant="standard"
                    error={!!errors.information_media}
                    helperText={
                      errors.information_media &&
                      errors.information_media.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="professionalInvestor"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue);
                }}
                options={dataJ.al_ta_professional_investor}
                getOptionLabel={(option: any) => option.description || ""}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.id === value.id
                }
                value={value}
                disabled={completeData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Se considera Inversionista Profesional"
                    variant="standard"
                    error={!!errors.professionalInvestor}
                    helperText={
                      errors.professionalInvestor &&
                      errors.professionalInvestor.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        {watchProfessionalInvestor?.description === "Sí" && (
          <Grid item xs={12}>
            <Controller
              control={control}
              name="expcStockMarketLeastThree"
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <FormControlLabel
                  sx={{ alignItems: "flex-start", mb: 2 }}
                  disabled={completeData}
                  control={
                    <Checkbox onChange={onChange} checked={value} {...field} />
                  }
                  label="Cuenta con experiencia, comprobable mediante la realización de operaciones en el mercado de valores nacional o extranjero, con un volumen de al menos tres millones de pesos dominicanos (DOP 3,000,000) o su equivalente en cualquier otra moneda por año, con una frecuencia media de más de tres (3) operaciones por trimestre en el año previo a su clasificación como cliente profesional"
                />
              )}
            />
            <Controller
              control={control}
              name="equityCapacityLeastTwentyFive"
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <FormControlLabel
                  sx={{ alignItems: "flex-start", mb: 2 }}
                  disabled={completeData}
                  control={
                    <Checkbox onChange={onChange} checked={value} {...field} />
                  }
                  label="Cuenta con Capacidad patrimonial, comprobable mediante la determinación de que sus activos en efectivo o inversiones en instrumentos financieros sean de al menos veinticinco millones de pesos dominicanos (DOP 25,000,000) o su equivalente en cualquier otra moneda, depositados en una entidad de intermediación financiera o inversiones locales o extranjeras"
                />
              )}
            />
            <Controller
              control={control}
              name="knowledgeVerified"
              rules={{ required: true }}
              render={({ field: { onChange, value, ...field } }) => (
                <FormControlLabel
                  sx={{ alignItems: "flex-start", mb: 2 }}
                  disabled={completeData}
                  control={
                    <Checkbox onChange={onChange} checked={value} {...field} />
                  }
                  label="Cuenta con Conocimiento, verificado, al menos, uno de los siguientes aspectos: (i) que ha laborado en cargos de dirección en el área de negocios o áreas afines de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (ii) que es o ha sido miembro del consejo de administración de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (iii) que cuenta con la certificación de corredor de valores vigente o su equivalente de otro país, al momento de su calificación; o, (iv) Que tenga o haya tenido un cargo de dirección o gerencial en el área de tesorería, inversiones, riesgos, finanzas o contraloría por un periodo de al menos dos (2) años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (v) Que sea o haya sido miembro del consejo de administración por un periodo de al menos dos años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (vi) Que haya laborado en cargos de dirección o gerencial en entidades financieras extranjeras u organismos multilaterales de los cuales la República Dominicana sea miembro por un período de al menos dos (2) años, o; (vii) Que sea o haya sido funcionario las Superintendencias del Mercado de Valores, de Bancos, de Seguros o de Pensiones, del Banco Central de la República Dominicana o de la Junta Monetaria"
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Instrumentos financieros negociados en ultimos 2 años
      </TitleForm>
      {financialInstrumentsYearField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.financialInstruments`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_instrument}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Instrumentos"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.financialInstruments && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.financialInstruments?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.experienceTime`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_time_experience_instrument}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tiempo de experiencia"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.experienceTime && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.experienceTime?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.numTransactionsYear`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_number_operations_year}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Cantidad de Operaciones al año"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.numTransactionsYear && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.numTransactionsYear?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`financialInstrumentsYear.${index}.levelknowInstRisk`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_knowledge_level}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nivel de conocimiento Instrumento y Riesgo"
                          variant="standard"
                          error={
                            errors?.financialInstrumentsYear?.[index]
                              ?.levelknowInstRisk && true
                          }
                          helperText={
                            errors?.financialInstrumentsYear?.[index]
                              ?.levelknowInstRisk?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !dataForm && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      financialInstrumentsYearRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {financialInstrumentsYearField.length < 3 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              financialInstrumentsYearAppend({
                financialInstruments: [],
                experienceTime: [],
                numTransactionsYear: [],
                levelknowInstRisk: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar
            instrumento
          </Button>
        </Grid>
      )}
      <Typography variant="body2">
        * Mínimo 1 y máximo 3 instrumentos financieros{" "}
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {liquid_assets_distributionField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_liquid_assets_distribution}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_description`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue);
                      }}
                      options={dataJ.al_ta_annual_income}
                      getOptionLabel={(option: any) => option.description || ""}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.id === value.id
                      }
                      value={value}
                      disabled={completeData}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Valor Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_description && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_description?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && !completeData && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      liquid_assets_distributionRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {liquid_assets_distributionField.length < 5 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              liquid_assets_distributionAppend({
                liquid_assets_distribution_id: [],
                liquid_assets_distribution_description: [],
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar Activo
            Líquido
          </Button>
        </Grid>
      )}
      <Typography variant="body2">* Mínimo 1 activo líquido </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Tarjeta de Firmas
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            name="social_name_signature"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nombre o Denominación Social"
                type="text"
                variant="standard"
                disabled={completeData}
                {...register("social_name_signature")}
                error={errors?.social_name_signature && true}
                helperText={errors?.social_name_signature?.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name={"instructions_signature"}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl
                fullWidth
                className={completeData ? "disabled-select" : ""}
              >
                <InputLabelToForm
                  error={
                    errors.instructions_signature &&
                    Boolean(errors.instructions_signature)
                  }
                >
                  Instrucciones de firmas
                </InputLabelToForm>
                <Select
                  variant="standard"
                  value={value}
                  {...register("instructions_signature")}
                  error={
                    errors.instructions_signature &&
                    Boolean(errors.instructions_signature)
                  }
                >
                  <MenuItem value="y">Y</MenuItem>
                  <MenuItem value="y/o">Y/O</MenuItem>
                  <MenuItem value="apoderado">Apoderado</MenuItem>
                </Select>
                {errors.instructions_signature && (
                  <FormHelperText>
                    {errors.instructions_signature.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Firmas Autorizadas
      </TitleForm>
      {authorized_signaturesFields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: "#efe5df",
                  width: "50%",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorized_signatures.${index}.name`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres y Apellidos"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `authorized_signatures.${index}.name` as const
                      )}
                      error={
                        errors?.authorized_signatures?.[index]?.name && true
                      }
                      helperText={
                        errors?.authorized_signatures?.[index]?.name?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorized_signatures.${index}.document_number`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de documento de identidad"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `authorized_signatures.${index}.document_number` as const
                      )}
                      error={
                        errors?.authorized_signatures?.[index]
                          ?.document_number && true
                      }
                      helperText={
                        errors?.authorized_signatures?.[index]?.document_number
                          ?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: "flex" }}>
                <Controller
                  name={`authorized_signatures.${index}.function`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Función"
                      type="text"
                      variant="standard"
                      disabled={completeData}
                      {...register(
                        `authorized_signatures.${index}.function` as const
                      )}
                      error={
                        errors?.authorized_signatures?.[index]?.function && true
                      }
                      helperText={
                        errors?.authorized_signatures?.[index]?.function
                          ?.message
                      }
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      authorized_signaturesRemove(index);
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        );
      })}
      {authorized_signaturesFields.length < 4 && !completeData && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mb: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              authorized_signaturesAppend({
                name: "",
                document_number: "",
                function: "",
              });
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar firma
          </Button>
        </Grid>
      )}
      <Typography variant="body2">
        * Para añadir o eliminar firmantes deberá completar una nueva tarjeta de
        firmas. El cliente, su representante legal o apoderado declara que las
        firmas aquí estampadas han sido colocadas por las personas físicas que
        figuran en el presente formulario con el objetivo de ser utilizadas por
        ALTIO para verificación.
      </Typography>
      <Stack
        justifyContent="flex-end"
        alignItems="flex-end"
        direction="row"
        spacing={2}
        sx={{ mt: 2 }}
      >
        {loadingSendData && (
          <CircularProgress
            size={24}
            sx={{ margin: "5px auto", color: "#6fc555" }}
          />
        )}
        {completeData ? (
          <>
            <Button
              type="button"
              variant="outlined"
              onClick={handleBack}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Regresar
            </Button>
            <ButtonGradient
              type="button"
              onClick={handleNext}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        ) : (
          <>
            <Tooltip
              disableFocusListener
              disableTouchListener
              title={
                step4Answered === false
                  ? "Perderas la información no guardada"
                  : ""
              }
              placement="top"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={handleBack}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Regresar
              </Button>
            </Tooltip>
            <ButtonGradient
              type="button"
              //onClick={() => setOpenDialog(true)}
              onClick={validateForm}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Continuar
            </ButtonGradient>
          </>
        )}
      </Stack>
      <Dialog open={openDialog} onClose={handleClose}>
        <FancyDialog
          textTitle={`¿Estas seguro de guardar todos los datos?`}
          textChildren={`Si guardas este paso, no podrás regresar a editar la información anterior`}
          actionButton={
            <>
              {" "}
              <Button
                variant="contained"
                sx={{ mt: 2, mr: 2 }}
                onClick={handleClose}
                autoFocus
              >
                Cancelar
              </Button>
              <ButtonGradient type="submit" onClick={handleSubmit(onSubmit)}>
                Aceptar
              </ButtonGradient>{" "}
            </>
          }
        />
      </Dialog>
    </form>
  );
};

export default LegalRepresentativeInformation;
