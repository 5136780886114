import { FC, useEffect, useLayoutEffect, useState } from "react";
import useLegalForm from "../../hooks/useLegalForm";
import useProfile from "../../hooks/useProfile";
import {
  Stack,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Divider,
  CircularProgress,
  Button,
  Link,
  List,
  ListItem,
  Tooltip,
  ListItemText,
  ListItemIcon,
  styled,
} from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FinalCalInvestorProfile } from "../../types/types";
import { formSchemaOptions } from "../../utils";
import { data as datos } from "../../data/data";
import TitleForm from "../../components/TitleForm";
import ButtonGradient from "../../components/ButtonGradient";
import InputLabelToForm from "../../components/InputLabelToForm";
import axios from "axios";
import { apis } from "../../apis/apis";
import {
  updateProfile,
  clientNotification,
  admintNotification,
} from "../../services/Services";

const ListText = styled(ListItemText)(() => ({
  marginTop: 0,
  marginBottom: 0,
  "& span": {
    fontSize: "1rem",
  },
}));

const InvestorTypeResults: FC = () => {
  const { handleBack } = useLegalForm();
  const {
    dataForm,
    investmentProfile,
    getInvestmentProfile,
    loadDataServer,
    steps_completed,
    data,
    dataJ,
  } = useProfile();

  let findData: any;
  if (dataForm !== undefined) {
    findData = dataForm;
  } else {
    findData = [];
  }

  const validation = Yup.object().shape({
    agree_cat_investor_profile: Yup.string().required("Campo es requerido"),
    type_investor_considered: Yup.object().when("agree_cat_investor_profile", {
      is: "No",
      then: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
      otherwise: Yup.object().nullable(),
    }),
    what_cat_investor_considered: Yup.object().when(
      "type_investor_considered",
      {
        is: (type_investor_considered: any) => {
          if (type_investor_considered?.description == "No Profesional") {
            return true;
          }
        },
        then: Yup.object()
          .shape(formSchemaOptions)
          .typeError("Campo es requerido"),
        otherwise: Yup.object().nullable(),
      }
    ),
  });

  const defaultValues: FinalCalInvestorProfile = {
    agree_cat_investor_profile: findData.agree_cat_investor_profile
      ? findData.agree_cat_investor_profile
      : "",
    type_investor_considered: findData.type_investor_considered
      ? dataJ &&
        dataJ.al_ta_type_investor_considered.find(
          (f: any) => f.id === Number(findData.type_investor_considered)
        )
      : [],
    what_cat_investor_considered: findData.what_cat_investor_considered
      ? data &&
        data.al_ta_what_cat_investor_considered.find(
          (f: any) => f.id === Number(findData.what_cat_investor_considered)
        )
      : [],
  };

  const [loading, setLoading] = useState(false);
  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false);
  const [loadedPDFPerfil, setLoadedPDFPerfil] = useState(false);
  const [loadingSendData, setLoadingSendData] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");

  const {
    register,
    handleSubmit,
    resetField,
    formState,
    control,
    watch,
    reset,
  } = useForm<FinalCalInvestorProfile>({
    defaultValues,
    resolver: yupResolver(validation),
  });
  const { errors } = formState;
  const watchCatProfile: any = watch("agree_cat_investor_profile");
  const watchtype_investor_considered: any = watch("type_investor_considered");
  const miarray: any = [];
  const id = sessionStorage.getItem("user_id");

  useEffect(() => {
    reset(defaultValues);
  }, [dataForm]);

  useEffect(() => {
    if (watchtype_investor_considered?.description !== "No Profesional") {
      resetField("what_cat_investor_considered");
    }
  }, [watchtype_investor_considered]);

  useEffect(() => {
    if (watchCatProfile === "Si") {
      resetField("type_investor_considered");
      resetField("what_cat_investor_considered");
    }
  }, [watchCatProfile]);

  const handleDownloadKYC = async () => {
    setLoadedPDFKYC(true);
    await axios
      .get(`${apis.investmentProfile.kyc}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "conozca-su-cliente-persona-juridica.pdf"
        );
        document.body.appendChild(link);
        link.click();
      });
    setLoadedPDFKYC(false);
  };

  /* const handleDownloadPerfil = async () => {
    setLoadedPDFPerfil(true);
    await axios
      .get(`${apis.investmentProfile.perfil}${id}`, { responseType: "blob" })
      .then((resp) => {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Evaluacion-del-perfil-persona-juridica.pdf"
        );
        document.body.appendChild(link);
        link.click();
      });
    setLoadedPDFPerfil(false);
  }; */

  let userData;
  const handleNotification = async () => {
    userData = {
      firstname: findData.social_denomination,
      formtype: "Jurídico",
      username: findData.email,
    };

    await clientNotification(userData, "completeFormJuridic");
    await admintNotification(userData);
  };

  const convertTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date: Date) => {
    return [
      convertTo2Digits(date.getDate()),
      convertTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join("/");
  };

  const onSubmit = async (data: any) => {
    setLoadingSendData(true);
    let key: string;
    let value: { id: string; description: string }[] | any = [];

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value });
      if (!value) {
        delete data[key];
      } else if (typeof value == "object") {
        data[key] = value !== null && value.id ? value.id : `"${value}"`;
      }
    }
    data.all_steps_complete = "true";

    if (findData.all_steps_complete !== "true") {
      let fullData = {
        fields: {
          ...data,
          form_date: formatDate(new Date()),
        },
        formType: "J",
        userId: sessionStorage.getItem("user_id"),
        stepForm: "Confirmación de Perfil",
      };
      localStorage.setItem("stepForm", "Confirmación de Perfil");
      const saveData = await updateProfile(fullData);
      if (saveData.status === 200) {
        await handleNotification();
        setLoadingSendData(false);
        setMessage2("Información guardada");
        loadDataServer && loadDataServer(localStorage.getItem("user_id"));
        setTimeout(() => {
          setMessage2("");
        }, 600);
      } else {
        setLoadingSendData(false);
        setMessage("Ha ocurrido un error");
      }
    }
  };

  return (
    <div>
      <Grid
        container
        rowSpacing={{ xs: 2, sm: 4 }}
        columnSpacing={{ xs: 2, sm: 4 }}
        sx={{
          maxWidth: "950px",
          mx: {
            md: "auto!important",
          },
          pr: {
            md: "32px",
          },
        }}
      >
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <TitleForm sx={{ mt: 4, mb: 4 }}> Resultado Evaluación</TitleForm>
          <Typography variant="body1" align="left" sx={{ mb: 3 }}>
            Según la información suministrada por el cliente acerca de su nivel
            profesional, fuente de fondos, conocimiento de productos
            financieros, apetito de riesgo y rentabilidad esperada, la
            recomendación según su perfil de riesgo se ajusta a:
          </Typography>

          {loading ? (
            <Stack spacing={2} direction="row">
              <CircularProgress
                size={24}
                sx={{ margin: "5px auto", color: "#6fc555" }}
              />
            </Stack>
          ) : (
            <>
              <Typography align="center" variant="h6" color="primary">
                {
                  //@ts-ignore
                  investmentProfile?.result?.INVESTOR_TYPE
                }
              </Typography>
              {
                //@ts-ignore
                investmentProfile?.result?.INVESTOR_CATEGORY && (
                  <>
                    <Divider
                      sx={{
                        pt: 3,
                        mb: 3,
                        borderColor: "secondary.main",
                        width: "50%",
                        maxWidth: "450px",
                        ml: "auto",
                        mr: "auto",
                      }}
                    />

                    <Typography
                      align="center"
                      variant="h5"
                      sx={{
                        width: "100%",
                        maxWidth: "300px",
                        backgroundColor: "primary.main",
                        color: "#fff",
                        pb: "4px",
                        margin: "0 auto",
                        borderRadius: "4px",
                        "&:hover": {
                          backgroundColor: "primary.main",
                          opacity: [0.9, 0.8, 0.9],
                        },
                      }}
                      color="light.contrastText"
                    >
                      {
                        //@ts-ignore
                        investmentProfile?.result?.INVESTOR_CATEGORY
                      }
                    </Typography>
                  </>
                )
              }
              <Divider
                sx={{
                  pt: 3,
                  mb: 0,
                  borderColor: "secondary.main",
                  width: "50%",
                  maxWidth: "450px",
                  ml: "auto",
                  mr: "auto",
                }}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TitleForm sx={{ mt: 4, mb: 4 }}>Confirmación de Perfil</TitleForm>
            <Grid container spacing={4} sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <Controller
                  name={"agree_cat_investor_profile"}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControl
                      fullWidth
                      className={
                        findData.all_steps_complete ? "disabled-select" : ""
                      }
                    >
                      <InputLabelToForm
                        error={
                          errors.agree_cat_investor_profile &&
                          Boolean(errors.agree_cat_investor_profile)
                        }
                      >
                        ¿Cliente está de acuerdo con la categorización de su
                        perfil de inversionista?
                      </InputLabelToForm>
                      <Select
                        variant="standard"
                        value={value}
                        {...register("agree_cat_investor_profile")}
                        error={
                          errors.agree_cat_investor_profile &&
                          Boolean(errors.agree_cat_investor_profile)
                        }
                      >
                        <MenuItem value="Si">Si</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      {errors.agree_cat_investor_profile && (
                        <FormHelperText>
                          {errors.agree_cat_investor_profile.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Grid>
              {watchCatProfile === "No" && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="type_investor_considered"
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          onChange={(event, newValue) => {
                            onChange(newValue);
                          }}
                          options={
                            dataJ && dataJ.al_ta_type_investor_considered
                              ? dataJ.al_ta_type_investor_considered
                              : []
                          }
                          getOptionLabel={(option: any) =>
                            option.description || ""
                          }
                          isOptionEqualToValue={(option: any, value: any) =>
                            option.id === value.id
                          }
                          value={value}
                          disabled={findData.all_steps_complete}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                              variant="standard"
                              error={!!errors.type_investor_considered}
                              helperText={
                                errors.type_investor_considered &&
                                errors.type_investor_considered.message
                              }
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {watchtype_investor_considered?.id === 4 && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="what_cat_investor_considered"
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        onChange={(event, newValue) => {
                          onChange(newValue);
                        }}
                        options={
                          data && data.al_ta_what_cat_investor_considered
                            ? data.al_ta_what_cat_investor_considered
                            : []
                        }
                        getOptionLabel={(option: any) =>
                          option.description || ""
                        }
                        isOptionEqualToValue={(option: any, value: any) =>
                          option.id === value.id
                        }
                        value={value}
                        disabled={findData.all_steps_complete}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
                            variant="standard"
                            error={!!errors.what_cat_investor_considered}
                            helperText={
                              errors.what_cat_investor_considered &&
                              errors.what_cat_investor_considered.message
                            }
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              Se recomienda que tu Portafolio contenga deuda o bonos
              gubernamentales, también es posible incluir cuotas de fondos
              mutuos o fondos de inversión estructurados en base a instrumentos
              respaldados por el Gobierno Central. <br />
              Estos instrumentos financieros deben estar contratados a plazos
              cortos de entre 30 días y un Año. La principal característica de
              las inversiones a realizar es buscar un rendimiento que compense
              la inflación y que posea un nivel de liquidez aceptable para
              disponer de sus recursos en caso de un imprevisto. De igual
              manera, este tipo de inversionistas pueden utilizar productos que
              mitiguen el riesgo de mercado implícito en instrumentos de Renta
              Fija, como por ejemplo operaciones con acuerdos de recompra con
              niveles de precio previamente establecidos. <br />
              Se recomienda que los portafolios para inversionistas
              conservadores deben tener cero renta variable (acciones) y cero
              commodities, se debe buscar que los clientes que no toleran
              riesgos tengan la seguridad de que sus recursos están lo más
              protegidos que se pueda contra imprevistos del mercado o de los
              emisores".
            </Typography>
            <Stack
              justifyContent="flex-end"
              alignItems="flex-end"
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              sx={{ mt: 6 }}
            >
              {steps_completed && (
                <>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={handleBack}
                    sx={{ width: { xs: "100%", sm: "auto" } }}
                  >
                    Regresar
                  </Button>
                  {!findData.all_steps_complete && (
                    <ButtonGradient
                      type="submit"
                      sx={{ width: { xs: "100%", sm: "auto" } }}
                    >
                      Guardar y finalizar
                    </ButtonGradient>
                  )}
                </>
              )}
            </Stack>
            <Divider
              sx={{
                pt: 3,
                mb: 0,
                borderColor: "secondary.main",
                width: "50%",
                ml: "auto",
                mr: "auto",
              }}
            />
            <TitleForm sx={{ mt: 4, mb: 4, fontWeight: 500 }}>
              ¡Primer paso completado!
            </TitleForm>
            <Divider
              sx={{
                pt: 1,
                mb: 3,
                borderColor: "secondary.main",
                width: "100%",
                ml: "auto",
                mr: "auto",
              }}
            />
            <Typography
              variant="h6"
              color="secondary.main"
              sx={{ fontWeight: 500, mb: 3 }}
            >
              Próximos pasos:
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", sm: "flex-start" }}
              alignItems="center"
              spacing={{ xs: 1, sm: 2 }}
            >
              <Button
                variant="outlined"
                onClick={handleDownloadKYC}
                disabled={!findData.all_steps_complete}
                sx={{
                  fontWeight: 700,
                  borderRadius: "0.75rem",
                  padding: "2px 15px",
                }}
              >
                {loadedPDFKYC && (
                  <CircularProgress
                    size={24}
                    sx={{ marginRight: "5px", color: "#6fc555" }}
                  />
                )}
                Descarga aquí
              </Button>
              <Typography variant="body1">
                los formularios que acabas de completar y firma cada uno de
                ellos.
              </Typography>
              {/* <Button
                variant="outlined"
                onClick={handleDownloadPerfil}
                disabled={!findData.all_steps_complete}
              >
                {loadedPDFPerfil && (
                  <CircularProgress
                    size={24}
                    sx={{ marginRight: "5px", color: "#6fc555" }}
                  />
                )}
                Descargar Evaluación del Perfil
              </Button> */}
            </Stack>
            <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
              Envía los documentos escaneados a{" "}
              <Link
                href="mailto:servicios@altiosafi.com"
                target="_blank"
                sx={{ fontWeight: 700 }}
              >
                servicios@altiosafi.com
              </Link>
              , además adjunta:
            </Typography>
            <List dense={true} disablePadding>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Cédula de los accionistas o beneficiarios finales." />
              </ListItem>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Registro mercantil." />
              </ListItem>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Acta de inscripción de RNC." />
              </ListItem>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Estatutos Sociales." />
              </ListItem>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Estados Financieros Auditados recientes." />
              </ListItem>
              <ListItem sx={{ alignItems: "flex-start" }}>
                <ListItemIcon sx={{ minWidth: "15px", marginTop: "8px" }}>
                  <FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
                </ListItemIcon>
                <ListText primary="Acta de la Ultima Asamblea Celebrada." />
              </ListItem>
            </List>
            {loadingSendData && (
              <Stack spacing={2} direction="row">
                <CircularProgress
                  size={24}
                  sx={{ margin: "5px auto", color: "#6fc555" }}
                />
              </Stack>
            )}
            {message !== "" && (
              <Typography sx={{ mt: 4 }} color="success.main" align="center">
                {message}
              </Typography>
            )}
            {message2 !== "" && (
              <Typography sx={{ mt: 4 }} color="primary" align="center">
                {message2}
              </Typography>
            )}
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default InvestorTypeResults;
