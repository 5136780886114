import { createContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setAuthData, removeItem, setItem } from "../utils/LocalStorageManager";
import jwt_decode from "jwt-decode";
import { requestTypesApp } from "../types/types";
import { getProfile, getAllData } from "../services/Services";
import { getUserData } from "../services/Services";

const initialState = {
  isLoading: false,
  errorMsg: "",
  successMsg: "",
  existProfile: false,
};

//define context
const AppContext = createContext<requestTypesApp>(initialState);
const { Provider } = AppContext;

const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData);
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [existProfile, setExistProfile] = useState(false);
  const [clientProfile, setProfileData] = useState({});
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: "",
    modalObject: null,
  });

  const [data, setAllData] = useState<any>(
    localStorage.getItem("allDataTablesF") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("allDataTablesF"))
  );
  const [dataJ, setAllDataJ] = useState<any>(
    localStorage.getItem("allDataTablesJ") &&
      //@ts-ignore
      JSON.parse(localStorage.getItem("allDataTablesJ"))
  );

  const [authenticate, setAuthenticate] = useState(() => {
    if (!authData) {
      return false;
    }
    const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
    const expiresAt = decodeToken?.exp;
    let valid = new Date().getTime() / 1000 < expiresAt;
    if (valid) {
      return true;
    }
    return false;
  });
  const navigate = useNavigate();

  const getAllDataTables = async () => {
    const responseF = await getAllData("F");
    if (responseF?.status === 200) {
      setAllData(responseF.body);
      localStorage.setItem("allDataTablesF", JSON.stringify(responseF.body));
    }

    const responseJ = await getAllData("J");
    if (responseJ?.status === 200) {
      setAllDataJ(responseJ.body);
      localStorage.setItem("allDataTablesJ", JSON.stringify(responseJ.body));
    }
  };

  const loginProvider = async (loginData: any) => {
    try {
      //save login data in storage
      const authData = await setAuthData("authAltioInfo", loginData);
      if (!authData) {
        setAuthenticate(false);
        return;
      }
      //Obetener data del usurio de micro de user
      const dataUser: any = await getUserData(loginData.username);
      if (dataUser) {
        console.log("dataUser: ", dataUser.content[0]);
        await setItem("dataUser", dataUser.content[0]);
      }

      const decodeToken: any = jwt_decode(authData?.tokenInfo?.access_token);
      const expiresAt = decodeToken?.exp;
      let valid = new Date().getTime() / 1000 < expiresAt;
      if (valid) {
        //console.log("es valido");
        setAuthenticate(valid);
        setAuthInfo(authData);
        await getAllDataTables();
        const responseProfile = await getProfile(authData.id);
        if (responseProfile?.status === 200) {
          localStorage.setItem(
            "data_form",
            JSON.stringify(responseProfile.body.fields)
          );
          if (responseProfile.body.formType === "F") {
            navigate("formClient/physical", { replace: true });
          } else {
            navigate("formClient/legal", { replace: true });
          }
        } else {
          setExistProfile(false);
          //navigate("/physical-or-legal", { replace: true });
          navigate("formClient/physical", { replace: true });
        }
        return;
      }
    } catch (error) {
      console.log(error);
    }
    setAuthenticate(false);
  };

  const logoutProvider = async () => {
    await setAuthData("authAltioInfo", null);
    await removeItem("data_form");
    await removeItem("dataUser");
    setAuthenticate(false);
    navigate("/", { replace: true });
  };

  /* useEffect(() => {
    let time = 300000;
    let timeOutTimer = setTimeout(logoutProvider, time);
    const checkInactivity = () => {
      window.onclick = () => checkInactivity();
      window.onkeypress = () => checkInactivity();
      window.addEventListener("mousemove", checkInactivity);
      clearTimeout(timeOutTimer);
      timeOutTimer = setTimeout(logoutProvider, time);
    };
    checkInactivity();
  }, []); */

  const values: requestTypesApp = useMemo(
    () => ({
      authInfo,
      authenticate,
      isLoading,
      successMsg,
      clientProfile,
      loginProvider,
      logoutProvider,
      setLoading,
      setErrorMsg,
      setSuccessMsg,
      setProfileData,
      existProfile,
      modalData,
      setModalData,
      errorMsg,
    }),
    // eslint-disable-next-line
    [
      authInfo,
      authenticate,
      isLoading,
      errorMsg,
      successMsg,
      clientProfile,
      modalData,
    ]
  );

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};

export { AppProvider };

export default AppContext;
