import { useState, createContext } from "react";
import { PhysicalForm, PhysicalFormData } from "../types/types";

const initialState = {
  fieldas: [],
  step1Answered: false,
  step1Field: [],
  step2Answered: false,
  step2Field: [],
  step3Answered: false,
  step3Field: [],
  step4Answered: false,
  step4Field: [],
  activeStep: 0,
};
const PhysicalFormContext = createContext<PhysicalForm>(initialState);

const PhysicalFormProvider = ({ children }: any) => {
  const [step1Answered, setStep1Answered] = useState(
    initialState.step1Answered
  );
  const [step1Field, setStep1Field] = useState<unknown[]>(
    initialState.step1Field
  );

  const [step2Answered, setStep2Answered] = useState(
    initialState.step2Answered
  );
  const [step2Field, setStep2Field] = useState<any[]>(initialState.step2Field);

  const [step3Answered, setStep3Answered] = useState(
    initialState.step3Answered
  );
  const [step3Field, setStep3Field] = useState<any[]>(initialState.step3Field);

  const [step4Answered, setStep4Answered] = useState(
    initialState.step4Answered
  );
  const [step4Field, setStep4Field] = useState<any[]>(initialState.step4Field);
  const [fieldas, setFields] = useState<PhysicalFormData[]>(
    initialState.fieldas
  );
  const [activeStep, setActiveStep] = useState(initialState.activeStep);

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const removeDataPhysical = () => {
    console.log("Ingresa");
    setActiveStep(0);
    setStep1Answered(false);
    setStep2Answered(false);
    setStep3Answered(false);
    setStep4Answered(false);
    setStep1Field([]);
    setStep2Field([]);
    setStep3Field([]);
    setStep4Field([]);
    setFields([]);
  };

  const formContextValues: PhysicalForm = {
    step1Answered,
    setStep1Answered,
    step1Field,
    setStep1Field,
    step2Answered,
    setStep2Answered,
    step2Field,
    setStep2Field,
    step3Answered,
    setStep3Answered,
    step3Field,
    setStep3Field,
    step4Answered,
    setStep4Answered,
    step4Field,
    setStep4Field,
    fieldas,
    setFields,
    activeStep,
    setActiveStep,
    handleBack,
    handleNext,
    removeDataPhysical,
  };

  return (
    <PhysicalFormContext.Provider value={formContextValues}>
      {children}
    </PhysicalFormContext.Provider>
  );
};

export { PhysicalFormProvider };

export default PhysicalFormContext;
