import { useState, createContext } from "react";
import { LegalForm, LegalFormData } from "../types/types";
import axios from "axios";
import { apis } from "../apis/apis";

const initialState = {
  activeStep: 0,
  step1Answered: false,
  step2Answered: false,
  step3Answered: false,
  step4Answered: false,
};
const LegalFormContext = createContext<LegalForm>(initialState);

const LegalFormProvider = ({ children }: any) => {
  const [step1, setStep1] = useState<unknown[]>([]);
  const [step1Answered, setStep1Answered] = useState(
    initialState.step1Answered
  );
  const [step2, setStep2] = useState<unknown[]>([]);
  const [step2Answered, setStep2Answered] = useState(
    initialState.step1Answered
  );
  const [step3, setStep3] = useState<unknown[]>([]);
  const [step3Answered, setStep3Answered] = useState(
    initialState.step1Answered
  );
  const [step4, setStep4] = useState<unknown[]>([]);
  const [step4Answered, setStep4Answered] = useState(
    initialState.step1Answered
  );
  const [fields, setFields] = useState<LegalFormData[]>();
  const [activeStep, setActiveStep] = useState(initialState.activeStep);

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const removeLegalData = () => {
    setActiveStep(0);
    setStep1([]);
    setStep2([]);
    setStep3([]);
    setStep4([]);
    setFields(undefined);
  };

  const formContextValues: LegalForm = {
    step1,
    setStep1,
    step1Answered,
    setStep1Answered,
    step2,
    setStep2,
    step2Answered,
    setStep2Answered,
    step3,
    setStep3,
    step3Answered,
    setStep3Answered,
    step4,
    setStep4,
    step4Answered,
    setStep4Answered,
    fields,
    //@ts-ignore
    setFields,
    activeStep,
    setActiveStep,
    handleBack,
    handleNext,
    removeLegalData,
  };

  return (
    <LegalFormContext.Provider value={formContextValues}>
      {children}
    </LegalFormContext.Provider>
  );
};

export { LegalFormProvider };

export default LegalFormContext;
