import { Stack, Typography, styled, Link as TextLink } from "@mui/material";
import { Link } from "react-router-dom";
import ButtonGradient from "../../../components/ButtonGradient";
import TitleForm from "../../../components/TitleForm";

const ToLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.contrastText,
  width: "100%",
}));

const validateRegister = () => {
  return (
    <Stack justifyContent="center" alignItems="center" p={2}>
      <TitleForm>¡Ya estás listo!</TitleForm>
      <Typography variant="body1" align="center" color="primary" sx={{ mb: 3 }}>
        Gracias por registrarse y bienvenido <br />
        al Portal ALTIO. <br /> Siga el enlace en su email, para comenzar.
      </Typography>
      <ButtonGradient fullWidth>
        <ToLink to="/">Iniciar sesión</ToLink>
      </ButtonGradient>
      <Typography variant="body1" align="center" sx={{ mt: 3 }}>
        Si tiene alguna pregunta, por favor contate a soporte a través del
        correo{" "}
        <TextLink
          href="mailto:servicios@altiosafi.com"
          target="_blank"
          color="inherit"
        >
          servicios@altiosafi.com
        </TextLink>
      </Typography>
    </Stack>
  );
};

export default validateRegister;
