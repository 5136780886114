import usePhysycalForm from "../../hooks/usePhysycalForm";
import useProfile from "../../hooks/useProfile";
import {
  Container,
  Tooltip,
  Stack,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  Box,
  Button,
  styled,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Headroom from "react-headroom";

import { StepsType } from "../../types/types";
import CustomerIdenfication from "./CustomerIdenfication";
import JobsInformation from "./JobsInformation";
import InvestorProfile from "./InvestorProfile";
import AdditionalData from "./AdditionalData";
import InvestorTypeResults from "./InvestorTypeResults";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #14475294 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #14475294 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 60,
  height: 60,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  cursor: "inherit",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #144752 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.35)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)",
  }),
}));

const WrappMobileStepper = styled(MobileStepper)(({ theme }) => ({
  background:
    "linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)",
  color: "#fff",
  fontFamily: "Mulish",
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 30 }} />,
    2: <BadgeIcon sx={{ fontSize: 30 }} />,
    3: <PeopleAltIcon sx={{ fontSize: 30 }} />,
    4: <QueryStatsIcon sx={{ fontSize: 30 }} />,
    5: <SensorOccupiedIcon sx={{ fontSize: 30 }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ cursor: "pointer" }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const steps: StepsType[] = [
  {
    label: "Identificación del Cliente",
    component: <CustomerIdenfication />,
  },
  {
    label: "Información Laboral & Financiera",
    component: <JobsInformation />,
  },
  {
    label: "Datos Complementarios",
    component: <AdditionalData />,
  },
  {
    label: "Perfil del Inversionista",
    component: <InvestorProfile />,
  },
  {
    label: "Resultados del Inversionista",
    component: <InvestorTypeResults />,
  },
];

const PhysicalForm = () => {
  const { activeStep, setActiveStep } = usePhysycalForm();
  const { exists, steps_completed } = useProfile();
  const theme = useTheme();
  const maxSteps = steps.length;
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step);
  };

  const name = (stepNumber: number) => {
    if (steps_completed && steps_completed[stepNumber]) {
      setActiveStep && setActiveStep(stepNumber);
    }
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 5, px: { xs: 0, sm: 2 } }}>
      <Stack sx={{ width: "100%" }} spacing={4}>
        {!isMobile ? (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className={`${activeStep}`}
          >
            {steps.map((step, i) => (
              <Step key={i}>
                {steps_completed &&
                steps_completed[i] !== true &&
                activeStep !== steps.length - 2 ? (
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title="Debes completar todos los pasos"
                    placement="top"
                  >
                    <StepLabel
                      StepIconComponent={ColorlibStepIcon}
                      sx={{ cursor: "default" }}
                    >
                      {step.label}
                    </StepLabel>
                  </Tooltip>
                ) : (
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    onClick={handleStep(i)}
                    sx={{ cursor: "pointer" }}
                  >
                    {step.label}
                  </StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        ) : (
          <Headroom downTolerance={10} pinStart={80}>
            <WrappMobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={
                    activeStep >= maxSteps - 1 ||
                    (steps_completed &&
                      steps_completed[activeStep + 1] !== true &&
                      activeStep !== steps.length - 2)
                  }
                  sx={{ color: "#fff" }}
                >
                  Continuar
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                  sx={{ color: "#fff" }}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Regresar
                </Button>
              }
            />
          </Headroom>
        )}{" "}
        <>
          {steps[activeStep].component}
          {!isMobile && (
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Regresar
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={handleNext}
                disabled={activeStep === steps.length - 1}
              >
                {activeStep === steps.length - 1 ? "Finalizar" : "Continuar"}
              </Button>
            </Box>
          )}
        </>
      </Stack>
    </Container>
  );
};

export default PhysicalForm;
