import * as Yup from "yup";
import { phoneRegExp, urlRegExp, formSchemaOptions, formSchemaCountry } from "../../utils";

export const validationBussinessIndetification = Yup.object().shape({
  investment_promoter: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  social_denomination: Yup.string()
      .required("Campo es requerido"),
  trade_name: Yup.string()
      .required("Campo es requerido"),
  incorporation_date: Yup.date().typeError("Campo es requerido"),
  country_of_incorporation: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  city_of_incorporation: Yup.string()
  .required("Campo es requerido"),
  entity_type: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  id_type_legal: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  document_number: Yup.string()
  .required("Campo es requerido"),
  type_of_legal_entity: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  other_type_of_legal_entity: Yup.string().when("type_of_legal_entity", {
    is: (type_of_legal_entity: any) => {
      if (type_of_legal_entity?.id === "6") {
        return true;
      }
    },
    then: Yup.string().required("Campo es requerido"),
  }),
  company_belong_to_economic_group: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  commercial_register_number: Yup.string()
      .required("Campo es requerido"),
  relationship_duration: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  company_incorporated_in_usa: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  country: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  province: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "63") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  }),
  sector: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "63") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
  }),
  street_contact_person: Yup.string().required("Campo es requerido"),
  building: Yup.string(),
  phone_one: Yup.string().matches(phoneRegExp, {message: "Teléfono no es valido",excludeEmptyString:true}),
  name_contact_person: Yup.string()
  .required("Campo es requerido"),
  position_in_entity: Yup.string()
  .required("Campo es requerido"),
  contact_phone: Yup.string().matches(phoneRegExp, {message: "Teléfono no es valido",excludeEmptyString:true}),
  ext_contact_phone: Yup.string().matches(/^[0-9]*$/, {message: "Extensión no es valida",excludeEmptyString:true}),
  email: Yup.string()
  .required("Campo es requerido")
  .email("Email es invalido"),
  website: Yup.string().matches(urlRegExp, {message: "Url es invalida", excludeEmptyString:true}),
  channel_catchment_client: Yup.string()
});

const formSchemaMain_clients_entity = {
  social_name: Yup.string().required("Campo es requerido"),
  identification_number: Yup.string().required("Campo es requerido"),
  economic_sector: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  other_economic_sector: Yup.string().when("economic_sector", {
    is: (economic_sector: any) => {
      if (economic_sector?.id === "15") {
        return true;
      }
    },
    then: Yup.string().required("Campo es requerido"),
  }),
  contact: Yup.string().required("Campo es requerido"),
};

const formSchemaInformation_shareholders = {
  social_name: Yup.string().required("Campo es requerido"),
  identification_number: Yup.string().required("Campo es requerido"),
  percentage_stake: Yup.string().required("Campo es requerido"),
  nationality_incorporation: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  US_nationality_or_residence: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
};

const formSchemaMembers_management_body = {
  social_name: Yup.string().required("Campo es requerido"),
  identification_number: Yup.string().required("Campo es requerido"),
  position: Yup.string().required("Campo es requerido"),
  nationality: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  US_nationality_or_residence: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
};

const formSchemapolitically_exposed_person_members = {
  name: Yup.string().required("Campo es requerido"),
  identification_number: Yup.string().required("Campo es requerido"),
  institution_name: Yup.string().required("Campo es requerido"),
  position: Yup.string().required("Campo es requerido"),
  appointment_date: Yup.date().typeError("Campo es requerido"),
  removal_date: Yup.date().nullable().typeError("Campo es requerido"),
};

export const validationEconomicActivityCompany = Yup.object().shape({
  nonfinancial_activities: Yup.object().nullable().test(
    'oneOfRequired',
    'Uno de los campos Actividades Financieras o Actividades no Financieras es requerido',
    function(item) {
      return (this.parent.nonfinancial_activities || this.parent.financialactivities)
    }
  ),
  financialactivities: Yup.object().nullable().test(
    'oneOfRequired',
    'Uno de los campos Actividades Financieras o Actividades no Financieras es requerido',
    function(item) {
      return (this.parent.financialactivities || this.parent.nonfinancial_activities)
    }
  ),
  other_economic_activity: Yup.object().nullable().when(
    ["nonfinancial_activities", "financialactivities"],
    {is: (
      nonfinancial_activities: any,
      financialactivities: any
    ) => nonfinancial_activities?.id === "6" && financialactivities?.id === "9",
      then: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  }
  ),
  economic_sector: Yup.string()
  .required("Campo es requerido"),
  /* other_economic_activity: Yup.object().nullable().when(
    "nonfinancial_activities",
    {is: (
      nonfinancial_activities: any
    ) => nonfinancial_activities?.id === "6",
      then: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  }
  ), */
  entity_have_links_with_altio: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  entity_have_links_with_altio_data: Yup.array().when(
    "entity_have_links_with_altio",
    {
      is: (entity_have_links_with_altio: any) => {
        if (entity_have_links_with_altio?.id === "1") {
          return true;
        }
      },
      then: Yup.array().of(
        Yup.object({
          name_or_social_denomination:
            Yup.string().required("Campo es requerido"),
          relation_with_entity: Yup.string().required("Campo es requerido"),
        })
      ),
    }
  ),
  annual_income: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  annual_expenses: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  total_assets: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  total_liabilities: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  subscribed_paid_Capital: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  total_employees: Yup.string()
  .required("Campo es requerido"),
  average_annual_amount: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  total_heritage: Yup.object()
      .shape(formSchemaOptions).typeError("Campo es requerido"),
  investment_goals: Yup.object()
  .shape(formSchemaOptions).typeError("Campo es requerido"),
  main_sources_funds: Yup.string()
  .required("Campo es requerido"),
  main_clients_entity: Yup.array().of(Yup.object().shape(formSchemaMain_clients_entity)),
})

const formSchemaBank_accounts_register = {
  account_holder: Yup.string().required("Campo es requerido"),
  entity_account: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  account_type: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  account_number: Yup.string().required("Campo es requerido"),
  currency_type: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido")
};

const formSchemaLegal_representative = {
  name: Yup.string().required("Campo es requerido"),
  id_type: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  document_number: Yup.string().required("Campo es requerido"),
  issuing_country: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  date_of_birth: Yup.date().typeError("Campo es requerido"),
  country_of_birth: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  UUEE_nationality: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  country: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  province: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "RD") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  }),
  municipality: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "RD") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaOptions)
      .typeError("Campo es requerido"),
  }),
  sector: Yup.object().nullable()
  .when("country", {
    is: (country: any) => {
      if (country?.id === "RD") {
        return true;
      }
    },
    then: Yup.object()
      .shape(formSchemaCountry)
      .typeError("Campo es requerido"),
  }),
  address: Yup.string()
  .required("Campo es requerido"),
  building_name: Yup.string(),
  floor_number: Yup.string(),
  postal_code: Yup.number().required("Campo es requerido").typeError("Campo es númerico"),
  residential_phone: Yup.string().matches(phoneRegExp, {message: "Teléfono no es valido",excludeEmptyString:true}),
  office_phone: Yup.string()
  .required("Campo es requerido").matches(phoneRegExp, "Teléfono no es valido")
  .min(8, "Teléfono no es valido")
  .max(12, "Teléfono no es valido"),
  cellphone: Yup.string()
  .required("Campo es requerido").matches(phoneRegExp, "Teléfono no es valido")
  .min(8, "Teléfono no es valido")
  .max(12, "Teléfono no es valido"),
  email: Yup.string()
  .required("Campo es requerido")
  .email("Email es invalido"),
};

export const validationAdditionalLegalInformation = Yup.object().shape({
  main_suppliers_entity: Yup.array().of(Yup.object().shape(formSchemaMain_clients_entity)),
  information_shareholders: Yup.array().of(Yup.object().shape(formSchemaInformation_shareholders)),
  members_management_body: Yup.array().of(Yup.object().shape(formSchemaMembers_management_body)),
  manages_public_resources: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  includes_shareholders_or_partners: Yup.object()
  .shape(formSchemaOptions)
  .typeError("Campo es requerido"),
  politically_exposed_person_members: Yup.array().when(
    "includes_shareholders_or_partners", {
      is: (includes_shareholders_or_partners:any) => {
        if(includes_shareholders_or_partners?.id === "1") {
          return true;
        }
      },
      then: Yup.array().of(Yup.object().shape(formSchemapolitically_exposed_person_members))
    }
  ),
  email1_correspondence: Yup.string()
  .required("Campo es requerido")
  .email("Email es invalido"),
  email2_correspondence: Yup.string().email("Email es invalido"),
  bank_accounts_register: Yup.array().of(Yup.object().shape(formSchemaBank_accounts_register)),
  legal_representative:Yup.array().of(Yup.object().shape(formSchemaLegal_representative)),
})

const formSchemaFinanceInstruments = {
  financialInstruments: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  experienceTime: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  numTransactionsYear: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  levelknowInstRisk: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
};

const formSchemaAuthorized_signatures= {
  name: Yup.string()
  .required("Campo es requerido"),
  document_number: Yup.string()
  .required("Campo es requerido"),
  function: Yup.string()
  .required("Campo es requerido"),
};

const formSchemaLiquidAssetsDistribution = {
  liquid_assets_distribution_id: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  liquid_assets_distribution_description: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
};

export const validationLegalRepresentativeInformationType = Yup.object().shape({
  ability_absorb_losses: Yup.object()
    .shape({
      id: Yup.number().required("Required"),
      description: Yup.string().required("Required"),
    })
    .typeError("Campo es requerido"),
  risk_tolerance: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  desired_profitability: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  investment_time_horizon: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  channel_catchment_client: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  knowledge_market_values: Yup.object()
        .shape(formSchemaOptions)
        .typeError("Campo es requerido"),
  information_media: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  professionalInvestor: Yup.object()
    .shape(formSchemaOptions)
    .typeError("Campo es requerido"),
  expcStockMarketLeastThree: Yup.boolean(),
  equityCapacityLeastTwentyFive: Yup.boolean(),
  knowledgeVerified: Yup.boolean(),
  financialInstrumentsYear: Yup.array().of(
    Yup.object().shape(formSchemaFinanceInstruments)
  ),
  productsUsed: Yup.bool().when(
    [
      "investmentFunds",
      "fixedIncomeSecurities",
      "liquidityOperations",
      "variableIncomeSecurities",
      "otherServices",
      "notHaveExperience",
    ],
    {
      is: (
        fixedIncomeSecurities: any,
        investmentFunds: any,
        liquidityOperations: any,
        variableIncomeSecurities: any,
        otherServices: any,
        notHaveExperience: any
      ) =>
        fixedIncomeSecurities?.length === 0 &&
        investmentFunds?.length === 0 &&
        liquidityOperations?.length === 0 &&
        variableIncomeSecurities?.length === 0 &&
        otherServices?.length === 0 &&
        notHaveExperience === false,
      then: Yup.bool().required("Debe seleccionar al menos uno"),
      otherwise: Yup.bool(),
    }
  ),
  liquid_assets_distribution_form: Yup.array().of(
    Yup.object().shape(formSchemaLiquidAssetsDistribution)
  ),
  social_name_signature: Yup.string()
  .required("Campo es requerido"),
  instructions_signature: Yup.string()
  .required("Campo es requerido"),
  authorized_signatures: Yup.array().of(Yup.object().shape(formSchemaAuthorized_signatures)),
},
[
  ["fixedIncomeSecurities", "investmentFunds"],
  ["fixedIncomeSecurities", "liquidityOperations"],
  ["fixedIncomeSecurities", "variableIncomeSecurities"],
  ["fixedIncomeSecurities", "otherServices"],
  ["investmentFunds", "liquidityOperations"],
  ["investmentFunds", "variableIncomeSecurities"],
  ["investmentFunds", "otherServices"],
  ["liquidityOperations", "variableIncomeSecurities"],
  ["liquidityOperations", "otherServices"],
  ["variableIncomeSecurities", "otherServices"],
]
)


