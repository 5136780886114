import axios from "axios";
import { getItem } from "./LocalStorageManager";
import jwt_decode from "jwt-decode";
import { basesURL } from "./UrlConstant";

const publicFetch = axios.create({
  baseURL: basesURL.app_base_url + basesURL.api_context,
});

const privateFetch = axios.create({
  baseURL: basesURL.app_base_url + basesURL.api_context,
});

privateFetch.interceptors.request.use(
  async (config) => {
    //validate uri to send token
    //@ts-ignore
    const { origin } = new URL(config.baseURL);
    const allowedOrigins = [basesURL.app_base_url];
    const authInfo: any = await getItem("authAltioInfo");
    const decodeToken: any = jwt_decode(authInfo?.tokenInfo?.access_token);
    const expiresAt = decodeToken.exp;
    let valid = new Date().getTime() / 1000 < expiresAt;
    if (allowedOrigins.includes(origin) && valid) {
      //@ts-ignore
      config.headers.Authorization = `Bearer ${authInfo?.tokenInfo?.access_token}`;
    }
    return config;
  }
  // error=>{
  //   return Promise.reject(error);
  // }
);

export { publicFetch, privateFetch };
