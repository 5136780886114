import { Outlet } from "react-router-dom";
import { Stack, styled } from "@mui/material";
import PaperToForm from "../../components/PaperToForm";
import { Altio } from "../../components/iconsSvg/AltioSvgIcons";
import SocialNetworks from "../../components/footer/SocialNetworks";

const Content = styled(Stack)(({ theme }) => ({
  minHeight: "100vh",
  padding: theme.spacing(0, 2),
}));

const AuthLayout = () => {
  return (
    <Content
      justifyContent="center"
      alignItems="center"
      spacing={2}
      sx={{ mt: 0, py: 4 }}
    >
      <Altio />
      <PaperToForm sx={{ width: "450px" }}>
        <Outlet />
      </PaperToForm>
      <SocialNetworks />
    </Content>
  );
};

export default AuthLayout;
